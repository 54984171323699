import { NgModule } from '@angular/core';
import { GCCoreModule } from '@core/_core.module';
import { AudienceModule } from '@features/audience/audience.module';
import { ProgramFormsModule } from '@features/program-forms/program-forms.module';
import { QuickAddModule } from '@features/quick-add/quick-add.module';
import { AccordionsModule } from '@yourcause/common/accordion';
import { AdHocFormSelectorComponent } from './components/ad-hoc-form-selector/ad-hoc-form-selector.component';
import { AdHocReportBuilderComponent } from './components/ad-hoc-report-builder/ad-hoc-report-builder.component';
import { AdHocReportCellComponent } from './components/ad-hoc-report-cell/ad-hoc-report-cell.component';
import { AdHocReportSchedulesPageComponent } from './components/ad-hoc-report-schedules-page/ad-hoc-report-schedules-page.component';
import { ReportOwnershipTransferComponent } from './components/report-ownership-transfer/report-ownership-transfer.component';
import { AdHocReportListViewPageComponent } from './pages/ad-hoc-report-list-view-page/ad-hoc-report-list-view-page.component';
import { AdvancedFiltersModalComponent } from './pages/advanced-filters-modal/advanced-filters-modal.component';
import { DataFeedsListViewPageComponent } from './pages/data-feeds-list-view-page/data-feeds-list-view-page.component';
import { ManageAdHocReportModalComponent } from './pages/manage-ad-hoc-report-modal/manage-ad-hoc-report-modal.component';
import { ManageAdHocReportPageComponent } from './pages/manage-ad-hoc-report-page/manage-ad-hoc-report-page.component';
import { ReportingWrapperPageComponent } from './pages/reporting-wrapper-page/reporting-wrapper-page.component';
import { SendReportModalComponent } from './pages/send-report-modal/send-report-modal.component';
import { ShareReportModalComponent } from './pages/share-report-modal/share-report-modal.component';
import { UpdateHeaderColumnModalComponent } from './pages/update-header-column-modal/update-header-column-modal.component';
import { DoesReportSupportFormsPipe } from './pipes/does-report-support-forms.pipe';
import { ReportOwnershipTransferModalComponent } from './components/report-ownership-transfer-modal/report-ownership-transfer-modal.component';

@NgModule({
  imports: [
    GCCoreModule,
    ProgramFormsModule,
    AudienceModule,
    QuickAddModule,
    AccordionsModule
  ],
  declarations: [
    AdvancedFiltersModalComponent,
    UpdateHeaderColumnModalComponent,
    AdHocReportCellComponent,
    ManageAdHocReportModalComponent,
    AdHocReportBuilderComponent,
    ManageAdHocReportPageComponent,
    AdHocReportListViewPageComponent,
    ReportingWrapperPageComponent,
    ShareReportModalComponent,
    SendReportModalComponent,
    AdHocFormSelectorComponent,
    DoesReportSupportFormsPipe,
    DataFeedsListViewPageComponent,
    AdHocReportSchedulesPageComponent,
    ReportOwnershipTransferComponent,
    ReportOwnershipTransferModalComponent
  ],
  exports: [
    AdHocReportBuilderComponent,
    ShareReportModalComponent,
    SendReportModalComponent,
    AdHocFormSelectorComponent,
    DoesReportSupportFormsPipe,
    ReportOwnershipTransferComponent,
    ReportOwnershipTransferModalComponent
  ]
})
export class AdHocReportingModule { }
