import { AudienceMember } from '@features/audience/audience.typing';
import { FormAudience, FormDefinitionForUi } from '@features/configure-forms/form.typing';
import { Filter, FilterValue, PaginationOptions } from '@yourcause/common';
import { UserInfo } from '../client-user.typing';
import { WorkflowLevelAutomationAPI } from './workflow-level-automation.typing';
import { TableDataDownloadFormat } from '@yourcause/common/files';
import { Frequency } from '@yourcause/common/date';


export namespace AdHocReportingAPI {
  export interface FormComponentSummary {
    key: string;
    type: string;
    label: string;
  }

  export interface PaginationAdvancedFilterColumnValueModel extends Filter {
    useLogicalOperatorAnd: boolean;
    filterValues: string[];
  }

  export interface PaginationAdvancedFilterColumnModel {
    columnName: string;
    filter: PaginationAdvancedFilterColumnValueModel;
  }

  export interface AdvancedPaginationOptionsModel<T = any> extends PaginationOptions<T> {
    useLogicalOperatorAnd: boolean;
    advancedFilterColumns: PaginationAdvancedFilterColumnModel[][];
  }

  export interface BaseUserSavedReport {
    id: number;
    name: string;
    description: string;
    isUserOwnedReport: boolean;
    othersMayView?: boolean;
    othersMayModify?: boolean;
    reportModelType: AdHocReportModelType;
    userSavedReportColumns: UserSavedReportColumn[];
    createdBy?: UserInfo;
    updatedBy?: UserInfo;
    userId?: number;
    createdDate?: string;
    updatedDate?: string;
    clientId: number;
    forms: (WorkflowLevelAutomationAPI.WorkflowFormDisplay&{
      formDefinition: FormDefinitionForUi|string;
    })[];
    numberOfSchedules?: number;
    isShared?: boolean;
    reportOwnerClientUserId: number;
    accessType: AdHocReportAccessType;
    primaryFormId: number;
    advancedFilterColumns: AdvancedUserSavedFilterColumn[][];
    useLogicalOperatorAnd: boolean;
    referenceFieldTableId: number;
    referenceFieldTableKey: string;
    standardComponentIsPublished: boolean;
  }

  export interface UserSavedAdHocReport extends BaseUserSavedReport {
    reportType: AdHocReportType.AdHoc;
  }

  export interface UserSavedChart extends BaseUserSavedReport {
    reportType: AdHocReportType.Chart;
    chartType: ChartType;
    chartConfig: string;
  }

  export interface UserSavedAdHocReportForUi extends UserSavedAdHocReport {
    isStandardReportTemplate: boolean;
    isMyReport: boolean;
  }

  export interface UserSavedChartForUi extends UserSavedChart {
    isStandardReportTemplate: boolean;
  }

  export type UserSavedReport = UserSavedAdHocReport|UserSavedChart;

  export type UserSavedReportForUi = UserSavedAdHocReportForUi|UserSavedChartForUi;

  export interface SimpleReportOwnedByUser {
    id: number;
    name: string;
    description: string;
    reportType: AdHocReportType;
    reportModelType: AdHocReportModelType;
  }

  export interface ReportToReassign {
    userSavedReportId: number;
    userId: number
  }

  export interface FormDisplayModel extends WorkflowLevelAutomationAPI.WorkflowFormDisplay {
    formAudience: FormAudience;
  }

  export interface BaseCreateReportPayload {
    userSavedReportColumns: UserSavedReportColumn[];
    formIds: number[];
    reportUsers: AudienceMember[];
    name: string;
    description: string;
    othersMayModify?: boolean;
    othersMayView?: boolean;
    reportModelType: AdHocReportModelType;
    advancedFilterColumns: AdvancedUserSavedFilterColumn[][];
    useLogicalOperatorAnd: boolean;
    primaryFormId?: number;
    referenceFieldTableId: number;
  }

  export interface CreateAdHocPayload extends BaseCreateReportPayload {
    reportType: AdHocReportType.AdHoc;
  }

  export interface CreateChartPayload extends BaseCreateReportPayload {
    reportType: AdHocReportType.Chart;
    chartType: ChartType;
    chartConfig: string;
    chartMaxRows: number;
    chartIncludeOtherAggregate: boolean;
  }

  export type CreateReportPayload = CreateChartPayload|CreateAdHocPayload;

  export type UpdateReportPayload = CreateReportPayload & {
    id: number;
  };

  export interface ReportScheduleFromApi {
    scheduleId: number;
    audienceUsers: AudienceMember[];
    reportId: number;
    sftpId: number;
    audienceId: number;
    oneOffUsers: AudienceMember[];
    frequencyType: Frequency;
    hour: number;
    day: number;
    frequencyValue: number;
    expirationHours: number;
    showMaskedData: boolean;
    failureDeliveryUsers: AudienceMember[];
    exportFileTypeId: TableDataDownloadFormat;
    includeBom: boolean;
    delimiter: string;
    createdBy: UserInfo;
    updatedBy: UserInfo;
    createdDate: string;
    updatedDate: string;
  }

  export interface UserSavedReportColumn {
    id?: number;
    columnName: string;
    sortType: SortTypes;
    sortPriority: number;
    userSavedFilterColumns: UserSavedFilterColumn[];
    isVisible?: boolean;
    displayName: string;
    isChartGroupingColumn: boolean;
    isChartSubGroupingColumn: boolean;
    isChartAggregate: boolean;
    chartAggregateType: ChartAggregateType;
    referenceFieldId: number;
    referenceFieldTableId?: number;
  }

  export interface UserSavedFilterColumn {
    id?: number;
    filterType: string;
    filterValue: FilterValue;
  }

  export interface AdvancedUserSavedFilterColumn {
    columnName: string;
    filterType: string;
    filterValue: FilterValue;
    filterValues: string[];
    useLogicalOperatorAnd: boolean;
  }

  export enum ChartType {
    Bar	= 1,
    Bubble = 2,
    Doughnut = 3,
    HorizontalBar = 4,
    Line = 5,
    Pie = 6,
    PolarArea = 7,
    Radar = 8,
    Scatter = 9,
    Stat = 10,
    Table = 11
  }

  export enum AdHocReportType {
    AdHoc = 1,
    Chart = 2,
    ChartTemplate = 3
  }

  export enum AdHocReportModelType {
    Application = 1,
    ApplicationForm = 2,
    Award = 3,
    Payment = 4,
    ApplicationInKindAmountRequested = 5,
    InKindAwardItems = 6,
    InKindPaymentItems = 7,
    Budgets = 8,
    FundingSources = 9,
    Table = 10,
    FieldGroup = 11,
    TodaysDate = 101
  }

  export enum SortTypes {
    NoSort = 1,
    Ascending = 2,
    Descending = 3
  }

  export interface SaveSharedReportUsersPayload {
    id: number;
    users: AudienceMember[];
  }

  export enum AdHocReportAccessType {
    VIEW = 1,
    MANAGE = 2
  }

  export enum ChartAggregateType {
    Sum = 1,
    Max = 2,
    Min = 3,
    Count = 4
  }

  export interface SendReportPayload {
    reportId: number;
    showMaskedData: boolean;
    sftpId: number;
    clientEmailTemplateId: number;
    audienceId: number;
    oneOffUsers: AudienceMember[];
    expirationHours: number;
    exportFileTypeId: TableDataDownloadFormat;
    includeBom: boolean;
    delimiter: string;
  }

  export interface ScheduleReportPayload {
    scheduleId?: number;
    reportId: number;
    sftpId: number;
    audienceId: number;
    oneOffUsers: AudienceMember[];
    failureDeliveryUsers: AudienceMember[];
    frequencyType: Frequency;
    hour?: number;
    day: number;
    frequencyValue: number;
    showMaskedData: boolean;
    expirationHours: number;
    exportFileTypeId: TableDataDownloadFormat;
    includeBom: boolean;
    delimiter: string;
  }

  export interface DataFeedConnection {
    id?: number;
    connectionName: string;
    folderPath: string;
    createdDate: string;
    activeDataFiles: ActiveDataFile[];
  }

  export interface ActiveDataFile {
    fileName: string;
    url: string;
  }
}

