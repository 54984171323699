import { Injectable } from '@angular/core';
import { FormMaskingService } from '@core/services/form-masking.service';
import { APIAdminClient } from '@core/typings/api/admin-client.typing';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { BaseApplicationForLogic, FormAnswerValues, FormDefinitionComponent, FormDefinitionForUi, ReportFieldDataOptions } from '@features/configure-forms/form.typing';
import { FormsService } from '@features/configure-forms/services/forms/forms.service';
import { FormFieldAdHocService } from '@features/form-fields/services/form-field-ad-hoc.service';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { ReportFieldResources } from '@features/forms/report-field.resources';
import { LogicColumn, LogicColumnDisplay, LogicFilterTypes } from '@features/logic-builder/logic-builder.typing';
import { AdHocReportingMappingService } from '@features/reporting/services/ad-hoc-reporting-mapping.service';
import { AdHocReportingService } from '@features/reporting/services/ad-hoc-reporting.service';
import { I18nService } from '@yourcause/common/i18n';
import { ConfirmAndTakeActionService } from '@yourcause/common/modals';
import { isNull, isUndefined } from 'lodash';
import { ComponentHelperService, NominationFormObject } from '../component-helper/component-helper.service';
import { ArrayHelpersService } from '@yourcause/common/utils';

@Injectable({ providedIn: 'root' })
export class ReportFieldService {

  constructor (
    private adHocReportingService: AdHocReportingService,
    private adHocReportingMapper: AdHocReportingMappingService,
    private reportFieldResources: ReportFieldResources,
    private i18n: I18nService,
    private formFieldHelperService: FormFieldHelperService,
    private componentHelper: ComponentHelperService,
    private formMaskingService: FormMaskingService,
    private arrayHelper: ArrayHelpersService,
    private confirmAndTakeAction: ConfirmAndTakeActionService,
    private clientSettingService: ClientSettingsService,
    private formService: FormsService,
    private formFieldAdHocService: FormFieldAdHocService
  ) { }

  /**
   * Get the value for the report field
   *
   * @param reportFieldDataOptions: Report Field Config
   * @param row: Row to get value from
   * @param component: form component
   * @returns the report field value
   */
  getReportFieldValue (
    reportFieldDataOptions: ReportFieldDataOptions,
    row: AdHocReportingUI.ReportFieldResponseRow,
    component: FormDefinitionComponent,
    returnFlatAnswer = false
  ) {
    let value: FormAnswerValues = '';
    const isNominationField = this.componentHelper.isReportNominationField(reportFieldDataOptions.reportFieldObject);
    if (isNominationField) {
      const key = reportFieldDataOptions.reportFieldDisplay;
      if (returnFlatAnswer) {
        const field = this.formFieldHelperService.referenceFieldMap[key];
        const [columnDef] = this.formFieldAdHocService.getReferenceFieldColumnDef(
          {
            ...field,
            formIds: []
          },
          false,
          true
        );
        value = this.adHocReportingMapper.getValueForColumnFromRow(
          columnDef,
          row,
          this.formMaskingService.getDefaultMaskSetting(),
          true
        );
      } else {
        const nomField = this.formFieldHelperService.referenceFieldMap[key];
        if (!!row) {
          const object = row[NominationFormObject];
          value = object ? object[this.componentHelper.getNominatorReportKey(key)] : null;
          if (isNull(value) || isUndefined(value)) {
            value = this.formFieldHelperService.getBlankValueForFormField(nomField, component, false);
          }
        } else {
          value = this.formFieldHelperService.getBlankValueForFormField(nomField, component, false);
        }
      }
    } else {
      if (!!row) {
        const buckets = this.getReportFieldBuckets(false);
        // find bucket whose property matches the report field object, get columns from that bucket
        const cols: AdHocReportingUI.ColumnImplementation[] = buckets.find((bucket) => {
          return bucket.property === reportFieldDataOptions.reportFieldObject;
        }).columns;
        // find the column that matches our report field display
        const column = cols.find((a) => {
          return a.definition.column === reportFieldDataOptions.reportFieldDisplay;
        });
        // use column def and endpoint response to return value
        value = this.adHocReportingMapper.getValueForColumnFromRow(
          column.definition,
          row,
          this.formMaskingService.getDefaultMaskSetting()
        );
      }
    }


    return value;
  }


  /**
   * Get Report Field Buckets
   *
   * @param isNominationForm: Is this on a nomination form?
   * @param includeNominationFormObject: Should we include the Nomination form object?
   * @returns report field buckets
   */
  getReportFieldBuckets (
    isNominationForm: boolean,
    includeNominationFormObject = false
  ): AdHocReportingUI.ColumnBucket[] {
    const bucketsFromService = this.adHocReportingService.getBuckets('application', [], []);
    let buckets = bucketsFromService.filter((bucket) => {
      const passesNomination = isNominationForm ? bucket.property !== 'relatedNominator' : true;
      const hasCols = bucket.columns.some((column) => {
        return column.definition.canBeUsedAsReportField;
      });

      return hasCols && passesNomination;
    }).map((bucket) => {
      if (bucket.property === 'application') {
        bucket.display = this.i18n.translate(
          isNominationForm ? 'FORMS:textNomination' : 'common:lblApplication',
          {},
          isNominationForm ? 'Nomination' : 'Application'
        );
      }

      return {
        ...bucket,
        columns: bucket.columns.map((col) => {
          if (bucket.property === 'relatedNominator' && col.definition.column === 'name') {
            return {
              ...col,
              definition: {
                ...col.definition,
                // Needed to match API model
                column: 'fullName'
              }
            };
          }

          return col;
        })
      };
    });
    if (includeNominationFormObject) {
      const nominatorBucket: AdHocReportingUI.ColumnBucket = {
        i18nKey: '',
        display: this.i18n.translate('common:textRelatedNominationForm', {}, 'Related nomination form'),
        property: NominationFormObject,
        columns: [],
        allColumns: []
      };
      buckets = this.arrayHelper.sort(buckets.concat([nominatorBucket]), 'display');
    }
    
    return buckets;
  }

  /**
   * Get Report Field Columns for Logic Builder
   *
   * @param currentFormDefinition: Form Definition to get Columns from
   * @param isNominationForm: Is this on a nomination form?
   * @returns Report field columns for logic builder
   */
  getReportFieldColumns (
    currentFormDefinition: FormDefinitionForUi[],
    isNominationForm: boolean
  ): LogicColumnDisplay<BaseApplicationForLogic>[] {
    const {
      columnNameMap,
      additionalNomReportComps
    } = this.componentHelper.getColumnNameMapAndNomReportFields(currentFormDefinition);
    const baseReportFields = this.getBaseReportFieldsForLogic(isNominationForm, columnNameMap);
    const additionalFields = additionalNomReportComps.filter((comp) => {
      const field = this.formFieldHelperService.referenceFieldMap[comp.reportFieldDataOptions.reportFieldDisplay];

      return !!field;
    }).map<LogicColumnDisplay<BaseApplicationForLogic>>((comp) => {
      const fieldKey = comp.reportFieldDataOptions.reportFieldDisplay;
      const field = this.formFieldHelperService.referenceFieldMap[fieldKey];
      const [columnDef] = this.formFieldAdHocService.getReferenceFieldColumnDef({
        ...field,
        formIds: []
      });
      const label = columnNameMap[fieldKey];
      const nomKey = this.componentHelper.getNominatorReportKey(fieldKey);

      return {
        column: [
          'reportFieldResponse',
          NominationFormObject,
          nomKey
        ] as LogicColumn<BaseApplicationForLogic>,
        label: label || field.name,
        otherColumnOptions: [],
        type: columnDef.type as LogicFilterTypes,
        filterOptions: []
      };
    });

    return this.arrayHelper.sort(baseReportFields.concat(additionalFields), 'label');
  }

  getBaseReportFieldsForLogic (
    isNominationForm: boolean,
    columnNameMap: Record<string, string>
  ) {
    const hasNominations = this.clientSettingService.doesClientHaveClientFeature(APIAdminClient.ClientFeatureTypes.HasNominations);
    const includeNominationFormObject = !isNominationForm &&
      hasNominations &&
      this.formService.nominationFormOptions.length > 0;
    const reportFieldBuckets = this.getReportFieldBuckets(
      isNominationForm,
      includeNominationFormObject
    );

    return reportFieldBuckets.reduce((acc, curr) => {
      const bucket = reportFieldBuckets.find((_bucket) => _bucket.property === curr.property);
      const options = bucket.columns.map((column) => {
        const label = columnNameMap[column.definition.column];
        const defLabel = this.i18n.translate(
          column.definition.i18nKey,
          {},
          column.definition.defaultDisplay
        );
        const field: LogicColumnDisplay<BaseApplicationForLogic> = {
          column: [
            'reportFieldResponse',
            column.definition.parentBucket,
            column.definition.column
          ] as LogicColumn<BaseApplicationForLogic>,
          label: label || defLabel,
          otherColumnOptions: [],
          type: column.definition.type as LogicFilterTypes,
          filterOptions: []
        };

        return field;
      });

      return [
        ...acc,
        ...options
      ];
    }, [] as LogicColumnDisplay<BaseApplicationForLogic>[]);
  }

  async getResponseFromApi (
    applicationId: number,
    revisionId: number,
    hasNominationReportField: boolean
  ) {
    const [
      reportFieldResponse,
      nominationResponse
    ] = await Promise.all([
      this.reportFieldResources.getReportFieldResponse(applicationId),
      !!revisionId && hasNominationReportField ?
        this.reportFieldResources.getReportFieldNominationResponse(applicationId, revisionId) :
         {
          nominationReferenceFields: [],
          employeeApplicantInfo: {},
          nominatorInfo: {}
        }

    ]);

    this.adaptNominationResponse(reportFieldResponse, nominationResponse);


    return reportFieldResponse;
  }

  /**
   * Adapt the report data returned from the API
   *
   * @param reportFieldResponse: Report Field Response from API
   * @param nominationResponse: Nomination Report Response from API
   */
  adaptNominationResponse (
    reportFieldResponse: AdHocReportingUI.ReportFieldResponseRow,
    nominationResponse: ReferenceFieldAPI.NominationReportFieldResponse
  ) {
    if (!!nominationResponse) {
      (reportFieldResponse as any).relatedNominator = {};
      Object.keys(nominationResponse.nominatorInfo).forEach((key) => {
        const value = nominationResponse.nominatorInfo[key];
        reportFieldResponse['relatedNominator'][key] = value;
      });
      Object.keys(nominationResponse.employeeApplicantInfo).forEach((key) => {
        const value = nominationResponse.employeeApplicantInfo[key];
        reportFieldResponse['relatedNominator'][key] = value;
      });
      reportFieldResponse.originalNominationResponse = nominationResponse.nominationReferenceFields;
      nominationResponse.nominationReferenceFields.forEach((response) => {
        const field = this.formFieldHelperService.referenceFieldMap[response.referenceFieldKey];
        let value = this.formFieldHelperService.prepareValueForMapping(response, field);
        value = this.formFieldHelperService.formatFieldForUi(field, response, null, null);
        reportFieldResponse[NominationFormObject] = {
          ...((reportFieldResponse as any)[NominationFormObject] || {}),
          [this.componentHelper.getNominatorReportKey(response.referenceFieldKey)]: value
        };
      });
    }
  }

  /**
   * Get Report Field Responses
   *
   * @param applicationId: Application ID
   * @param revisionId: Revision ID
   * @param hasNominationReportField: Does this form have a nomination report field on it?
   * @returns the Report Field Responses for the given application form
   */
  async getReportFieldResponseFromAPI (
    applicationId: number,
    revisionId: number,
    hasNominationReportField: boolean
  ) {
    const {
      passed,
      endpointResponse
    } = await this.confirmAndTakeAction.genericTakeAction(
      () => this.getResponseFromApi(applicationId, revisionId, hasNominationReportField),
      '',
      this.i18n.translate(
        'FORMS:textErrorFetchingReportFieldData',
        {},
        'There was an error fetching report field data'
      ),
      true
    );

    return passed ? endpointResponse : null;
  }

  /**
   * Check if the form contains any report fields
   *
   * @param formDefinition: Form Definition
   * @returns if the form contains any report fields
   */
  formContainsReportField (formDefinition: FormDefinitionForUi[]): {
    formContainsReportField: boolean;
    formHasNominationReportField: boolean;
  } {
    let formContainsReportField = false;
    let formHasNominationReportField = false;
    formDefinition.forEach((tab) => {
      this.componentHelper.eachComponent(tab.components, (comp) => {
        if (this.componentHelper.isReportFieldComp(comp.type)) {
          formContainsReportField = true;
          const object = comp.reportFieldDataOptions?.reportFieldObject;
          if (
            this.componentHelper.isReportNominationField(object) ||
            this.componentHelper.isReportRelatedNominatorField(object)
          ) {
            formHasNominationReportField = true;
          }
        }
      });
    });

    return {
      formContainsReportField,
      formHasNominationReportField
    };
  }

  getReportFieldResponseBulk (applicationIds: number[]) {
    return this.reportFieldResources.getReportFieldResponsesBulk(applicationIds);
  }

  handleReportFieldData (
    formDefinition: FormDefinitionForUi[],
    applicationId: number,
    revisionId: number
  ) {
    const {
      formContainsReportField,
      formHasNominationReportField
    } = this.formContainsReportField(formDefinition);
    if (formContainsReportField) {
      return this.getReportFieldResponseFromAPI(applicationId, revisionId, formHasNominationReportField);
    }

    return null;
  }
}
