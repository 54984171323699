import { Injectable } from '@angular/core';
import { BudgetService } from '@features/budgets/budget.service';
import { FormsService } from '@features/configure-forms/services/forms/forms.service';
import { EmployeeSSOFieldsService } from '@features/employee-sso-fields/employee-sso-fields.service';
import { FormFieldService } from '@features/form-fields/services/form-field.service';
import { StandardProductConfigurationService } from '@features/platform-admin/standard-product-configuration/standard-product-configuration.service';
import { ProgramService } from '@features/programs/services/program.service';
import { SystemTagsService } from '@features/system-tags/system-tags.service';
import { WorkflowService } from '@features/workflow/workflow.service';

@Injectable({ providedIn: 'root' })
export class PrepareAdHocReportingService {

  constructor (
    private employeeSSOFieldsService: EmployeeSSOFieldsService,
    private programService: ProgramService,
    private workflowService: WorkflowService,
    private formFieldService: FormFieldService,
    private budgetService: BudgetService,
    private formService: FormsService,
    private standardProductService: StandardProductConfigurationService,
    private systemTagsService: SystemTagsService
  ) { }

  async resolveAdHocDefinitions () {
    await Promise.all([
      this.employeeSSOFieldsService.setEmployeeSSOFields(),
      this.systemTagsService.resolveSystemTags(),
      this.workflowService.getWorkflows(),
      this.budgetService.setAllOptions(),
      this.programService.getAllActiveManagerPrograms()
    ]);
  }

  async resolve () {
    await Promise.all([
      this.resolveAdHocDefinitions(),
      this.formFieldService.resolve(),
      this.standardProductService.setStandardReportTemplates(),
      this.formService.prepareForms(),
    ]);
  }
}
