import { Injectable } from '@angular/core';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { ApplicationFileService } from '@features/application-file/services/application-file.service';
import { YcFile } from '@yourcause/common/files';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { NotifierService } from '@yourcause/common/notifier';

@Injectable({ providedIn: 'root' })
export class FormFieldFileUploadService {

  constructor (
    private logger: LogService,
    private i18n: I18nService,
    private notifier: NotifierService,
    private applicationFileService: ApplicationFileService
  ) { }

  async handleTableFileUploads (
    tableColumns: ReferenceFieldsUI.TableFieldForCrudUi[],
    responseMap: ReferenceFieldsUI.RefResponseMap,
    applicationId: number,
    applicationFormId: number
  ): Promise<ReferenceFieldsUI.RefResponseMap> {
    try {
      const filesNeedingUploaded = this.getTableFilesNeedingUploaded(
        tableColumns,
        responseMap
      );

      const response = await this.doUploadOfReferenceFieldFiles(
        filesNeedingUploaded,
        applicationId,
        applicationFormId,
        responseMap,
        {}
      );

      return response.refChangeTracker;
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'common:textThereWasAnErrorSaving',
        {},
        'There was an error saving'
      ));

      return null;
    }
  }

  getTableFilesNeedingUploaded (
    tableColumns: ReferenceFieldsUI.TableFieldForCrudUi[],
    responseMap: ReferenceFieldsUI.RefResponseMap
  ): ReferenceFieldsUI.FileNeedingUploaded[] {
    const filesNeedingUploaded: ReferenceFieldsUI.FileNeedingUploaded[] = [];
    tableColumns.forEach((column) => {
      const answer = responseMap[column.referenceField.key];
      if (
        answer instanceof Array &&
        column.referenceField.type === ReferenceFieldsUI.ReferenceFieldTypes.FileUpload
      ) {
        answer.forEach((file: any) => {
          if (
            file instanceof YcFile &&
            !file.fileUploadId
          ) {
            filesNeedingUploaded.push({
              key: column.referenceField.key,
              referenceFieldId: column.referenceFieldId,
              file
            });
          }
        });
      }
    });

    return filesNeedingUploaded;
  }

  /**
   * Takes an array of files, uploads them, and returns the updated files
   *
   * @param filesNeedingUploaded array of table files that need to be uploaded
   * @param applicationId application id
   * @param applicationFormId application form id
   * @param column table column row response
   * @returns updated array of files
   */
  async doUploadOfReferenceFieldTableFiles (
    filesNeedingUploaded: ReferenceFieldsUI.FileNeedingUploaded[],
    applicationId: number,
    applicationFormId: number,
    column: ReferenceFieldAPI.ApplicationRefFieldResponse
  ): Promise<YcFile<File>[]> {
    for (const item of filesNeedingUploaded) {
      const newFile = await this.uploadReferenceFieldFile(
        item,
        applicationId,
        applicationFormId
      );
      const columnFiles = column.value as YcFile<File>[];
      const columnValueIndex = columnFiles.findIndex((file) => {
        return file === item.file;
      });

      column.value = [
        ...columnFiles.slice(0, columnValueIndex),
        newFile,
        ...columnFiles.slice(columnValueIndex + 1)
      ];
    }

    return column.value as YcFile<File>[];
  }

  /**
   * Takes an array of files, uploads them, and updates the response map
   *
   * @param filesNeedingUploaded array of files that need to be uploaded
   * @param applicationId application id
   * @param applicationFormId application form id
   * @param refChangeTracker response map that stores the file answer changes
   * @param referenceFieldsMap map that stores all the reference fields answers
   * @returns returns the updated maps
   */
  async doUploadOfReferenceFieldFiles (
    filesNeedingUploaded: ReferenceFieldsUI.FileNeedingUploaded[],
    applicationId: number,
    applicationFormId: number,
    refChangeTracker: ReferenceFieldsUI.RefResponseMap,
    referenceFieldsMap: ReferenceFieldsUI.RefResponseMap
  ): Promise<{
    refChangeTracker: ReferenceFieldsUI.RefResponseMap;
    referenceFieldsMap: ReferenceFieldsUI.RefResponseMap;
  }> {
    for (const item of filesNeedingUploaded) {
      const file = await this.uploadReferenceFieldFile(
        item,
        applicationId,
        applicationFormId
      );
      refChangeTracker = this.mapNewFileInfoBackToResponseMap(
        item,
        file,
        refChangeTracker
      );
      referenceFieldsMap = this.mapNewFileInfoBackToResponseMap(
        item,
        file,
        referenceFieldsMap
      );
    }

    return {
      refChangeTracker,
      referenceFieldsMap
    };
  }

  /**
   * Actually upload the reference field file
   *
   * @param item details of file to upload
   * @param applicationId application id
   * @param applicationFormId application form id
   * @returns the updated YcFile after upload
   */
  async uploadReferenceFieldFile (
    item: ReferenceFieldsUI.FileNeedingUploaded,
    applicationId: number,
    applicationFormId: number
  ): Promise<YcFile<File>> {
    const file = item.file;
    const fileName = file.fileName;
    const fileUrl = await this.applicationFileService.uploadFile(
      applicationId,
      applicationFormId,
      file.file,
      fileName,
      item.referenceFieldId
    );
    if (!!fileUrl) {
      file.fileUrl = fileUrl;
      const details = this.applicationFileService.breakDownloadUrlDownToObject(
        file.fileUrl
      );
      if (details.fileId) {
        file.fileUploadId = +details.fileId;
      }

      return file;
    }

    return null;
  }

  /**
   *
   * @param fileDetails file details for the file that was uploaded
   * @param file new file we uploaded
   * @param referenceFieldsMap response map that needs updated to link to new file
   * @returns the updated reference fields map
   */
  mapNewFileInfoBackToResponseMap (
    fileDetails: ReferenceFieldsUI.FileNeedingUploaded,
    file: YcFile<File>,
    referenceFieldsMap: ReferenceFieldsUI.RefResponseMap
  ) {
    const allFilesForField = referenceFieldsMap[fileDetails.key] as YcFile<File>[] || [];
    const thisFileIndex = allFilesForField.findIndex((_file) => {
      return _file === file;
    });
    const updatedFiles = [
      ...allFilesForField.slice(0, thisFileIndex),
      file,
      ...allFilesForField.slice(thisFileIndex + 1)
    ];
    if (thisFileIndex > -1) {
      referenceFieldsMap = {
        ...referenceFieldsMap,
        [fileDetails.key]: updatedFiles
      };
    }

    return referenceFieldsMap;
  }
}
