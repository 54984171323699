import { Injectable } from '@angular/core';
export const acceptedFileTypesLink = 'https://webfiles-sc1.blackbaud.com/files/support/helpfiles/grantsconnect/content/gc-guide-file-requirements.html';
export const privacyPolicyLink = 'https://www.yourcause.com/security';
export const termsOfServiceLink = 'https://www.yourcause.com/security';

@Injectable({ providedIn: 'root' })
export class LinkService {

  getAcceptedFileTypesLink () {
    return acceptedFileTypesLink;
  }

  getPrivacyPolicyLink () {
    return privacyPolicyLink;
  }

  getTermsOfServiceLink () {
    return termsOfServiceLink;
  }
}
