import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { ApplicationFileUploadResponse } from '@core/typings/file.typing';

@Injectable({ providedIn: 'root' })
export class ApplicationFileResources {
  constructor (
    private http: HttpRestService,
    private portal: PortalDeterminationService
  ) { }

  uploadFile (
    applicationId: number,
    applicationFormId: number,
    refFieldId: number,
    file: File,
    fileName: string
  ): Promise<number> {
    const forApplicant = this.portal.isApply;
      let endpoint = forApplicant ?
        `/api/portal/applications/${applicationId}/ApplicationForms/${applicationFormId}/Files` :
        `/api/manager/applications/${applicationId}/ApplicationForms/${applicationFormId}/Files`;
      if (!!refFieldId) {
        endpoint = `${endpoint}?referenceFieldId=${refFieldId}`;
      }

    return this.http.postFile(endpoint, file, undefined, fileName);
  }

  private getFileApplicant (
    applicationId: number,
    applicationFormId: number,
    applicationFormFileUploadId: number
  ) {
    return `/api/portal/applications/${applicationId}/ApplicationForms/${applicationFormId}/Files/${applicationFormFileUploadId}`;
  }

  private getFileManager (
    applicationId: number,
    applicationFormId: number,
    applicationFormFileUploadId: number
  ) {
    return `/api/manager/applications/${applicationId}/ApplicationForms/${applicationFormId}/Files/${applicationFormFileUploadId}`;
  }

  async getFile (
    applicationId: number,
    applicationFormId: number,
    applicationFormFileUploadId: number
  ) {
    return this.http.get<ApplicationFileUploadResponse>(
      this.getFileUrl(applicationId, applicationFormId, applicationFormFileUploadId)
    );
  }

  private getFileUrl (
    applicationId: number,
    applicationFormId: number,
    applicationFormFileUploadId: number
  ): string {
    const func = this.portal.isApply ?
      'getFileApplicant' :
      'getFileManager';

    return this[func](
      applicationId,
      applicationFormId,
      applicationFormFileUploadId
    );
  }
}

