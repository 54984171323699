import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { environment } from '@environment';
import { AddressRequestsResources } from '@features/platform-admin/address-requests/address-requests.resources';
import { FileService, FileTypeService } from '@yourcause/common/files';
import { ConfirmAndTakeActionService } from '@yourcause/common/modals';
import { ApplicationFileResources } from '../resources/application-file.resources';

export interface ApplicationFileUrlParams {
  applicationId: number;
  applicationFormId: number;
  fileId: number;
  fileName: string;
}

@Injectable({ providedIn: 'root' })
export class ApplicationFileService {

   constructor (
    private portal: PortalDeterminationService,
    private fileService: FileService,
    private addressRequestResources: AddressRequestsResources,
    private fileTypeService: FileTypeService,
    private confirmAndTakeAction: ConfirmAndTakeActionService,
    private applicationFileResources: ApplicationFileResources
  ) { }

  convertParamsToApplicationFileUrl (
    applicationId: number,
    applicationFormId: number,
    fileId: number,
    fileName: string
  ) {
    const forApplicant = this.portal.isApply;
    const locationBase = environment.isLocalhost ?
      'yourcausegrantsqa.com' :
      location.hostname;

    const queryParams = {
      applicationId,
      applicationFormId,
      fileId,
      fileName
    };
    const query = Object.keys(queryParams)
      .map(key => key as keyof typeof queryParams)
      .map((key: keyof typeof queryParams) => `${key}=${encodeURIComponent(queryParams[key])}`)
      .join('&');

    return `https://${locationBase}/${forApplicant ? 'apply' : 'management'}/download-file?${query}`;
  }

  async doUploadFile (
    applicationId: number,
    applicationFormId: number,
    file: File,
    fileName: string,
    refFieldId: number
  ) {
    const fileId = await this.applicationFileResources.uploadFile(
      applicationId,
      applicationFormId,
      refFieldId,
      file,
      fileName
    );

    return this.convertParamsToApplicationFileUrl(
      applicationId,
      applicationFormId,
      fileId,
      fileName
    );
  }

  async uploadFile (
    applicationId: number,
    applicationFormId: number,
    file: File,
    fileName: string,
    refFieldId: number
  ) {
    const {
      passed,
      endpointResponse,
      error
    } = await this.confirmAndTakeAction.genericTakeAction(
      () => this.doUploadFile(
        applicationId,
        applicationFormId,
        file,
        fileName,
        refFieldId
      ),
      '',
      ''
    );
    if (!passed) {
      const e = error as HttpErrorResponse;
      this.fileTypeService.displayInvalidFileUploadErrorMessage(e?.error?.message, e);

      return null;
    } else {
      return endpointResponse;
    }
  }

  breakDownloadUrlDownToObject (url: string): ApplicationFileUrlParams {
    if (url) {
      const applicableInfo = url.split('?')[1];

      if (applicableInfo) {
        const info = applicableInfo.split('&')
          .map(keyValueString => keyValueString.split('='))
          .reduce((acc, keyValue) => ({
            ...acc,
            [keyValue[0]]: keyValue[1]
          }), {
            applicationId: null,
            applicationFormId: null,
            fileId: null,
            fileName: ''
          });

        let fileName = info.fileName;
        try {
          const decoded = decodeURIComponent(info.fileName);
          fileName = decoded;
        } catch (e) { }

        return {
          ...info,
          fileName
        };
      }
    }

    return null;
  }

  async openFile (
    applicationId: number,
    fileId: number,
    applicationFormId?: number
  ) {
    const blob = await this.getFileFromFileInfo(applicationFormId, applicationId, fileId);
    const blobUrl = this.fileService.convertFileToUrl(blob);

    this.doOpen(blobUrl);
  }

  doOpen (url: string) {
    window.open(url, '_blank');
  }

  async downloadFile (
    applicationId: number,
    fileId: number,
    fileName: string,
    applicationFormId?: number
  ) {
    const blob = await this.getFileFromFileInfo(applicationFormId, applicationId, fileId);

    this.fileService.saveAs(blob, fileName);
  }

  getFile (
    applicationFormId: number,
    applicationId: number,
    fileId: number
  ) {
    return this.applicationFileResources.getFile(applicationId,  applicationFormId, fileId);
  }

  async getFileFromFileInfo (
    applicationFormId: number,
    applicationId: number,
    fileId: number
  ) {
    let url: string;
    if (!!applicationFormId) {
      const { accessUrl } = await this.getFile(
        applicationFormId,
        applicationId,
        fileId
      );
      url = accessUrl;
    } else {
      // Only scenario is address request files
      const {
        accessUrl
      } = await this.addressRequestResources.getManagerUrlForAddressRequestFile(
        applicationId,
        fileId
      );
      url = accessUrl;
    }

    const blob = await this.fileService.getBlob(url) as File;

    return blob;
  }
}
