import { Injectable } from '@angular/core';
import { GcFlyoutService } from '@core/services/gc-flyout.service';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { Applicant } from '@core/typings/applicant.typing';
import { AddEditUser, SimpleUser, User, UserFromApi, User_Table_Key } from '@core/typings/client-user.typing';
import { UsersImport, UsersValidationPayload } from '@core/typings/user.typing';
import { FlyoutService } from '@yourcause/common/flyout';
import { CSVBoolean, IsArrayOfType, IsEmail, IsString, Required, Transform, createValidator } from '@yourcause/common/form-control-validation';
import { I18nService } from '@yourcause/common/i18n';
import { ConfirmAndTakeActionService } from '@yourcause/common/modals';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { Subscription } from 'rxjs';
import { UserDetailFlyoutComponent } from './user-detail-flyout/user-detail-flyout.component';
import { UserResources } from './user.resources';
import { UserState } from './user.state';

@AttachYCState(UserState)
@Injectable({ providedIn: 'root' })
export class UserService extends BaseYCService<UserState> {
  sub = new Subscription();

   constructor (
    private i18n: I18nService,
    private notifier: NotifierService,
    private portal: PortalDeterminationService,
    private userResources: UserResources,
    private gcFlyoutService: GcFlyoutService,
    private flyoutService: FlyoutService,
    private confirmAndTakeAction: ConfirmAndTakeActionService
  ) {
    super();
    this.sub.add(
      this.changesTo$(this.userKey).subscribe(() => {
        const user = this.currentUser;
        const firstName = user ? user.firstName : '';
        const lastName = user ? user.lastName : '';
        const jobTitle = user ? (user as User).jobTitle : '';
        const name = `${
            firstName.slice(0, 10) + (firstName.length > 10 ? '...' : '')
          } ${
            lastName.slice(0, 15) + (lastName.length > 15 ? '...' : '')
          }`;
        this.set('userName', name);
        this.set('userJobTitle', jobTitle);
      })
    );
  }

  get userKey (): 'user'|'applicant'|'admin' {
    return this.portal.isManager ?
      'user' :
      this.portal.isApply ? 'applicant' : 'admin';
  }

  get user () {
    return this.get('user');
  }

  get allUsers () {
    return this.get('allUsers');
  }

  get allUsersMap () {
    return this.get('allUsersMap');
  }

  get allUsersDetailed () {
    return this.get('allUsersDetailed');
  }

  get userAudienceMap () {
    return this.get('userAudienceMap');
  }

  get applicant () {
    return this.get('applicant');
  }

  get userEmail () {
    return this.user ? this.user.email : '';
  }

  get currentUser () {
    return this.get(this.userKey);
  }

  get adminPermissions () {
    return this.get('adminPermissions');
  }

  get lastSelectedCurrency () {
    return this.get('lastSelectedCurrency');
  }

  setApplicant (applicant: Applicant) {
    this.set('applicant', applicant);
  }

  setLastSelectedCurrency (currency: string) {
    this.set('lastSelectedCurrency', currency);
  }

  setAdmin (admin: User) {
    this.set('admin', admin);
  }

  async setAdminPermissions () {
    const adminPermissions = await this.userResources.getAdminPermissions();
    this.set('adminPermissions', adminPermissions);
  }

  setUser (user: User) {
    this.set('user', user);
  }

  getCurrentUserCulture () {
    return this.currentUser ?
      this.currentUser.culture || 'en-US' :
      'en-US';
  }

  async resetAllUsers () {
    let hasAllUsers = !!this.allUsers;
    let hasAllDetailedUsers = !!this.allUsersDetailed;
    if (hasAllUsers) {
      this.set('allUsers', undefined);
    }
    if (hasAllDetailedUsers) {
      this.set('allUsersDetailed', undefined);
    }

    await Promise.all([
      hasAllUsers ? this.setAllUsers() : undefined,
      hasAllDetailedUsers ? this.setAllUsersDetailed() : undefined
    ]);
  }

  async setAllUsers () {
    if (!this.allUsers) {
      const allUsers = await this.userResources.getAllUsers();
      this.setAllUsersMap(allUsers);
      this.set('allUsers', allUsers);
    }
  }

  setAllUsersMap (allUsers: SimpleUser[]) {
    const allUsersMap = allUsers.reduce((acc, user) => {
      return {
        ...acc,
        [user.email]: user
      };
    }, {} as Record<string, SimpleUser>);
    this.set('allUsersMap', allUsersMap);
  }

  async setAllUsersDetailed () {
    if (!this.allUsersDetailed) {
      const allUsers = await this.userResources.getAllUsersDetailed();
      const adaptedUsers = allUsers.map((user) => {
        return {
          ...user,
          isCurrentUser: user.id === this.currentUser.id
        };
      });
      this.set('allUsersDetailed', adaptedUsers);
    }
  }

  /**
   * Do the add or edit of a client user
   * 
   * @param user: User to Add or Edit
   */
  async doAddEditUser (user: AddEditUser) {
    user.firstName = user.firstName.trim();
    user.lastName = user.lastName.trim();
    await this.userResources.addEditUser(user);
    await this.resetAllUsers();
  }

  /**
   * Handles Add or Editing a Client User
   * 
   * @param user: User to Add or Edit
   * @returns if the method was successful
   */
  async addEditUser (user: AddEditUser) {
    const {
      passed,
      error
    } = await this.confirmAndTakeAction.genericTakeAction(
      () => this.doAddEditUser(user),
      this.i18n.translate(
        user.id ?
          'USERS:textSuccessfullyUpdatedUser' :
          'USERS:textSuccessfullyAddedUser',
        {},
        user.id ?
          'Successfully updated the user' :
          'Successfully added the user'
      ),
      ''
    );
    if (!passed) {
      if ((error as any)?.error?.message === `ClientUser record already exists for this email and client combination.`) {
        this.notifier.error(this.i18n.translate(
          'common:textEmailAlreadyInUse',
          {},
          'Email address already in use'
        ));
      } else {
        this.notifier.error(this.i18n.translate(
          user.id ?
            'USERS:textErrorUpdatingUser' :
            'USERS:textErrorAddingUser',
          {},
          user.id ?
            'There was an error updating the user' :
            'There was an error adding the user'
        ));
      }
    }

    return passed;
  }

  /**
   * Does the Activation of a Client User
   * 
   * @param id: ID of user to activate
   */
  async doActivateUser (id: number) {
    await this.userResources.activateUser(id);
    await this.resetAllUsers();
  }

  /**
   * Handles activating a client user
   * 
   * @param id: ID of user to activate
   * @returns if it was successful
   */
  async activateUser (id: number) {
    const {
      passed
    } = await this.confirmAndTakeAction.genericTakeAction(
      () => this.doActivateUser(id),
        this.i18n.translate(
          'USERS:textSuccessfullyActivatedUser',
          {},
          'Successfully activated the user'
        ),
        this.i18n.translate(
        'USERS:textErrorActivatingUser',
        {},
        'There was an error activating the user'
      )
    );

    return passed;
  }

  /**
   * Do the Import of Client Users
   * 
   * @param users: Users to Import
   */
  async doImportUsers (users: UsersImport[]) {
    await this.userResources.importUsers(users);
    await this.resetAllUsers();
  }

  /**
   * Handles the Import of Client Users
   *
   * @param users: Users to Import
   * @returns if it was successful
   */
  async handleUsersImport (users: UsersImport[]) {
    const {
      passed
    } = await this.confirmAndTakeAction.genericTakeAction(
      () => this.doImportUsers(users),
      this.i18n.translate(
        'MANAGE:textSuccessfullyImportedUsers',
        {},
        'Successfully imported users'
      ),
      this.i18n.translate(
        'MANAGE:textErrorImportingUsers',
        {},
        'There was an error importing users'
      )
    );

    return passed;
  }

  /**
   * Deactivates a User
   *
   * @param clientUserId: User ID to Deactivate
   */
  async doDeactivateUser (
    clientUserId: number
  ) {
    await this.userResources.deactivateUser([clientUserId]);
    await this.resetAllUsers();
  }

  /**
   * Handles Deactivating a User
   * 
   * @param clientUserId: User ID to Deactivate
   * @returns if it was successful
   */
  async handleDeactivateUser (
    userId: number
  ) {
    const {
      passed
    } = await this.confirmAndTakeAction.genericTakeAction(
      () => this.doDeactivateUser(userId),
      this.i18n.translate(
        'USERS:textSuccessfullyDeactivatedUser',
        {},
        'Successfully deactivated the user'
      ),
      this.i18n.translate(
        'USERS:textErrorDeactivatingUser',
        {},
        'There was an error deactivating the user'
      )
    );

    return passed;
  }

  /**
   * Deactivates a group of users
   *
   * @param ids: IDs of users to deactivate
   */
  async doDeactivateUsers (ids: number[]) {
    await this.userResources.deactivateUser(ids);
    await this.resetAllUsers();
  }

  /**
   * Handles Deactivating a Group of Users
   *
   * @param ids: IDs of users to deactivate
   * @returns if it was successful
   */
  async deactivateUsers (ids: number[]) {
    const {
      passed
    } = await this.confirmAndTakeAction.genericTakeAction(
      () => this.doDeactivateUsers(ids),
      this.i18n.translate(
        'USERS:textSuccessfullyDeactivatedUsers',
        {},
        'Successfully deactivated the users'
      ),
      this.i18n.translate(
        'USERS:textErrorDeactivatingUsers',
        {},
        'There was an error deactivating the users'
      )
    );

    return passed;
  }

  async validateUsersImport (context: Record<'call', ReturnType<UserService['validateUsers']>>, group: UsersImportValidationModel[]) {
    if (!context.call) {
      const params = this.getUserImportParams(group);
      context.call = this.validateUsers(params);
    }
    const validatorResponse = await context.call;

    return validatorResponse;
  }

  getUserImportParams (group: UsersImportValidationModel[]) {
    return {
      emails: group.map((member) => member['Email']),
      roleIds: group.reduce((acc, item) => {
        return [...acc].concat(item['Roles']);
      }, []),
      workflowLevelIds: group.reduce((acc, item) => {
        return [...acc].concat(item['Workflow Levels']);
      }, [])
    };
  }

  async validateUsers (
    payload: UsersValidationPayload
  ) {
    const response = await this.userResources.validateUsers(payload);

    return {
      Email: response.emails,
      Roles: response.roleIds,
      'Workflow Levels': response.workflowLevelIds
    };
  }

  /**
   * Sets the User Audience Map
   *
   * @param id: User ID
   */
  async setUserAudienceMap (id: number) {
    if (!this.userAudienceMap[id]) {
      const audiences = await this.userResources.getAudiencesForUser(id);
      this.set('userAudienceMap', {
        ...this.userAudienceMap,
        [id]: audiences
      });
    }
  }

  /**
   * Resets the User Audience Map
   * 
   * @param id: User Id
   */
  async resetUserAudienceMapForUser (id: number) {
    if (!!this.userAudienceMap[id]) {
      this.set('userAudienceMap', {
        ...this.userAudienceMap,
        [id]: undefined
      });
      await this.setUserAudienceMap(id);
    }
  }

  resetUserAudienceMap () {
    this.set('userAudienceMap', {});
  }

  /**
   * Opens the user flyout for the given record
   *
   * @param user: User to open flyout for
   */
  async openUserFlyout (user: UserFromApi): Promise<void> {
    this.gcFlyoutService.setInfoForFlyout(user, User_Table_Key, 'userId');
    const only1Record = this.gcFlyoutService.idsForFlyout.length === 1;
    await this.flyoutService.openFlyout(
      UserDetailFlyoutComponent,
      {
        showIterator: !only1Record
      },
      this.gcFlyoutService.onNextFlyoutRecord,
      this.gcFlyoutService.onPreviousFlyoutRecord,
      this.prepareUserFlyout,
      this.gcFlyoutService.onInitialFlyoutRecord
    );
  }

  /**
   * Prepare the User Flyout
   */
  prepareUserFlyout = async (id: number|string) => {
    await this.setUserAudienceMap(id as number);
  };
}

export const UsersValidator = createValidator<UsersImportValidationModel, void, 'Email'|'Workflow Levels'|'Roles'>(() => async (
  _,
  {
    ent,
    attr,
    group,
    injector,
    context
  }
) => {
  const service: UserService = injector.get(UserService);
  const validatorResponse = await service.validateUsersImport(context, group);
  const valid = attr === 'Email' ? !validatorResponse[attr].includes(ent[attr]) : !validatorResponse[attr].some(a => ent[attr].includes(a));
  // break up functions, write comments and test (payment import for example)
  if (!valid) {
    switch (attr) {
      case 'Workflow Levels':
        return {
          i18nKey: 'common:textWorkflowLevelsMustExist',
          defaultValue: 'Workflow Level must exist in the system'
        };
      case 'Roles':
        return {
          i18nKey: 'common:textRoleMustExist',
          defaultValue: 'Roles must exist in the system'
        };
      case 'Email':
        return {
          i18nKey: 'common:textEmailAlreadyInUse',
          defaultValue: 'Email address already in use'
        };
      default:
        return null;
    }
  }

  return [];
});

export class UsersImportValidationModel {
  @IsString({
    maxLength: 50
  })
  @Required()
  'First Name': string;

  @IsString({
    maxLength: 50
  })
  @Required()
  'Last Name': string;

  @IsString({
    maxLength: 50
  })
  @Required()
  'Job Title': string;

  @IsEmail()
  @UsersValidator()
  @Required()
  'Email': string;

  @IsArrayOfType('number')
  @Transform((val: string) => {
    if (!val) {
      return [];
    }
    const data = val
      .split(',')
      .map(x => parseInt(x, 10));

    return data;
  })
  @UsersValidator()
  'Roles': number[];

  @IsArrayOfType('number')
  @Transform((val: string) => {
    if (!val) {
      return [];
    }
    const data = val
      .split(',')
      .map(x => parseInt(x, 10));

    return data;
  })
  @UsersValidator()
  'Workflow Levels': number[];

  @CSVBoolean()
  @Required()
  'Is SSO': boolean;
}
