import { Injectable } from '@angular/core';
import { ClassificationService } from '@core/services/classification.service';
import { StatusService } from '@core/services/status.service';
import { AdHocReportingAPI } from '@core/typings/api/ad-hoc-reporting.typing';
import { APIAdminClient } from '@core/typings/api/admin-client.typing';
import { FundingSourceTypes } from '@core/typings/budget.typing';
import { ProcessingTypes } from '@core/typings/payment.typing';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { AwardService } from '@features/awards/award.service';
import { BudgetService } from '@features/budgets/budget.service';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { CyclesService } from '@features/cycles/cycles.service';
import { EmployeeSSOFieldsService } from '@features/employee-sso-fields/employee-sso-fields.service';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { NonprofitService } from '@features/nonprofit/nonprofit.service';
import { AddressRequestsService } from '@features/platform-admin/address-requests/address-requests.service';
import { ProgramService } from '@features/programs/services/program.service';
import { SystemTagsService } from '@features/system-tags/system-tags.service';
import { SystemTags } from '@features/system-tags/typings/system-tags.typing';
import { WorkflowService } from '@features/workflow/workflow.service';
import { OrganizationEligibleForGivingStatus, PropertyNamesOfType } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
export type RelatedObjectNames = PropertyNamesOfType<AdHocReportingDefinitions, AdHocReportingUI.BaseObject>;
export type RootObjectNames = PropertyNamesOfType<AdHocReportingDefinitions, AdHocReportingUI.RootObject>;

@Injectable({ providedIn: 'root' })
export class AdHocReportingDefinitions {

  constructor (
    private programService: ProgramService,
    private awardService: AwardService,
    private workflowService: WorkflowService,
    private cycleService: CyclesService,
    private classificationService: ClassificationService,
    private employeeSSOFieldsService: EmployeeSSOFieldsService,
    private budgetService: BudgetService,
    private addressRequestService: AddressRequestsService,
    private systemTagsService: SystemTagsService,
    private nonprofitService: NonprofitService,
    private formFieldHelperService: FormFieldHelperService,
    private i18n: I18nService,
    private statusService: StatusService,
    private clientSettingsService: ClientSettingsService
  ) { }
  processorTypes = [{
    label: this.i18n.translate(
      'ADMIN:textClientAndYourCause',
      {},
      'Client and YourCause'
    ),
    value: ProcessingTypes.Both
  }, {
    label: this.i18n.translate(
      'common:labelClient',
      {},
      'Client'
    ),
    value: ProcessingTypes.Client
  }, {
    label: 'YourCause',
    value: ProcessingTypes.YourCause
  }];
  eligibleForGivingStatusTypes = [{
    label: this.i18n.translate(
      'common:textUnknown',
      {},
      'Unkown'
    ),
    value: OrganizationEligibleForGivingStatus.UNKNOWN
  }, {
    label: this.i18n.translate(
      'common:textEligible',
      {},
      'Eligible'
    ),
    value: OrganizationEligibleForGivingStatus.ELIGIBLE
  }, {
    label: this.i18n.translate(
      'common:textInformationRequired',
      {},
      'Information required'
    ),
    value: OrganizationEligibleForGivingStatus.INFO_REQUIRED
  }, {
    label: this.i18n.translate(
      'common:textIneligible',
      {},
      'Ineligible'
    ),
    value: OrganizationEligibleForGivingStatus.INELIGBLE
  }];


  get hasNominations () {
    return this.clientSettingsService.doesClientHaveClientFeature(APIAdminClient.ClientFeatureTypes.HasNominations);

  }

  get canReserveFunds () {
    return this.clientSettingsService.doesClientHaveClientFeature(APIAdminClient.ClientFeatureTypes.ReserveFunds);
  }

  get canAccessSpecialHandling () {
    return this.clientSettingsService.doesClientHaveClientFeature(APIAdminClient.ClientFeatureTypes.AllowApplicantSpecialHandling);
  }

  get hideFundingSources () {
    return this.clientSettingsService.hideFundingSources;
  }

  /* APPLICATION */
  private _application: AdHocReportingUI.RootObject;
  get application (): AdHocReportingUI.RootObject {
    let reserveColumns: AdHocReportingUI.ColumnDefinition[] = [];
    if (this.canReserveFunds) {
      reserveColumns = [{
        display: 'Reserved amount',
        i18nKey: 'GLOBAL:textReservedAmount',
        type: 'currency',
        column: 'reservedFunds',
        supportsMinMaxSum: true
      }, {
        display: 'Is auto-declined',
        i18nKey: 'GLOBAL:textIsAutoDeclined',
        type: 'boolean',
        column: 'autoDeclined',
        supportsGrouping: true
      }, {
        display: 'Is reserved',
        i18nKey: 'GLOBAL:textIsReserved',
        type: 'boolean',
        column: 'isReserved',
        supportsGrouping: true
      }];
    }

    return this._application || (this._application = {
      supportsSummaryWidgets: false,
      omitRootObject: false,
      display: this.hasNominations ?
        'Applications / Nominations' :
        'Applications',
      i18nKey: this.hasNominations ?
        'common:hdrApplicationsNominations' :
        'common:hdrApplications',
      editEndpoint: 'applications',
      readOnlyEndpoint: 'Application',
      chartEndpoint: 'GetApplicationsChart',
      previewChartEndpoint: 'GetApplicationsChartPreview',
      property: 'application',
      columns: [
        ...reserveColumns,
        {
        display: 'ID',
        i18nKey: 'common:textId',
        type: 'number',
        format: 'id',
        column: 'id',
        canBeUsedAsReportField: true
      }, {
        display: 'Is draft',
        i18nKey: 'GLOBAL:textIsDraft',
        type: 'boolean',
        column: 'isDraft',
        supportsGrouping: true
      }, {
        display: 'Submitted date',
        i18nKey: 'GLOBAL:lblSubmittedDate',
        type: 'date',
        format: 'date',
        column: 'submittedDate',
        canBeUsedAsReportField: true
      }, {
        display: 'Submitted date month',
        i18nKey: 'GLOBAL:lblSubmittedDateMonth',
        type: 'text',
        column: 'submittedDateMonth',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Submitted date year',
        i18nKey: 'GLOBAL:lblSubmittedDateYear',
        type: 'text',
        column: 'submittedDateYear',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Create date',
        i18nKey: 'GLOBAL:lblCreatedDate',
        type: 'date',
        format: 'date',
        column: 'createdDate'
      }, {
        display: 'Create date month',
        i18nKey: 'GLOBAL:lblCreateDateMonth',
        type: 'text',
        column: 'createdDateMonth',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Create date year',
        i18nKey: 'GLOBAL:lblCreateDateYear',
        type: 'text',
        column: 'createdDateYear',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Update date',
        i18nKey: 'GLOBAL:lblUpdatedDate',
        type: 'date',
        format: 'date',
        column: 'updatedDate'
      }, {
        display: 'Update date month',
        i18nKey: 'GLOBAL:lblUpdateDateMonth',
        type: 'text',
        column: 'updatedDateMonth',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Update date year',
        i18nKey: 'GLOBAL:lblUpdateDateYear',
        type: 'text',
        column: 'updatedDateYear',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Application status',
        i18nKey: 'GLOBAL:lblApplicationStatus',
        type: 'multi-list',
        filterOptions: this.statusService.get('adHocAppStatusOptions'),
        format: 'label',
        column: 'applicationStatus',
        supportsGrouping: true
      }, {
        display: 'Status update date',
        i18nKey: 'GLOBAL:lblStatusUpdateDate',
        type: 'date',
        format: 'date',
        column: 'statusUpdatedDate'
      }, {
        display: 'Status update date month',
        i18nKey: 'GLOBAL:lblStatusUpdateDateMonth',
        type: 'text',
        column: 'statusUpdatedDateMonth',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Status update date year',
        i18nKey: 'GLOBAL:lblStatusUpdateDateYear',
        type: 'text',
        column: 'statusUpdatedDateYear',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Approved date',
        i18nKey: 'GLOBAL:lblApprovedDate',
        type: 'date',
        format: 'date',
        column: 'approvedDate',
        canBeUsedAsReportField: true
      }, {
        display: 'Approved date month',
        i18nKey: 'GLOBAL:lblApprovedDateMonth',
        type: 'text',
        column: 'approvedDateMonth',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Approved date year',
        i18nKey: 'GLOBAL:lblApprovedDateYear',
        type: 'text',
        column: 'approvedDateYear',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Declined date',
        i18nKey: 'GLOBAL:lblDeclinedDate',
        type: 'date',
        format: 'date',
        column: 'declinedDate'
      }, {
        display: 'Declined date month',
        i18nKey: 'GLOBAL:lblDeclinedDateMonth',
        type: 'text',
        column: 'declinedDateMonth',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Declined date year',
        i18nKey: 'GLOBAL:lblDeclinedDateYear',
        type: 'text',
        column: 'declinedDateYear',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Last award date',
        i18nKey: 'GLOBAL:lblLastAwardDate',
        type: 'date',
        format: 'date',
        column: 'lastAwardDate',
        notApplicableToNominations: true
      }, {
        display: 'Last award date month',
        i18nKey: 'GLOBAL:lblLastAwardDateMonth',
        type: 'text',
        column: 'lastAwardDateMonth',
        supportsGrouping: true,
        dashboardOnly: true,
        notApplicableToNominations: true
      }, {
        display: 'Last award date year',
        i18nKey: 'GLOBAL:lblLastAwardDateYear',
        type: 'text',
        column: 'lastAwardDateYear',
        supportsGrouping: true,
        dashboardOnly: true,
        notApplicableToNominations: true
      }, {
        display: 'Last payment date',
        i18nKey: 'GLOBAL:lblLastPaymentDate',
        type: 'date',
        format: 'date',
        column: 'lastPaymentDate',
        notApplicableToNominations: true
      }, {
        display: 'Last payment date month',
        i18nKey: 'GLOBAL:lblLastPaymentDateMonth',
        type: 'text',
        column: 'lastPaymentDateMonth',
        supportsGrouping: true,
        dashboardOnly: true,
        notApplicableToNominations: true
      }, {
        display: 'Last payment date year',
        i18nKey: 'GLOBAL:lblLastPaymentDateYear',
        type: 'text',
        column: 'lastPaymentDateYear',
        supportsGrouping: true,
        dashboardOnly: true,
        notApplicableToNominations: true
      }, {
        display: 'Is archived',
        i18nKey: 'GLOBAL:lblIsArchived',
        type: 'boolean',
        column: 'isArchived',
        supportsGrouping: true
      }, {
        display: 'Archive notes',
        i18nKey: 'GLOBAL:lblArchiveNotes',
        type: 'text',
        column: 'archiveNotes'
      }, {
        display: 'Archive reason code',
        i18nKey: 'GLOBAL:lblArchiveReasonCode',
        type: 'multi-list',
        column: 'archiveReasonCode',
        format: 'label',
        filterOptions: this.statusService.get('archiveReasonCodes'),
        supportsGrouping: true
      }, {
        display: 'Created by',
        i18nKey: 'common:hdrCreatedBy',
        type: 'text',
        column: 'createdBy'
      }, {
        display: 'Updated by',
        i18nKey: 'GLOBAL:lblUpdatedBy',
        type: 'text',
        column: 'updatedBy'
      }, {
        display: 'Submitted by',
        i18nKey: 'GLOBAL:lblSubmittedBy',
        type: 'text',
        column: 'submittedBy'
      }, {
        display: 'Cash amount requested',
        i18nKey: 'GLOBAL:lblCashAmountRequested',
        type: 'currency',
        column: 'amountRequested',
        supportsMinMaxSum: true,
        canBeUsedAsReportField: true
      }, {
        display: 'Recommended funding amount',
        i18nKey: 'GLOBAL:textRecommendedFundingAmount',
        type: 'currency',
        column: 'recommendedFundingAmount',
        supportsMinMaxSum: true,
        canBeUsedAsReportField: true,
        notApplicableToNominations: true
      }, {
        display: 'Cash amount requested equivalent',
        i18nKey: 'GLOBAL:textCashAmountRequestedEquivalent',
        type: 'currency',
        format: 'otherColumn',
        formatSource: 'currencyRequested',
        column: 'currencyRequestedAmountEquivalent'
      }, {
        display: 'Currency requested',
        i18nKey: 'GLOBAL:textCurrencyRequested',
        type: 'text',
        column: 'currencyRequested',
        supportsGrouping: true
      }, {
        display: 'Total amount requested',
        i18nKey: 'GLOBAL:lblTotalAmountRequested',
        type: 'currency',
        column: 'totalAmountRequested',
        supportsMinMaxSum: true, 
        canBeUsedAsReportField: true
      }, {
        display: 'In-kind amount requested',
        i18nKey: 'GLOBAL:lblInKindAmountRequested',
        type: 'currency',
        column: 'inKindAmountRequested',
        supportsMinMaxSum: true
      }, {
        display: 'Total in-kind units requested',
        i18nKey: 'GLOBAL:lblTotalInKindUnitsRequested',
        type: 'number',
        format: 'number',
        column: 'inKindItemCount'
      }, {
        display: 'Import ID',
        i18nKey: 'GLOBAL:lblImportID',
        type: 'text',
        column: 'importId'
      }, {
        display: 'Payment designation',
        i18nKey: 'GLOBAL:lblPaymentDesignation',
        type: 'text',
        column: 'paymentDesignation'
      },  {
        display: 'Attention',
        i18nKey: 'FORMS:textAttention',
        type: 'text',
        column: 'careOf'
      }, {
        display: 'Tags',
        i18nKey: 'GLOBAL:lblTags',
        type: 'multiValueList',
        column: 'tagList',
        filterOptions: this.systemTagsService.getTagOptions(
          SystemTags.Buckets.Application
        )
      }, {
        display: 'Nomination ID',
        i18nKey: 'GLOBAL:textNominationID',
        type: 'number',
        format: 'id',
        column: 'nominationId'
      }, {
        display: 'Nominator name',
        i18nKey: 'GLOBAL:textNominatorName',
        type: 'text',
        column: 'nominatorName'
      }, {
        display: 'Nominator email',
        i18nKey: 'GLOBAL:textNominatorEmail',
        type: 'text',
        column: 'nominatorEmail'
      }, {
        display: 'Collaborator email',
        i18nKey: 'GLOBAL:textCollaboratorEmail',
        type: 'text',
        column: 'collaboratorEmails'
      }, {
        display: 'Collaborator name',
        i18nKey: 'GLOBAL:textCollaboratorName',
        type: 'text',
        column: 'collaboratorNames'
      }, {
        display: 'Number of awards',
        i18nKey: 'GLOBAL:textNumberOfAwards',
        type: 'number',
        format: 'number',
        column: 'numberOfAwards',
        notApplicableToNominations: true
      }, {
        display: 'Number of payments',
        i18nKey: 'GLOBAL:textNumberOfPayments',
        type: 'number',
        format: 'number',
        column: 'numberOfPayments',
        notApplicableToNominations: true
      }, {
        display: 'Total cash award amount',
        i18nKey: 'GLOBAL:textTotalCashAwardAmount',
        type: 'currency',
        column: 'totalCashAwardAmount',
        canBeUsedAsReportField: true,
        notApplicableToNominations: true
      }, {
        display: 'Total cash award amount in currency requested',
        i18nKey: 'GLOBAL:textTotalCashAwardAmountInCurrencyRequested',
        type: 'currency',
        column: 'totalCashAwardAmountEquivalentInCurrencyRequested',
        notApplicableToNominations: true
      }, {
        display: 'Total in-kind award amount',
        i18nKey: 'GLOBAL:textTotalInKindAwardAmount',
        type: 'currency',
        column: 'totalInKindAwardAmount',
        canBeUsedAsReportField: true,
        notApplicableToNominations: true
      }, {
        display: 'Total cash payment amount',
        i18nKey: 'GLOBAL:textTotalCashPaymentAmount',
        type: 'currency',
        column: 'totalCashPaymentAmount',
        canBeUsedAsReportField: true,
        notApplicableToNominations: true
      },
      {
        display: 'Total cash payment amount in currency requested',
        i18nKey: 'GLOBAL:textTotalCashPaymentAmountEquivalentInCurrencyRequested',
        type: 'currency',
        column: 'totalCashPaymentAmountEquivalentInCurrencyRequested',
        canBeUsedAsReportField: true,
        notApplicableToNominations: true
      }, {
        display: 'Total in-kind payment amount',
        i18nKey: 'GLOBAL:textTotalInKindPaymentAmount',
        type: 'currency',
        column: 'totalInKindPaymentAmount',
        canBeUsedAsReportField: true,
        notApplicableToNominations: true
      }, {
        display: 'Cash award ID',
        i18nKey: 'GLOBAL:textCashAwardId',
        type: 'number',
        format: 'id',
        column: 'cashAwardId',
        notApplicableToNominations: true
      }, {
        display: 'In kind award ID',
        i18nKey: 'GLOBAL:textInKindAwardId',
        type: 'number',
        format: 'id',
        column: 'inKindAwardId',
        notApplicableToNominations: true
      }, {
        display: 'Is awarded',
        i18nKey: 'common:textIsAwarded',
        type: 'boolean',
        column: 'isAwarded',
        notApplicableToNominations: true
      }, {
        display: 'Has form to complete',
        i18nKey: 'common:textHasFormToComplete',
        type: 'boolean',
        column: 'hasFormToComplete'
      }, {
        display: 'Has applied previously to program',
        i18nKey: 'common:textHasAppliedPreviouslyToProgram',
        type: 'boolean',
        column: 'hasAppliedPreviouslyToProgram',
        noFiltering: true
      }, {
        display: 'In my workflow level',
        i18nKey: 'common:textInMyWorkflowLevel',
        type: 'boolean',
        column: 'isInMyWorkflowLevel'
      }, {
        display: 'In my workflow',
        i18nKey: 'common:textInMyWorkflow',
        type: 'boolean',
        column: 'isInMyWorkflow'
      },
      ...(this.employeeSSOFieldsService.applicantColumnsForReporting || [])
        .map(col => {
        return  {
          ...col,
          supportsGrouping: true
        };
      })],
      type: AdHocReportingAPI.AdHocReportModelType.Application,
      isRootObject: true,
      relatedObjects: [
        'organization',
        this.hasNominations ? 'relatedNominator' : null,
        'applicant',
        'program',
        'workflowLevel',
        'assignedBudget',
        !this.hideFundingSources ? 'assignedFundingSource' : null,
        'todaysDate',
        this.canAccessSpecialHandling ? 'payee' : null,
        'cycle',
        'client',
        this.hasNominations ? 'nominee' : null
      ].filter((obj) => !!obj) as RelatedObjectNames[]
    });
  }

  /* APPLICATION IN KIND */
  private _applicationInKind: AdHocReportingUI.RootObject;
  get applicationInKind (): AdHocReportingUI.RootObject {
    return this._applicationInKind || (this._applicationInKind = {
      supportsSummaryWidgets: false,
      omitRootObject: false,
      display: 'In kind amount requested',
      i18nKey: 'common:hdrInKindAmountRequested',
      editEndpoint: 'inkindapplications',
      readOnlyEndpoint: 'InKindApplications',
      chartEndpoint: 'GetApplicationInKindItemChart',
      previewChartEndpoint: 'GetApplicationInKindItemChartPreview',
      property: 'inKindItem',
      columns: [{
        display: 'ID',
        i18nKey: 'common:textId',
        type: 'number',
        format: 'id',
        column: 'id'
      }, {
        display: 'Identification',
        i18nKey: 'GLOBAL:IDENTIFICATION',
        type: 'text',
        column: 'itemIdentification',
        supportsGrouping: true
      }, {
        display: 'Units',
        i18nKey: 'common:textUnits',
        type: 'number',
        format: 'number',
        supportsMinMaxSum: true,
        column: 'itemCount'
      }, {
        display: 'Name',
        i18nKey: 'common:lblName',
        type: 'text',
        column: 'itemName'
      }, {
        display: 'Value',
        i18nKey: 'common:hdrValue',
        type: 'currency',
        column: 'itemValue'
      }],
      type: AdHocReportingAPI.AdHocReportModelType.ApplicationInKindAmountRequested,
      isRootObject: true,
      relatedObjects: [
        ...this.application.relatedObjects.filter((obj) => {
          return obj !== 'assignedBudget' && obj !== 'assignedFundingSource';
        }),
        'application'
      ] as RelatedObjectNames[]
    });
  }

  /* AWARD */
  private _award: AdHocReportingUI.RootObject;
  get award (): AdHocReportingUI.RootObject {
    return this._award || (this._award = {
      supportsSummaryWidgets: false,
      omitRootObject: false,
      display: 'Awards',
      i18nKey: 'common:hdrAwards',
      editEndpoint: 'Awards',
      readOnlyEndpoint: 'Award',
      chartEndpoint: 'GetAwardsChart',
      previewChartEndpoint: 'GetAwardsChartPreview',
      property: 'award',
      columns: [{
        display: 'ID',
        i18nKey: 'common:textId',
        type: 'number',
        format: 'id',
        column: 'id'
      }, {
        display: 'Amount',
        i18nKey: 'GLOBAL:textAmount',
        type: 'currency',
        column: 'amount',
        supportsMinMaxSum: true
      }, {
        display: 'Amount equivalent',
        i18nKey: 'GLOBAL:textAmountEquivalent',
        type: 'currency',
        format: 'otherColumn',
        formatSource: 'currencyRequested',
        column: 'currencyRequestedAmountEquivalent'
      }, {
        display: 'Currency requested',
        i18nKey: 'GLOBAL:textCurrencyRequested',
        type: 'text',
        column: 'currencyRequested',
        supportsGrouping: true
      }, {
        display: 'Description',
        i18nKey: 'common:textDescription',
        type: 'text',
        column: 'description'
      }, {
        display: 'Award date',
        i18nKey: 'AWARDS:textAwardDate',
        type: 'date',
        format: 'date',
        column: 'awardDate',
        canBeUsedAsReportField: true
      }, {
        display: 'Award date month',
        i18nKey: 'GLOBAL:lblAwardDateMonth',
        type: 'text',
        column: 'awardDateMonth',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Award date year',
        i18nKey: 'GLOBAL:lblAwardDateYear',
        type: 'text',
        column: 'awardDateYear',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Awarded by',
        i18nKey: 'GLOBAL:lblAwardedBy',
        type: 'text',
        column: 'createdBy'
      }, {
        display: 'Updated by',
        i18nKey: 'GLOBAL:lblUpdatedBy',
        type: 'text',
        column: 'updatedBy'
      }, {
        display: 'Create date',
        i18nKey: 'GLOBAL:lblCreatedDate',
        type: 'date',
        format: 'date',
        column: 'createdDate'
      }, {
        display: 'Create date month',
        i18nKey: 'GLOBAL:lblCreateDateMonth',
        type: 'text',
        column: 'createdDateMonth',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Create date year',
        i18nKey: 'GLOBAL:lblCreateDateYear',
        type: 'text',
        column: 'createdDateYear',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Update date',
        i18nKey: 'GLOBAL:lblUpdatedDate',
        type: 'date',
        format: 'date',
        column: 'updatedDate'
      }, {
        display: 'Update date month',
        i18nKey: 'GLOBAL:lblUpdateDateMonth',
        type: 'text',
        column: 'updatedDateMonth',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Update date year',
        i18nKey: 'GLOBAL:lblUpdateDateYear',
        type: 'text',
        column: 'updatedDateYear',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Import ID',
        i18nKey: 'GLOBAL:lblImportID',
        type: 'text',
        column: 'importId'
      }, {
        display: 'Number of payments',
        i18nKey: 'GLOBAL:textNumberOfPayments',
        type: 'number',
        format: 'number',
        column: 'numberOfPayments'
      }, {
        display: 'Total cash award amount',
        i18nKey: 'GLOBAL:textTotalCashAwardAmount',
        type: 'currency',
        column: 'totalCashAwardAmount'
      }, {
        display: 'Total in-kind award amount',
        i18nKey: 'GLOBAL:textTotalInKindAwardAmount',
        type: 'currency',
        column: 'totalInKindAwardAmount'
      }],
      type: AdHocReportingAPI.AdHocReportModelType.Award,
      isRootObject: true,
      relatedObjects: [
        'organization',
        'applicant',
        this.hasNominations ? 'relatedNominator' : null,
        'application',
        'program',
        'assignedBudget',
        !this.hideFundingSources ? 'assignedFundingSource' : null,
        'workflowLevel',
        'cycle'
      ].filter((obj) => !!obj) as RelatedObjectNames[]
    });
  }

  /* AWARD IN KIND */
  private _awardInKind: AdHocReportingUI.RootObject;
  get awardInKind (): AdHocReportingUI.RootObject {
    return this._awardInKind || (this._awardInKind = {
      supportsSummaryWidgets: false,
      omitRootObject: false,
      display: 'In kind items awarded',
      i18nKey: 'common:hdrInKindItemsAwarded',
      editEndpoint: 'InKindAwards',
      readOnlyEndpoint: 'InKindAwards',
      chartEndpoint: 'GetAwardInKindItemChart',
      previewChartEndpoint: 'GetAwardInKindItemChartPreview',
      property: 'inKindItem',
      columns: [
        ...(this.applicationInKind.columns || []).map((col) => {
          return {
            ...col
          };
        })
      ],
      type: AdHocReportingAPI.AdHocReportModelType.InKindAwardItems,
      isRootObject: true,
      relatedObjects: [
        ...this.award.relatedObjects.filter((obj) => {
          return obj !== 'assignedBudget' && obj !== 'assignedFundingSource';
        }),
        'award'
      ] as RelatedObjectNames[]
    });
  }

  /* Field Group */

  private _fieldGroup: AdHocReportingUI.RootObject;
  get fieldGroup (): AdHocReportingUI.RootObject {
    return this._fieldGroup || (this._fieldGroup = {
      supportsSummaryWidgets: false,
      recordOptions: this.formFieldHelperService.allReferenceFields
        .filter(field => field.type === ReferenceFieldsUI.ReferenceFieldTypes.Subset)
        .map(field => {
          return {
            label: field.name,
            value: field.referenceFieldId
          };
        }),
      omitRootObject: false,
      noFormLogic: true,
      display: 'Field group responses',
      i18nKey: 'reporting:textFieldGroupResponses',
      editEndpoint: 'FieldGroups',
      readOnlyEndpoint: '',
      chartEndpoint: 'GetFieldGroupChart',
      previewChartEndpoint: 'GetFieldGroupChartPreview',
      property: 'fieldGroup',
      columns: [{
        column: 'numericValue',
        type: 'number',
        noFiltering: true,
        supportsMinMaxSum: true,
        format: 'number',
        display: 'Value',
        i18nKey: 'common:hdrValue'
      }, {
        column: 'referenceFieldId',
        type: 'number',
        format: 'id',
        display: 'ID',
        noFiltering: true,
        i18nKey: 'common:textId'
      }, {
        column: 'referenceFieldKey',
        type: 'text',
        display: 'Key',
        i18nKey: 'common:hdrKey'
      }, {
        column: 'referenceFieldName',
        type: 'text',
        display: 'Name',
        supportsGrouping: true,
        i18nKey: 'common:lblName'
      }],
      type: AdHocReportingAPI.AdHocReportModelType.FieldGroup,
      isRootObject: true,
      relatedObjects: [
        'organization',
        'applicant',
        this.hasNominations ? 'relatedNominator' : null,
        'application',
        'program',
        'workflowLevel',
        'payee',
        'cycle'
      ].filter((obj) => !!obj) as RelatedObjectNames[],
      additionalDashboardProperties: {
        fieldGroupReferenceFieldId: 'summaryRecordId'
      }
    });
  }

  /* CUSTOM FORM */
  private _customForm: AdHocReportingUI.RootObject;
  get customForm (): AdHocReportingUI.RootObject {
    return this._customForm || (this._customForm = {
      supportsSummaryWidgets: false,
      omitRootObject: true,
      display: 'Custom form responses',
      i18nKey: 'reporting:textCustomFormResponses',
      editEndpoint: 'ApplicationForms',
      readOnlyEndpoint: 'ApplicationForm',
      chartEndpoint: 'GetApplicationFormsChart',
      previewChartEndpoint: 'GetApplicationFormsChartPreview',
      property: 'formData',
      columns: [],
      type: AdHocReportingAPI.AdHocReportModelType.ApplicationForm,
      isRootObject: true,
      relatedObjects: [
        'application',
        'organization',
        this.hasNominations ? 'relatedNominator' : null,
        'applicant',
        'program',
        'assignedBudget',
        !this.hideFundingSources ? 'assignedFundingSource' : null,
        'workflowLevel',
        'cycle',
        this.hasNominations ? 'nominee' : null,
        'form'
      ].filter((obj) => !!obj)
    });
  }

  /* PAYMENT */
  private _payment: AdHocReportingUI.RootObject;
  get payment (): AdHocReportingUI.RootObject {
    return this._payment || (this._payment = {
      supportsSummaryWidgets: false,
      omitRootObject: false,
      display: 'Payments',
      i18nKey: 'common:hdrPayments',
      editEndpoint: 'Payments',
      readOnlyEndpoint: 'Payment',
      chartEndpoint: 'GetPaymentsChart',
      previewChartEndpoint: 'GetPaymentsChartPreview',
      property: 'payment',
      columns: [{
        display: 'ID',
        i18nKey: 'common:textId',
        type: 'number',
        format: 'id',
        column: 'id'
      }, {
        display: 'Scheduled date',
        i18nKey: 'APPLY:lblScheduledDate',
        type: 'date',
        format: 'date',
        column: 'scheduledDate'
      }, {
        display: 'Scheduled date month',
        i18nKey: 'GLOBAL:lblScheduledDateMonth',
        type: 'text',
        column: 'scheduledDateMonth',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Scheduled date year',
        i18nKey: 'GLOBAL:lblScheduledDateYear',
        type: 'text',
        column: 'scheduledDateYear',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Payment amount',
        i18nKey: 'AWARDS:lblPaymentAmount',
        type: 'currency',
        column: 'totalAmount',
        supportsMinMaxSum: true
      }, {
        display: 'Payment amount equivalent',
        i18nKey: 'AWARDS:lblPaymentAmountEquivalent',
        type: 'currency',
        format: 'otherColumn',
        formatSource: 'currencyRequested',
        column: 'currencyRequestedAmountEquivalent'
      }, {
        display: 'Currency requested',
        i18nKey: 'GLOBAL:textCurrencyRequested',
        type: 'text',
        column: 'currencyRequested',
        supportsGrouping: true
      }, {
        display: 'Total units',
        i18nKey: 'BUDGET:textTotalUnits',
        type: 'number',
        format: 'number',
        supportsMinMaxSum: true,
        column: 'totalUnits'
      }, {
        display: 'Status',
        i18nKey: 'common:hdrStatus',
        type: 'multi-list',
        format: 'label',
        column: 'paymentStatus',
        filterOptions: this.statusService.get('paymentStatusOptions'),
        supportsGrouping: true
      }, {
        display: 'Substatus',
        i18nKey: 'common:hdrSubstatus',
        type: 'multi-list',
        format: 'label',
        column: 'substatus',
        filterOptions: this.statusService.get('paymentSubStatusOptions')
      }, {
        display: 'Status date',
        i18nKey: 'GLOBAL:STATUS_DATE',
        type: 'date',
        format: 'date',
        column: 'statusDate'
      }, {
        display: 'Status date month',
        i18nKey: 'GLOBAL:lblStatusDateMonth',
        type: 'text',
        column: 'statusDateMonth',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Status date year',
        i18nKey: 'GLOBAL:lblStatusDateYear',
        type: 'text',
        column: 'statusDateYear',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Notes',
        i18nKey: 'GLOBAL:NOTES',
        type: 'text',
        column: 'notes'
      }, {
        display: 'Created by',
        i18nKey: 'common:hdrCreatedBy',
        type: 'text',
        column: 'createdBy'
      }, {
        display: 'Updated by',
        i18nKey: 'GLOBAL:lblUpdatedBy',
        type: 'text',
        column: 'updatedBy'
      }, {
        display: 'Create date',
        i18nKey: 'GLOBAL:lblCreatedDate',
        type: 'date',
        format: 'date',
        column: 'createdDate'
      }, {
        display: 'Create date month',
        i18nKey: 'GLOBAL:lblCreateDateMonth',
        type: 'text',
        column: 'createdDateMonth',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Create date year',
        i18nKey: 'GLOBAL:lblCreateDateYear',
        type: 'text',
        column: 'createdDateYear',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Update date',
        i18nKey: 'GLOBAL:lblUpdatedDate',
        type: 'date',
        format: 'date',
        column: 'updatedDate'
      }, {
        display: 'Update date month',
        i18nKey: 'GLOBAL:lblUpdateDateMonth',
        type: 'text',
        column: 'updatedDateMonth',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Update date year',
        i18nKey: 'GLOBAL:lblUpdateDateYear',
        type: 'text',
        column: 'updatedDateYear',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Cleared date',
        i18nKey: 'GLOBAL:lblClearedDate',
        type: 'date',
        format: 'date',
        column: 'clearedDate'
      }, {
        display: 'Cleared date month',
        i18nKey: 'GLOBAL:lblClearedDateMonth',
        type: 'text',
        column: 'clearedDateMonth',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Cleared date year',
        i18nKey: 'GLOBAL:lblClearedDateYear',
        type: 'text',
        column: 'clearedDateYear',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Account number',
        i18nKey: 'GLOBAL:textAccountNumber',
        type: 'text',
        column: 'accountNumber',
        supportsGrouping: true
      }, {
        display: 'Issue date',
        i18nKey: 'GLOBAL:lblIssueDate',
        type: 'date',
        format: 'date',
        column: 'issuedDate'
      }, {
        display: 'Issue date month',
        i18nKey: 'GLOBAL:lblIssueDateMonth',
        type: 'text',
        column: 'issuedDateMonth',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Issue date year',
        i18nKey: 'GLOBAL:lblIssueDateYear',
        type: 'text',
        column: 'issuedDateYear',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'On hold',
        i18nKey: 'GLOBAL:textOnHold',
        type: 'boolean',
        column: 'onHold',
        supportsGrouping: true
      }, {
        display: 'Payment number',
        i18nKey: 'MANAGE:textPaymentNumber',
        type: 'text',
        column: 'paymentNumber'
      }, {
        display: 'Original payment ID',
        i18nKey: 'GLOBAL:textOriginalPaymentID',
        type: 'number',
        format: 'id',
        column: 'reissuedForPaymentId'
      }, {
        display: 'Reissued Payment ID',
        i18nKey: 'GLOBAL:textReissuedPaymentID',
        type: 'number',
        format: 'id',
        column: 'reissuedInPaymentId'
      },  {
        display: 'Is ACH',
        i18nKey: 'GLOBAL:lblIsACH',
        type: 'boolean',
        column: 'isACH'
      }, {
        display: 'On hold notes',
        i18nKey: 'GLOBAL:lblOnHoldNotes',
        type: 'text',
        column: 'onHoldNotes'
      }, {
        display: 'Batch notes',
        i18nKey: 'GLOBAL:lblBatchNotes',
        type: 'text',
        column: 'batchNotes'
      }, {
        display: 'Is expedited',
        i18nKey: 'GLOBAL:lblIsExpedited',
        type: 'boolean',
        column: 'isExpedited'
      }, {
        display: 'Expedited notes',
        i18nKey: 'GLOBAL:lblExpeditedNotes',
        type: 'text',
        column: 'expeditedNotes'
      }, {
        display: 'Designation',
        i18nKey: 'GLOBAL:textDesignation',
        type: 'text',
        column: 'paymentDesignation'
      }, {
        display: 'Attention',
        i18nKey: 'FORMS:textAttention',
        type: 'text',
        column: 'careOf'
      }, {
        display: 'Has alternate address request',
        i18nKey: 'GLOBAL:textHasAlternateAddressRequest',
        type: 'boolean',
        column: 'hasAddressRequest'
      }, {
        display: 'Import ID',
        i18nKey: 'GLOBAL:lblImportID',
        type: 'text',
        column: 'importId'
      }, {
        display: 'Tags',
        i18nKey: 'GLOBAL:lblTags',
        type: 'multiValueList',
        column: 'tagList',
        filterOptions: this.systemTagsService.getTagOptions(
          SystemTags.Buckets.Payment
        )
      }, {
        display: 'Batch name',
        i18nKey: 'MANAGE:textBatchName',
        type: 'text',
        column: 'paymentBatchName'
      }, {
        display: 'Batch ID',
        i18nKey: 'MANAGE:textBatchId',
        type: 'number',
        format: 'id',
        column: 'paymentBatchId',
        supportsGrouping: true
      }, {
        display: 'Batch external ID',
        i18nKey: 'MANAGE:textBatchExternalId',
        type: 'text',
        column: 'paymentBatchExternalId'
      }, {
        display: 'Batch Submitted Date',
        i18nKey: 'MANAGE:textBatchSubmittedDate',
        type: 'date',
        format: 'date',
        column: 'paymentBatchSubmittedDate'
      },
      {
        display: 'Transaction ID',
        i18nKey: 'GLOBAL:lblTransactionID',
        type: 'text',
        column: 'externalPaymentId'
      }, {
        display: 'Is paid',
        i18nKey: 'common:textIsPaid',
        type: 'boolean',
        column: 'isPaid'
      }, {
        display: 'Payment type',
        i18nKey: 'MANAGE:textPaymentType',
        type: 'multi-list',
        column: 'paymentType',
        filterOptions: this.awardService.getPaymentTypeOptions(),
        format: 'label'
      }],
      type: AdHocReportingAPI.AdHocReportModelType.Payment,
      isRootObject: true,
      relatedObjects: [
        this.canAccessSpecialHandling ? 'payee' : null,
        !this.hideFundingSources ? 'assignedFundingSource' : null,
        !this.hideFundingSources ? 'fundingSource' : null,
        this.hasNominations ? 'relatedNominator' : null,
        'assignedBudget',
        'budget',
        'workflowLevel',
        'award',
        'organization',
        'applicant',
        'application',
        'program',
        'cycle'
      ].filter((obj) => !!obj) as RelatedObjectNames[]
    });
  }

  /* PAYMENT IN KIND */
  private _paymentInKind: AdHocReportingUI.RootObject;
  get paymentInKind (): AdHocReportingUI.RootObject {
    return this._paymentInKind || (this._paymentInKind = {
      supportsSummaryWidgets: false,
      omitRootObject: false,
      display: 'In kind items paid',
      i18nKey: 'common:hdrInKindItemsPaid',
      editEndpoint: 'InKindPayments',
      readOnlyEndpoint: 'InKindPayments',
      chartEndpoint: 'GetPaymentInKindItemChart',
      previewChartEndpoint: 'GetPaymentInKindItemChartPreview',
      property: 'inKindItem',
      columns: [
        ...(this.applicationInKind.columns || []).map((col) => {
          return {
            ...col
          };
        })
      ],
      type: AdHocReportingAPI.AdHocReportModelType.InKindPaymentItems,
      isRootObject: true,
      relatedObjects: [
        ...this.payment.relatedObjects.filter((obj) => {
          return obj !== 'assignedBudget' && obj !== 'assignedFundingSource';
        }),
        'payment'
      ] as RelatedObjectNames[]
    });
  }

  /* FORM */
  private _form: AdHocReportingUI.RelatedObject;
  get form (): AdHocReportingUI.RelatedObject {
    return this._form || (this._form = {
      display: 'Forms',
      i18nKey: 'common:textForms',
      property: 'form',
      columns: [{
        display: 'ID',
        i18nKey: 'common:textId',
        type: 'number',
        format: 'id',
        column: 'id',
        dashboardOnly: true
      }, {
        display: 'Application Form ID',
        i18nKey: 'common:textApplicationFormId',
        type: 'number',
        format: 'id',
        column: 'applicationFormId'
      }, {
        display: 'Status',
        i18nKey: 'common:hdrStatus',
        type: 'multi-list',
        format: 'label',
        column: 'status',
        filterOptions: this.statusService.formStatusOptions,
        supportsGrouping: true
      }, {
        display: 'Submitted date',
        i18nKey: 'GLOBAL:lblSubmittedDate',
        type: 'date',
        format: 'date',
        column: 'submittedDate'
      }, {
        display: 'Submitted date month',
        i18nKey: 'GLOBAL:lblSubmittedDateMonth',
        type: 'text',
        column: 'submittedDateMonth',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Submitted date year',
        i18nKey: 'GLOBAL:lblSubmittedDateYear',
        type: 'text',
        column: 'submittedDateYear',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Form version',
        i18nKey: 'GLOBAL:lblFormVersion',
        type: 'number',
        format: 'number',
        column : 'version'
      }, {
        display: 'Created by name',
        i18nKey: 'GLOBAL:lblCreatedByName',
        type: 'text',
        column : 'createdByName'
      }, {
        display: 'Created by email',
        i18nKey: 'GLOBAL:lblCreatedByEmail',
        type: 'text',
        column : 'createdByEmail'
      }, {
        display: 'Import ID',
        i18nKey: 'GLOBAL:lblImportID',
        type: 'text',
        column: 'importId'
      }, {
        display: 'Decision',
        i18nKey: 'GLOBAL:lblDecision',
        type: 'multi-list',
        filterOptions: this.statusService.decisionOptions,
        format: 'label',
        column: 'decision',
        supportsGrouping: true
      }, {
        display: 'Reviewer recommended funding amount',
        i18nKey: 'GLOBAL:textReviewerRecommendedFundingAmount',
        type: 'currency',
        column: 'reviewerRecommendedFundingAmount'
      }, {
        display: 'Due date',
        i18nKey: 'common:textDueDate',
        type: 'date',
        format: 'date',
        column: 'dueDate'
      }, {
        display: 'Is overdue',
        i18nKey: 'common:textIsOverdue',
        type: 'boolean',
        column: 'isOverDue'
      }, {
        display: 'Signed date',
        i18nKey: 'common:textSignedDate',
        type: 'date',
        format: 'date',
        column: 'signedDate'
      }, {
        display: 'Signed by name',
        i18nKey: 'common:textSignedByName',
        type: 'text',
        column: 'signedByName'
      }, {
        display: 'Signed by email',
        i18nKey: 'common:textSignedByEmail',
        type: 'text',
        column: 'signedByEmail'
      }, {
        display: 'Signed by IP address',
        i18nKey: 'common:textSignedIpAddress',
        type: 'text',
        column: 'signedByIpAddress'
      }, {
        display: 'Signed in workflow level name',
        i18nKey: 'common:textSignedInWflName',
        type: 'text',
        column: 'signedInWorkflowLevelName'
      }, {
        display: 'Signed in workflow level ID',
        i18nKey: 'common:textSignedInWflId',
        type: 'multi-list',
        filterOptions: this.workflowService.myWorkflowLevelOptions,
        format: 'value',
        column: 'signedInWorkflowLevelId'
      }],
      isRootObject: false
    });
  }

  /* ORGANIZATION */
  private _organization: AdHocReportingUI.RelatedObject;
  get organization (): AdHocReportingUI.RelatedObject {
    return this._organization || (this._organization = {
      display: 'Organizations',
      i18nKey: 'common:lblOrganizations',
      columns: [{
        display: 'YC Charity ID',
        i18nKey: 'nonprofit:lblYCCharityID',
        type: 'text',
        column: 'charityId'
      }, {
        display: 'Address 1',
        i18nKey: 'GLOBAL:ADDRESS_ONE',
        type: 'text',
        column: 'address1',
        canBeUsedAsReportField: true
      }, {
        display: 'Address 2',
        i18nKey: 'GLOBAL:ADDRESS_2',
        type: 'text',
        column: 'address2',
        canBeUsedAsReportField: true
      }, {
        display: 'City',
        i18nKey: 'GLOBAL:CITY',
        type: 'text',
        column: 'city',
        canBeUsedAsReportField: true
      }, {
        display: 'Registration ID',
        i18nKey: 'nonprofit:textRegistrationID',
        type: 'text',
        column: 'orgIdentification',
        supportsGrouping: true,
        noSubGrouping: true
      }, {
        display: 'Name',
        i18nKey: 'common:lblName',
        type: 'text',
        column: 'name',
        supportsGrouping: true,
        defaultI18nKey: 'GLOBAL:textIndividualIsBenefitingFromFunds',
        defaultDisplay: 'Individual is benefiting from funds for this program',
        canBeUsedAsReportField: true
      }, {
        display: 'Phone number',
        i18nKey: 'common:textPhoneNumber',
        type: 'text',
        column: 'phone',
        canBeUsedAsReportField: true
      }, {
        display: 'Postal code',
        i18nKey: 'GLOBAL:lblPostalCode',
        type: 'text',
        column: 'postalCode',
        canBeUsedAsReportField: true
      }, {
        display: 'State',
        i18nKey: 'GLOBAL:STATE',
        type: 'text',
        column: 'state',
        supportsGrouping: true,
        canBeUsedAsReportField: true
      }, {
        display: 'Country',
        i18nKey: 'GLOBAL:COUNTRY',
        type: 'text',
        column: 'country',
        supportsGrouping: true,
        canBeUsedAsReportField: true
      }, {
        display: 'Import ID',
        i18nKey: 'GLOBAL:lblImportID',
        type: 'text',
        column: 'importId'
      }, {
        display: 'Tags',
        i18nKey: 'GLOBAL:lblTags',
        type: 'multiValueList',
        column: 'tagList',
        filterOptions: this.systemTagsService.getTagOptions(
          SystemTags.Buckets.NonprofitProfile
        )
      }, {
        display: 'Vendor ID',
        i18nKey: 'GLOBAL:textVendorID',
        type: 'text',
        column: 'vendorId'
      }, {
        display: 'Classification',
        i18nKey: 'GLOBAL:textClassification',
        type: 'multi-list',
        filterOptions: this.classificationService.classificationOptions,
        format: 'label',
        column: 'orgClassification',
        supportsGrouping: true
      }, {
        display: 'Active',
        i18nKey: 'common:textActive',
        type: 'boolean',
        column: 'active',
        canBeUsedAsReportField: true
      }, {
        display: 'Is private organization',
        i18nKey: 'common:textIsPrivateOrganization',
        type: 'boolean',
        column: 'isPrivateOrg'
      }, {
        display: 'Equivalency determination vetted',
        i18nKey: 'nonprofit:textEquivalencyDeterminationVetted',
        type: 'boolean',
        column: 'isEdVetted'
      }, {
        display: 'Vetting status',
        i18nKey: 'nonprofit:textVettingStatus',
        type: 'multi-list',
        filterOptions: this.nonprofitService.getVettingStatusOptions(),
        format: 'label',
        column: 'vettingStatus'
      }, {
        display: 'NTEE',
        i18nKey: 'nonprofit:textNTEE',
        type: 'text',
        column: 'nteeCode',
        supportsGrouping: true
      },  {
        display: 'Foundation Type',
        i18nKey: 'nonprofit:textFoundationType',
        type: 'text',
        column: 'foundationType',
        supportsGrouping: true
      }, {
        display: 'Registration Authority',
        i18nKey: 'nonprofit:textRegistrationAuthority',
        type: 'text',
        column: 'registrationAuthority'
      }, {
        display: 'Subsection Code',
        i18nKey: 'nonprofit:textSubsectionCode',
        type: 'text',
        column: 'subsectionCode',
        supportsGrouping: true
      }, {
        display: 'YcCompliance',
        i18nKey: 'nonprofit:textYcCompliance',
        type: 'boolean',
        column: 'ycCompliance'
      }, {
        display: 'Blackbaud Giving Fund Eligible',
        i18nKey: 'nonprofit:textBlackbaudGivingFundEligible',
        type: 'multi-list',
        column: 'blackbaudEligibleForGivingStatus',
        format: 'label',
        filterOptions: this.eligibleForGivingStatusTypes
      }, {
        display: 'Company compliance',
        i18nKey: 'nonprofit:textCompanyCompliance',
        type: 'boolean',
        column: 'companyCompliance'
      }, {
        display: 'Sustainable Development Goals (SDGs)',
        i18nKey: 'nonprofit:textSustainableDevelopmentGoals',
        type: 'multiValueList',
        format: 'label',
        filterOptions: this.nonprofitService.getSdgOptions(),
        column: 'sdgList'
      }, {
        display: 'Total Awarded - year to date',
        i18nKey: 'nonprofit:textTotalAwardedYearToDate',
        type: 'currency',
        column: 'aggregatedTotalAwardedAmount',
        canBeUsedAsReportField: true
      }, {
        display: 'Total Requested - year to date',
        i18nKey: 'nonprofit:textTotalRequestedYearToDate',
        type: 'currency',
        column: 'aggregateTotalRequestedAmount',
        canBeUsedAsReportField: true
      }, {
        display: 'Total Requested per program for In Progress - Year to date',
        i18nKey: 'nonprofit:textTotalRequestedPerProgramForInProgressYTD',
        type: 'currency',
        column: 'aggregateTotalRequestedAmountInProgress',
        canBeUsedAsReportField: true
      }, {
        display: 'Total Requested per program for Awaiting Review - Year to date',
        i18nKey: 'nonprofit:textTotalRequestedPerProgramForAwaitingReviewYTD',
        type: 'currency',
        column: 'aggregateTotalRequestedAmountAwaitingReview',
        canBeUsedAsReportField: true
      }, {
        display: 'Total Requested per program for Approved - Year to date',
        i18nKey: 'nonprofit:textTotalRequestedPerProgramForApprovedYTD',
        type: 'currency',
        column: 'aggregateTotalRequestedAmountApproved',
        canBeUsedAsReportField: true
      }, {
        display: 'Total Requested per program for Hold - Year to date',
        i18nKey: 'nonprofit:textTotalRequestedPerProgramForHoldYTD',
        type: 'currency',
        column: 'aggregateTotalRequestedAmountHold',
        canBeUsedAsReportField: true
      }, {
        display: 'Total Requested per program for Cancelled - Year to date',
        i18nKey: 'nonprofit:textTotalRequestedPerProgramForCancelledYTD',
        type: 'currency',
        column: 'aggregateTotalRequestedAmountCancelled',
        canBeUsedAsReportField: true
      }, {
        display: 'Total Requested per program for Declined - Year to date',
        i18nKey: 'nonprofit:textTotalRequestedPerProgramForDeclinedYTD',
        type: 'currency',
        column: 'aggregateTotalRequestedAmountDeclined',
        canBeUsedAsReportField: true
      }, {
        display: 'Total Requested per program for Archived - Year to date',
        i18nKey: 'nonprofit:textTotalRequestedPerProgramForArchivedYTD',
        type: 'currency',
        column: 'aggregateTotalRequestedAmountIsArchived',
        canBeUsedAsReportField: true
      }, {
        display: 'Total Requested per program for Draft - Year to date',
        i18nKey: 'nonprofit:textTotalRequestedPerProgramForDraftYTD',
        type: 'currency',
        column: 'aggregateTotalRequestedAmountIsDraft',
        canBeUsedAsReportField: true
      }, {
        display: 'Total Awarded per program for Approved - Year to date',
        i18nKey: 'nonprofit:textTotalAwardedPerProgramForApprovedYTD',
        type: 'currency',
        column: 'aggregatedTotalAwardedAmountApproved',
        canBeUsedAsReportField: true
      },
      {
        display: 'Has ACH Disbursement Type',
        i18nKey: 'nonprofit:hasACHDisbursementType',
        type: 'boolean',
        column: 'hasACH'
      },
      {
        display: 'Total Recommended Funding for Approved but not Awarded per program - Year to date',
        i18nKey: 'nonprofit:textTotalAmountRecommendedFundingApprovedNotAwardedYTD',
        type: 'currency',
        column: 'aggregatedAmountRecommendedFundingApprovedNotAwarded',
        canBeUsedAsReportField: true
      }],
      property: 'organization',
      isRootObject: false
    });
  }

  /* Table */
  private _table: AdHocReportingUI.RootObject;
  get table (): AdHocReportingUI.RootObject {
    return this._table || (this._table = {
      supportsSummaryWidgets: false,
      omitRootObject: true,
      display: 'Table field responses',
      i18nKey: 'reporting:textTableFieldResponses',
      property: 'table',
      noFormLogic: true,
      editEndpoint: 'Table',
      chartEndpoint: null,
      previewChartEndpoint: null,
      readOnlyEndpoint: 'Table',
      columns: [],
      type: AdHocReportingAPI.AdHocReportModelType.Table,
      isRootObject: true,
      relatedObjects: [
        'application',
        'program',
        'applicant',
        'organization',
        this.hasNominations ? 'relatedNominator' : undefined
      ].filter((item) => !!item)
    });
  }
  /* APPLICANT */
  private _applicant: AdHocReportingUI.RelatedObject;
  get applicant (): AdHocReportingUI.RelatedObject {
    return this._applicant || (this._applicant = {
      display: this.hasNominations ?
        'Applicants / Nominators' :
        'Applicants',
      i18nKey: this.hasNominations ?
        'common:hdrApplicantsNominators' :
        'common:lblApplicants',
      columns: [{
        display: 'Address 1',
        i18nKey: 'GLOBAL:ADDRESS_ONE',
        type: 'text',
        column: 'address1'
      }, {
        display: 'Address 2',
        i18nKey: 'GLOBAL:ADDRESS_2',
        type: 'text',
        column: 'address2'
      }, {
        display: 'City',
        i18nKey: 'GLOBAL:CITY',
        type: 'text',
        column: 'city'
      }, {
        display: 'Email',
        i18nKey: 'common:textEmail',
        type: 'text',
        column: 'email'
      }, {
        display: 'Name',
        i18nKey: 'common:lblName',
        type: 'text',
        column: 'name',
        supportsGrouping: true,
        noSubGrouping: true,
        dashboardAliasDisplay: 'Applicant',
        dashboardAliasKey: 'common:lblApplicant'
      }, {
        display: 'Phone number',
        i18nKey: 'common:textPhoneNumber',
        type: 'text',
        column: 'phone'
      }, {
        display: 'Postal code',
        i18nKey: 'GLOBAL:lblPostalCode',
        type: 'text',
        column: 'postalCode'
      }, {
        display: 'State',
        i18nKey: 'GLOBAL:STATE',
        type: 'text',
        column: 'state',
        supportsGrouping: true
      }, {
        display: 'Country',
        i18nKey: 'GLOBAL:COUNTRY',
        type: 'text',
        column: 'country',
        supportsGrouping: true
      }, {
        display: 'ID',
        i18nKey: 'common:textId',
        type: 'number',
        format: 'id',
        column: 'id'
      }, {
        display: 'Import ID',
        i18nKey: 'GLOBAL:lblImportID',
        type: 'text',
        column: 'importId'
      }, {
        display: 'First name',
        i18nKey: 'common:textFirstName',
        type: 'text',
        column: 'firstName'
      }, {
        display: 'Last name',
        i18nKey: 'common:textLastName',
        type: 'text',
        column: 'lastName'
      }, {
        display: 'Vendor ID',
        i18nKey: 'GLOBAL:textVendorID',
        type: 'text',
        column: 'vendorId'
      }, {
        display: 'Opted out of GPC',
        i18nKey: 'GLOBAL:optedOutOfGPC',
        type: 'boolean',
        column: 'optedOutOfGPC'
      },
      {
        display: 'Language',
        i18nKey: 'GLOBAL:lblLanguage',
        type: 'text',
        column: 'languageCulture'
      },
      {
        display: 'Tags',
        i18nKey: 'GLOBAL:lblTags',
        type: 'multiValueList',
        column: 'tagList',
        filterOptions: this.systemTagsService.getTagOptions(
          SystemTags.Buckets.Applicant
        )
      },
      ...(this.employeeSSOFieldsService.get('applicantColumnsForReporting') || [])
        .map(col => {
          return {
            ...col
          };
        })
      ],
      property: 'applicant',
      isRootObject: false
    });
  }


  /* RELATED NOMINATOR */
  private _relatedNominator: AdHocReportingUI.RelatedObject;
  get relatedNominator (): AdHocReportingUI.RelatedObject {
    return this._relatedNominator || (this._relatedNominator = {
      display: 'Related Nominators',
      i18nKey: 'common:hdrRedlatedNominators',
      columns: [{
        display: 'Name',
        i18nKey: 'common:lblName',
        type: 'text',
        column: 'name',
        canBeUsedAsReportField: true
      },
      {
        display: 'Email',
        i18nKey: 'common:textEmail',
        type: 'text',
        column: 'email',
        canBeUsedAsReportField: true
      },
      ...(this.employeeSSOFieldsService.applicantColumnsForReporting || [])
        .map(col => {
          return {
            ...col,
            canBeUsedAsReportField: true
          };
        })
      ],
      property: 'relatedNominator',
      isRootObject: false
    });
  }

  /* PROGRAM */
  private _program: AdHocReportingUI.RelatedObject;
  get program (): AdHocReportingUI.RelatedObject {
    return this._program || (this._program = {
      display: 'Programs',
      i18nKey: 'GLOBAL:textPrograms',
      columns: [{
        display: 'ID',
        i18nKey: 'common:textId',
        type: 'multi-list',
        filterOptions: (this.programService.allManagerPrograms || [])
          .map(program => ({
            label: program.grantProgramName,
            value: program.grantProgramId
          })),
        format: 'value',
        column: 'id'
      }, {
        display: 'Is archived',
        i18nKey: 'GLOBAL:lblIsArchived',
        type: 'boolean',
        column: 'isArchived'
      }, {
        display: 'Name',
        i18nKey: 'common:lblName',
        type: 'text',
        column: 'name',
        supportsGrouping: true,
        dashboardAliasKey: 'common:lblProgram',
        dashboardAliasDisplay: 'Program',
        canBeUsedAsReportField: true
      }, {
        display: 'Program type',
        i18nKey: 'GLOBAL:textProgramType',
        type: 'multi-list',
        filterOptions: this.programService.programTypes,
        format: 'label',
        column: 'programType',
        canBeUsedAsReportField: true
      }],
      property: 'grantProgram',
      isRootObject: false
    });
  }

  /* WORKFLOW LEVEL */
  private _workflowLevel: AdHocReportingUI.RelatedObject;
  get workflowLevel (): AdHocReportingUI.RelatedObject {
    return this._workflowLevel || (this._workflowLevel = {
      display: 'Workflows',
      i18nKey: 'GLOBAL:textWorkflows',
      columns: [{
        display: 'Workflow name',
        i18nKey: 'common:textWorkflowName',
        type: 'text',
        column: 'workflowName',
        supportsGrouping: true,
        dashboardAliasDisplay: 'Workflow',
        dashboardAliasKey: 'common:lblWorkflow'
      }, {
        display: 'Workflow description',
        i18nKey: 'WORKFLOW:textWorkflowDescription',
        type: 'text',
        column: 'workflowDescription'
      }, {
        display: 'Workflow level name',
        i18nKey: 'WORKFLOW:textWorkflowLevelName',
        type: 'text',
        column: 'workflowLevelName',
        supportsGrouping: true,
        dashboardAliasDisplay: 'Workflow level',
        dashboardAliasKey: 'common:lblWorkflowLevel'
      }, {
        display: 'Workflow level description',
        i18nKey: 'WORKFLOW:textWorkflowLevelDescription',
        type: 'text',
        column: 'workflowLevelDescription'
      }, {
        display: 'Parent workflow level name',
        i18nKey: 'WORKFLOW:textParentWorkflowLevelName',
        type: 'text',
        column: 'parentWorkflowLevelName'
      }, {
        display: 'Parent workflow level description',
        i18nKey: 'WORKFLOW:textParentWorkflowLevelDescription',
        type: 'text',
        column: 'parentWorkflowLevelDescription'
      }, {
        display: 'Workflow ID',
        i18nKey: 'WORKFLOW:lblWorkflowID',
        type: 'multi-list',
        filterOptions: this.workflowService.myWorkflowOptions,
        format: 'value',
        column: 'workflowId'
      }, {
        display: 'Workflow level ID',
        i18nKey: 'WORKFLOW:lblWorkflowLevelID',
        type: 'multi-list',
        filterOptions: this.workflowService.myWorkflowLevelOptions,
        format: 'value',
        column: 'workflowLevelId'
      },
      {
        display: 'Initial Automated Workflow level ID',
        i18nKey: 'WORKFLOW:lblInitialAutomatedWorkflowLevelID',
        type: 'multi-list',
        filterOptions: this.workflowService.myWorkflowLevelOptions,
        format: 'value',
        column: 'initialAutomatedWorkflowLevelId'
      },
      {
        display: 'Initial Automated Workflow level name',
        i18nKey: 'WORKFLOW:lblInitialAutomatedWorkflowLevelName',
        type: 'text',
        column: 'initialAutomatedWorkflowLevelName'
      },
      {
        display: 'Initial Automated Workflow level description',
        i18nKey: 'WORKFLOW:lblInitialAutomatedWorkflowLevelDescription',
        type: 'text',
        column: 'initialAutomatedWorkflowLevelDescription'
      }],
      property: 'workflowLevel',
      isRootObject: false
    });
  }

  /* NOMINEE */
  private _nominee: AdHocReportingUI.RelatedObject;
  get nominee (): AdHocReportingUI.RelatedObject {
    return this._nominee || (this._nominee = {
      display: 'Nominees',
      i18nKey: 'GLOBAL:textNominees',
      columns: [{
        display: 'Email',
        i18nKey: 'common:textEmail',
        type: 'text',
        column: 'email'
      }, {
        display: 'Name',
        i18nKey: 'common:lblName',
        type: 'text',
        column: 'name'
      }, {
        display: 'Phone number',
        i18nKey: 'common:textPhoneNumber',
        type: 'text',
        column: 'phoneNumber'
      }, {
        display: 'Position',
        i18nKey: 'common:textPosition',
        type: 'text',
        column: 'position'
      }],
      property: 'nominee',
      isRootObject: false
    });
  }

  /* FORM DATA */
  formData: AdHocReportingUI.RelatedObject = {
    display: 'Form data',
    i18nKey: 'common:lblFormData',
    columns: [],
    property: 'formData',
    isRootObject: false
  };

  /* PAYEE */
  private _payee: AdHocReportingUI.RelatedObject;
  get payee (): AdHocReportingUI.RelatedObject {
    return this._payee || (this._payee = {
      display: 'Alternate Address Request',
      i18nKey: 'MANAGE:hdrAlternateAddressRequest',
      columns: [{
        display: 'Name',
        i18nKey: 'common:lblName',
        type: 'text',
        column: 'name'
      }, {
        display: 'Notes',
        i18nKey: 'GLOBAL:NOTES',
        type: 'text',
        column: 'notes'
      }, {
        display: 'Address 1',
        i18nKey: 'GLOBAL:ADDRESS_ONE',
        type: 'text',
        column: 'address1'
      }, {
        display: 'Address 2',
        i18nKey: 'GLOBAL:ADDRESS_2',
        type: 'text',
        column: 'address2'
      }, {
        display: 'City',
        i18nKey: 'GLOBAL:CITY',
        type: 'text',
        column: 'city'
      }, {
        display: 'State',
        i18nKey: 'GLOBAL:STATE',
        type: 'text',
        column: 'state'
      }, {
        display: 'Country',
        i18nKey: 'GLOBAL:COUNTRY',
        type: 'text',
        column: 'country'
      }, {
        display: 'Postal code',
        i18nKey: 'GLOBAL:lblPostalCode',
        type: 'text',
        column: 'postalCode'
      }, {
        display: 'Reason',
        i18nKey: 'common:textReason',
        type: 'text',
        column: 'alternateAddressReason'
      }, {
        display: 'Supporting document',
        i18nKey: 'common:textSupportingDocument',
        type: 'text',
        column: 'fileUpload',
        format: 'file'
      }, {
        display: 'Created by',
        i18nKey: 'common:hdrCreatedBy',
        type: 'text',
        column: 'createdBy'
      }, {
        display: 'Updated by',
        i18nKey: 'GLOBAL:lblUpdatedBy',
        type: 'text',
        column: 'updatedBy'
      }, {
        display: 'Create date',
        i18nKey: 'GLOBAL:lblCreatedDate',
        type: 'date',
        format: 'date',
        column: 'createDate'
      }, {
        display: 'Update date',
        i18nKey: 'GLOBAL:lblUpdatedDate',
        type: 'date',
        format: 'date',
        column: 'updatedDate'
      }, {
        display: 'Alternate address request status',
        i18nKey: 'common:textAlternateAddressRequestStatus',
        type: 'multi-list',
        format: 'label',
        column: 'addressRequestStatus',
        filterOptions: this.addressRequestService.getAddressRequestStatusOptions()
      }],
      property: 'payeeOverride',
      isRootObject: false
    });
  }

  /* FUNDING SOURCE */
  private _fundingSource: AdHocReportingUI.RelatedObject;
  get fundingSource (): AdHocReportingUI.RelatedObject {
    return this._fundingSource || (this._fundingSource = {
      display: 'Funding Sources',
      i18nKey: 'GLOBAL:textFundingSources',
      columns: [{
        display: 'ID',
        i18nKey: 'common:textId',
        type: 'multi-list',
        filterOptions: this.budgetService.allSourceOptions,
        format: 'value',
        column: 'id'
      }, {
        display: 'Name',
        i18nKey: 'common:lblName',
        type: 'text',
        column: 'name',
        supportsGrouping: true,
        dashboardAliasKey: 'GLOBAL:hdrFundingSource',
        dashboardAliasDisplay: 'Funding source'
      }, {
        display: 'Total amount',
        i18nKey: 'common:textTotalAmount',
        type: 'currency',
        column: 'totalAmount',
        supportsMinMaxSum: true
      }, {
        display: 'Total units',
        i18nKey: 'BUDGET:textTotalUnits',
        type: 'number',
        format: 'number',
        supportsMinMaxSum: true,
        column: 'totalUnits'
      }, {
        display: 'Unit cost',
        i18nKey: 'BUDGET:textUnitCost',
        type: 'currency',
        column: 'unitCost'
      }, {
        display: 'Unit value',
        i18nKey: 'BUDGET:textUnitValue',
        type: 'currency',
        column: 'unitValue'
      }, {
        display: 'Payment Processor',
        i18nKey: 'BUDGET:textPaymentProcessor',
        type: 'multi-list',
        format: 'label',
        filterOptions: this.processorTypes,
        column: 'processingType'
      }],
      property: 'fundingSource',
      isRootObject: false
    });
  }

  /* ASSIGNED FUNDING SOURCE */
  private _assignedFundingSource: AdHocReportingUI.RelatedObject;
  get assignedFundingSource (): AdHocReportingUI.RelatedObject {
    return this._assignedFundingSource || (this._assignedFundingSource = {
      display: 'Assigned funding source',
      i18nKey: 'GLOBAL:textAssignedFundingSource',
      columns: [{
        display: 'ID',
        i18nKey: 'common:textId',
        type: 'multi-list',
        filterOptions: this.budgetService.allSourceOptions,
        format: 'value',
        column: 'id'
      }, {
        display: 'Name',
        i18nKey: 'common:lblName',
        type: 'text',
        column: 'name',
        supportsGrouping: true
      }, {
        display: 'Funding source type',
        i18nKey: 'GLOBAL:textFundingSourceType',
        type: 'multi-list',
        format: 'label',
        filterOptions: this.awardService.getAwardTypeOptions(),
        column: 'fundingSourceType'
      }, {
        display: 'Payment Processor',
        i18nKey: 'BUDGET:textPaymentProcessor',
        type: 'multi-list',
        format: 'label',
        filterOptions: this.processorTypes,
        column: 'processingType'
      }],
      property: 'assignedFundingSource',
      isRootObject: false
    });
  }

  /* BUDGET */
  private _budget: AdHocReportingUI.RelatedObject;
  get budget (): AdHocReportingUI.RelatedObject {
    return this._budget || (this._budget = {
      display: 'Budgets',
      i18nKey: 'GLOBAL:textBudgets',
      columns: [{
        display: 'ID',
        i18nKey: 'common:textId',
        type: 'multi-list',
        filterOptions: this.budgetService.allBudgetOptions,
        format: 'value',
        column: 'id'
      }, {
        display: 'Name',
        i18nKey: 'common:lblName',
        type: 'text',
        column: 'name',
        supportsGrouping: true,
        dashboardAliasDisplay: 'Budget',
        dashboardAliasKey: 'common:lblBudget'
      }, {
        display: 'Description',
        i18nKey: 'common:textDescription',
        type: 'text',
        column: 'description'
      }, {
        display: 'Tags',
        i18nKey: 'GLOBAL:lblTags',
        type: 'multiValueList',
        column: 'tagList',
        filterOptions: this.systemTagsService.getTagOptions(
          SystemTags.Buckets.Budget
        )
      }, {
        display: 'Account number',
        i18nKey: 'GLOBAL:textAccountNumber',
        type: 'text',
        column: 'accountNumber'
      }, {
        display: 'Payment Processor',
        i18nKey: 'BUDGET:textPaymentProcessor',
        type: 'multi-list',
        format: 'label',
        filterOptions: this.processorTypes,
        column: 'processingType',
        customParentBucket: 'fundingSource'
      }],
      property: 'budget',
      isRootObject: false
    });
  }

  /* ASSIGNED BUDGET */
  private _assignedBudget: AdHocReportingUI.RelatedObject;
  get assignedBudget (): AdHocReportingUI.RelatedObject {
    return this._assignedBudget || (this._assignedBudget = {
      display: 'Assigned budget',
      i18nKey: 'GLOBAL:textAssignedBudget',
      columns: [{
        display: 'ID',
        i18nKey: 'common:textId',
        type: 'multi-list',
        filterOptions: this.budgetService.allBudgetOptions,
        format: 'value',
        column: 'id'
      }, {
        display: 'Name',
        i18nKey: 'common:lblName',
        type: 'text',
        column: 'name',
        supportsGrouping: true
      }, {
        display: 'Tags',
        i18nKey: 'GLOBAL:lblTags',
        type: 'multiValueList',
        column: 'tagList',
        filterOptions: this.systemTagsService.getTagOptions(
          SystemTags.Buckets.Budget
        )
      }, {
        display: 'Description',
        i18nKey: 'common:textDescription',
        type: 'text',
        column: 'description'
      }, {
        display: 'Account number',
        i18nKey: 'GLOBAL:textAccountNumber',
        type: 'text',
        column: 'accountNumber'
      }, !this.hideFundingSources ? null : {
        display: 'Budget type',
        i18nKey: 'common:textBudgetType',
        type: 'multi-list',
        format: 'label',
        filterOptions: this.awardService.getAwardTypeOptions(),
        column: 'fundingSourceType',
        customParentBucket: 'assignedFundingSource'
      }, !this.hideFundingSources ? null : {
        display: 'Payment Processor',
        i18nKey: 'BUDGET:textPaymentProcessor',
        type: 'multi-list',
        format: 'label',
        filterOptions: this.processorTypes,
        column: 'processingType',
        customParentBucket: 'assignedFundingSource'
      }].filter((column) => !!column).map((column) => {
        return column as AdHocReportingUI.ColumnDefinition;
      }),
      property: 'assignedBudget',
      isRootObject: false
    });
  }

  /* BUDGET ROOT OBJECT */
  private _budgets: AdHocReportingUI.RootObject;
  get budgets (): AdHocReportingUI.RootObject {
    return this._budgets || (this._budgets = {
      supportsSummaryWidgets: true,
      omitRootObject: false,
      display: 'Budget',
      i18nKey: 'common:lblBudget',
      editEndpoint: 'Budgets',
      readOnlyEndpoint: 'Budgets',
      chartEndpoint: '',
      recordOptions: this.budgetService.allBudgetOptions,
      previewChartEndpoint: 'Budgets',
      property: 'budget',
      noFormLogic: true,
      columns: [{
        display: 'Reserved amount',
        i18nKey: 'GLOBAL:textReservedAmount',
        type: 'currency',
        column: 'reservedAmount'
      }, {
        display: 'Pending payments total',
        i18nKey: 'GLOBAL:textPendingPaymentsTotal',
        type: 'currency',
        column: 'pendingPaymentsTotal',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Scheduled payments total',
        i18nKey: 'GLOBAL:textScheduledPaymentsTotal',
        type: 'currency',
        column: 'scheduledPaymentsTotal',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Processing payments total',
        i18nKey: 'GLOBAL:textProcessingPaymentsTotal',
        type: 'currency',
        column: 'processingPaymentsTotal',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Outstanding payments total',
        i18nKey: 'GLOBAL:textOutstandingPaymentsTotal',
        type: 'currency',
        column: 'outstandingPaymentsTotal',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Cleared payments total',
        i18nKey: 'GLOBAL:textClearedPaymentsTotal',
        type: 'currency',
        column: 'clearedPaymentsTotal',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Fulfilled payments total',
        i18nKey: 'GLOBAL:textFulfilledPaymentsTotal',
        type: 'currency',
        column: 'fulfilledPaymentsTotal',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Processing hold payments total',
        i18nKey: 'GLOBAL:textProcessingHoldPaymentsTotal',
        type: 'currency',
        column: 'processingHoldPaymentsTotal',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Total spent percentage',
        i18nKey: 'GLOBAL:textTotalSpentPercentage',
        type: 'number',
        format: 'percent',
        column: 'totalSpentPercentage',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Total remaining percentage',
        i18nKey: 'GLOBAL:textTotalRemainingPercentage',
        type: 'number',
        format: 'percent',
        column: 'totalRemainingPercentage',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Total unavailable percentage',
        i18nKey: 'GLOBAL:textTotalUnavailablePercentage',
        type: 'number',
        format: 'percent',
        column: 'totalUnavaliablePercentage',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Total reserved percentage',
        i18nKey: 'GLOBAL:textTotalReservedPercentage',
        type: 'number',
        format: 'percent',
        column: 'totalReservedPercentage',
        canBeUsedForRecordSummary: true
      }, {
        display: 'ID',
        i18nKey: 'common:textId',
        type: 'multi-list',
        filterOptions: this.budgetService.allBudgetOptions,
        format: 'value',
        column: 'id'
      }, {
        display: 'Name',
        i18nKey: 'common:lblName',
        type: 'text',
        column: 'name'
      }, {
        display: 'Description',
        i18nKey: 'common:textDescription',
        type: 'text',
        column: 'description'
      }, {
        display: 'Account number',
        i18nKey: 'GLOBAL:textAccountNumber',
        type: 'text',
        column: 'accountNumber'
      }, {
        display: 'Number of programs',
        i18nKey: 'GLOBAL:textNumberOfPrograms',
        type: 'number',
        format: 'number',
        column: 'numberOfPrograms'
      }, this.hideFundingSources ? null : {
        display: 'Number of funding sources',
        i18nKey: 'GLOBAL:textNumberOfFundingSources',
        type: 'number',
        format: 'number',
        column: 'numberOfFundingSources'
      }, {
        display: 'Number of payments',
        i18nKey: 'GLOBAL:textNumberOfPayments',
        type: 'number',
        format: 'number',
        column: 'numberOfPayments'
      }, {
        display: 'Payments total',
        i18nKey: 'GLOBAL:textPaymentsTotal',
        type: 'currency',
        column: 'paymentsTotal',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Remaining amount',
        i18nKey: 'GLOBAL:textRemainingAmount',
        type: 'currency',
        column: 'remainingAmount',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Unavailable amount',
        i18nKey: 'GLOBAL:textUnavailableAmount',
        type: 'currency',
        column: 'unavailableAmount',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Tags',
        i18nKey: 'GLOBAL:lblTags',
        type: 'multiValueList',
        column: 'tagList',
        filterOptions: this.systemTagsService.getTagOptions(
          SystemTags.Buckets.Budget
        )
      }, {
        display: 'Allocated amount',
        i18nKey: 'GLOBAL:textAllocatedAmount',
        type: 'currency',
        column: 'allocatedAmount',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Is closed',
        i18nKey: 'GLOBAL:textIsClosed',
        type: 'boolean',
        column: 'isClosed'
      }, !this.hideFundingSources ? null :
      {
        display: 'Budget type',
        i18nKey: 'common:textBudgetType',
        type: 'multi-list',
        column: 'fundingSourceType',
        format: 'label',
        filterOptions: [{
          label: this.i18n.translate(
            'GLOBAL:textCash',
            {},
            'Cash'
          ),
          value: FundingSourceTypes.DOLLARS
        }, {
          label: this.i18n.translate(
            'GLOBAL:textInKind',
            {},
            'In-Kind'
          ),
          value: FundingSourceTypes.UNITS
        }]
      }, !this.hideFundingSources ? null :
      {
        display: 'Processing type',
        i18nKey: 'GLOBAL:textProcessingType',
        type: 'multi-list',
        column: 'processingType',
        format: 'label',
        filterOptions: [{
          label: this.i18n.translate(
            'common:labelClient',
            {},
            'Client'
          ),
          value: ProcessingTypes.Client
        }, {
          label: 'YourCause',
          value: ProcessingTypes.YourCause
        }]
      }, !this.hideFundingSources ? null :
      {
        display: 'Total amount',
        i18nKey: 'GLOBAL:textTotalAmount',
        type: 'currency',
        column: 'totalAmount',
        canBeUsedForRecordSummary: true
      }].filter((column) => !!column).map((column) => {
        return column as AdHocReportingUI.ColumnDefinition;
      }),
      type: AdHocReportingAPI.AdHocReportModelType.Budgets,
      isRootObject: true,
      relatedObjects: [] as RelatedObjectNames[]
    });
  }

  /* FUNDING SOURCES ROOT OBJECT */
  private _fundingSources: AdHocReportingUI.RootObject;
  get fundingSources (): AdHocReportingUI.RootObject {
    return this._fundingSources || (this._fundingSources = {
      supportsSummaryWidgets: true,
      omitRootObject: false,
      display: 'Funding Source',
      i18nKey: 'BUDGET:lblFundingSource',
      editEndpoint: 'FundingSources',
      readOnlyEndpoint: 'FundingSources',
      recordOptions: this.budgetService.allSourceOptions,
      chartEndpoint: '',
      previewChartEndpoint: '',
      property: 'fundingSource',
      noFormLogic: true,
      columns: [{
        display: 'Reserved amount',
        i18nKey: 'GLOBAL:textReservedAmount',
        type: 'currency',
        column: 'reservedAmount',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Pending payments total',
        i18nKey: 'GLOBAL:textPendingPaymentsTotal',
        type: 'currency',
        column: 'pendingPaymentsTotal',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Scheduled payments total',
        i18nKey: 'GLOBAL:textScheduledPaymentsTotal',
        type: 'currency',
        column: 'scheduledPaymentsTotal',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Processing payments total',
        i18nKey: 'GLOBAL:textProcessingPaymentsTotal',
        type: 'currency',
        column: 'processingPaymentsTotal',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Outstanding payments total',
        i18nKey: 'GLOBAL:textOutstandingPaymentsTotal',
        type: 'currency',
        column: 'outstandingPaymentsTotal',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Cleared payments total',
        i18nKey: 'GLOBAL:textClearedPaymentsTotal',
        type: 'currency',
        column: 'clearedPaymentsTotal',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Fulfilled payments total',
        i18nKey: 'GLOBAL:textFulfilledPaymentsTotal',
        type: 'currency',
        column: 'fulfilledPaymentsTotal',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Processing hold payments total',
        i18nKey: 'GLOBAL:textProcessingHoldPaymentsTotal',
        type: 'currency',
        column: 'processingHoldPaymentsTotal',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Total spent percentage',
        i18nKey: 'GLOBAL:textTotalSpentPercentage',
        type: 'number',
        format: 'percent',
        column: 'totalSpentPercentage',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Total remaining percentage',
        i18nKey: 'GLOBAL:textTotalRemainingPercentage',
        type: 'number',
        format: 'percent',
        column: 'totalRemainingPercentage',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Total unavailable percentage',
        i18nKey: 'GLOBAL:textTotalUnavailablePercentage',
        type: 'number',
        format: 'percent',
        column: 'totalUnavaliablePercentage',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Total reserved percentage',
        i18nKey: 'GLOBAL:textTotalReservedPercentage',
        type: 'number',
        format: 'percent',
        column: 'totalReservedPercentage'
      }, {
        display: 'ID',
        i18nKey: 'common:textId',
        type: 'multi-list',
        filterOptions: this.budgetService.allSourceOptions,
        format: 'value',
        column: 'id'
      }, {
        display: 'Name',
        i18nKey: 'common:lblName',
        type: 'text',
        column: 'name'
      }, {
        display: 'Funding source type',
        i18nKey: 'GLOBAL:textFundingSourceType',
        type: 'multi-list',
        column: 'fundingSourceType',
        format: 'label',
        filterOptions: [{
          label: this.i18n.translate(
            'GLOBAL:textCash',
            {},
            'Cash'
          ),
          value: FundingSourceTypes.DOLLARS
        }, {
          label: this.i18n.translate(
            'GLOBAL:textInKind',
            {},
            'In-Kind'
          ),
          value: FundingSourceTypes.UNITS
        }]
      }, {
        display: 'Total amount',
        i18nKey: 'GLOBAL:textTotalAmount',
        type: 'currency',
        column: 'totalAmount',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Processing type',
        i18nKey: 'GLOBAL:textProcessingType',
        type: 'multi-list',
        column: 'processingType',
        format: 'label',
        filterOptions: [{
          label: this.i18n.translate(
            'common:labelClient',
            {},
            'Client'
          ),
          value: ProcessingTypes.Client
        }, {
          label: 'YourCause',
          value: ProcessingTypes.YourCause
        }]
      }, {
        display: 'Allocated amount',
        i18nKey: 'GLOBAL:textAllocatedAmount',
        type: 'currency',
        column: 'allocatedAmount',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Number of programs',
        i18nKey: 'GLOBAL:textNumberOfPrograms',
        type: 'number',
        format: 'number',
        column: 'numberOfPrograms'
      }, {
        display: 'Number of budgets',
        i18nKey: 'GLOBAL:textNumberOfBudgets',
        type: 'number',
        format: 'number',
        column: 'numberOfBudgets'
      }, {
        display: 'Number of payments',
        i18nKey: 'GLOBAL:textNumberOfPayments',
        type: 'number',
        format: 'number',
        column: 'numberOfPayments'
      }, {
        display: 'Payments total',
        i18nKey: 'GLOBAL:textPaymentsTotal',
        type: 'currency',
        column: 'paymentsTotal',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Remaining amount',
        i18nKey: 'GLOBAL:textRemainingAmount',
        type: 'currency',
        column: 'remainingAmount',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Unavailable amount',
        i18nKey: 'GLOBAL:textUnavailableAmount',
        type: 'currency',
        column: 'unavailableAmount',
        canBeUsedForRecordSummary: true
      }, {
        display: 'Is closed',
        i18nKey: 'GLOBAL:textIsClosed',
        type: 'boolean',
        column: 'isClosed'
      }],
      type: AdHocReportingAPI.AdHocReportModelType.FundingSources,
      isRootObject: true,
      relatedObjects: [] as RelatedObjectNames[]
    });
  }


  /* Cycles */
  private _cycle: AdHocReportingUI.RelatedObject;
  get cycle (): AdHocReportingUI.RelatedObject {
    return this._cycle || (this._cycle = {
      display: 'Cycles',
      i18nKey: 'PROGRAM:textCycles',
      columns: [{
        display: 'Is archived',
        i18nKey: 'GLOBAL:lblIsArchived',
        type: 'boolean',
        column: 'isArchived'
      }, {
        display: 'Name',
        i18nKey: 'common:lblName',
        type: 'text',
        column: 'name',
        supportsGrouping: true,
        dashboardAliasKey: 'common:lblCycle',
        dashboardAliasDisplay: 'Cycle'
      }, {
        display: 'Start date',
        i18nKey: 'GLOBAL:textStartDate',
        type: 'date',
        format: 'date',
        column: 'startDate',
        canBeUsedAsReportField: true
      }, {
        display: 'Start date month',
        i18nKey: 'GLOBAL:lblStartDateMonth',
        type: 'text',
        column: 'startDateMonth',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'Start date year',
        i18nKey: 'GLOBAL:lblStartDateYear',
        type: 'text',
        column: 'startDateYear',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'End date',
        i18nKey: 'GLOBAL:textEndDate',
        type: 'date',
        format: 'date',
        column: 'endDate',
        canBeUsedAsReportField: true
      }, {
        display: 'End date month',
        i18nKey: 'GLOBAL:lblEndDateMonth',
        type: 'text',
        column: 'endDateMonth',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'End date year',
        i18nKey: 'GLOBAL:lblEndDateYear',
        type: 'text',
        column: 'endDateYear',
        supportsGrouping: true,
        dashboardOnly: true
      }, {
        display: 'ID',
        i18nKey: 'common:textId',
        type: 'multi-list',
        filterOptions: (this.cycleService.allMyCycleSelectOptions || [])
          .map((cycle) => {
            return {
              label: cycle.label,
              value: cycle.value
            };
          }),
        format: 'value',
        column: 'id'
      }],
      property: 'cycle',
      isRootObject: false
    });
  }

  /* CLIENT */
  private _client: AdHocReportingUI.RelatedObject;
  get client (): AdHocReportingUI.RelatedObject {
    return this._client || (this._client = {
      display: 'Client',
      i18nKey: 'common:labelClient',
      tokenInsertOnly: true,
      columns: [{
        display: 'Name',
        i18nKey: 'common:lblName',
        type: 'text',
        column: 'name'
      }, {
        display: 'Address 1',
        i18nKey: 'GLOBAL:ADDRESS_ONE',
        type: 'text',
        column: 'address1'
      }, {
        display: 'Address 2',
        i18nKey: 'GLOBAL:ADDRESS_2',
        type: 'text',
        column: 'address2'
      }, {
        display: 'City',
        i18nKey: 'GLOBAL:CITY',
        type: 'text',
        column: 'city'
      }, {
        display: 'State',
        i18nKey: 'GLOBAL:STATE',
        type: 'text',
        column: 'state'
      },  {
        display: 'Postal code',
        i18nKey: 'GLOBAL:lblPostalCode',
        type: 'text',
        column: 'postalCode'
      }, {
        display: 'Country',
        i18nKey: 'GLOBAL:COUNTRY',
        type: 'text',
        column: 'country'
      }, {
        display: 'Client logo URL',
        i18nKey: 'GLOBAL:textClientLogoURL',
        type: 'text',
        column: 'clientLogo'
      }],
      property: 'client',
      isRootObject: false
    });
  }
  /* Today's Date */
  private _todaysDate: AdHocReportingUI.RootObject;
  get todaysDate (): AdHocReportingUI.RootObject {
    return this._todaysDate || (this._todaysDate = {
      supportsSummaryWidgets: false,
      omitRootObject: false,
      relatedObjects: [],
      editEndpoint: '',
      readOnlyEndpoint: '',
      chartEndpoint: '',
      previewChartEndpoint: '',
      display: 'Today\'s Date',
      i18nKey: 'common:lblTodaysDate',
      tokenInsertOnly: true,
      columns: [{
        display: 'Today\'s Date (Day, Month, Year)',
        i18nKey: 'common:lblTodaysDateDayMonthYear',
        type: 'text',
        column: 'TodaysDateDayMonthYear'
      }, {
        display: 'Today\'s Date (Month, Day, Year)',
        i18nKey: 'common:lblTodaysDateMonthDayYear',
        type: 'text',
        column: 'TodaysDateMonthDayYear'
      }, {
        display: 'Today\'s Date (MM_DD_YYYY)',
        i18nKey: 'common:lblTodaysDateMM_DD_YYYY',
        type: 'text',
        column: 'TodaysDateMM_DD_YYYY'
      }, {
        display: 'Today\'s Date (YYYY_MM_DD)',
        i18nKey: 'common:lblTodaysDateYYYY_MM_DD',
        type: 'text',
        column: 'TodaysDateYYYY_MM_DD'
      }],
      property: 'todaysDate',
      isRootObject: false,
      type: AdHocReportingAPI.AdHocReportModelType.TodaysDate
    });
  }

  reset () {
    Object.keys(this).forEach(key => {
      if (key.startsWith('_')) {
        (this as any)[key as any] = null;
      }
    });
  }
}
