import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { TranslationService } from '@core/services/translation.service';
import { FileUploadForPDF } from '@core/typings/pdf.typing';
import { ApplicationFileService } from '@features/application-file/services/application-file.service';
import { CommunicationsService } from '@features/communications/communications.service';
import { CommunicationVisibility } from '@features/communications/communications.typing';
import { DocumentTemplateService } from '@features/document-templates/document-template.service';
import { DistributeOptions } from '@features/document-templates/document-template.typing';
import { FormHelperService } from '@features/forms/services/form-helper/form-helper.service';
import { SimpleStringMap } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { DateService } from '@yourcause/common/date';
import { FileService, FileTypeService } from '@yourcause/common/files';
import { I18nService } from '@yourcause/common/i18n';
import { ConfirmAndTakeActionService } from '@yourcause/common/modals';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import printJS from 'print-js';
import { ApplicationAttachmentResources } from './application-attachment.resources';
import { ApplicationAttachmentState } from './application-attachments.state';
import { ApplicationAttachmentForUI, ApplicationAttachmentFromApi, ApplicationAttachmentRecord, AttachmentType, CommFileInfo, EmailFileInfo, ExternalFileModalResponse, ExternalOrMergeFileInfo, FormFileInfo, MailMergeModalResponse, MergeDocumenteModalResponse, OpenCommunicationFileParams } from './application-attachments.typing';

@AttachYCState(ApplicationAttachmentState)
@Injectable({ providedIn: 'root' })
export class ApplicationAttachmentService extends BaseYCService<ApplicationAttachmentState> {

   constructor (
    private applicationAttachmentResources: ApplicationAttachmentResources,
    private translationService: TranslationService,
    private i18n: I18nService,
    private notifier: NotifierService,
    private documentTemplateService: DocumentTemplateService,
    private fileService: FileService,
    private applicationFileService: ApplicationFileService,
    private communicationsService: CommunicationsService,
    private formHelperService: FormHelperService,
    private dateService: DateService,
    private fileTypeService: FileTypeService,
    private confirmAndTakeAction: ConfirmAndTakeActionService,
    private portal: PortalDeterminationService
  ) {
    super();
  }

  getAttachmentTypeMap (): SimpleStringMap<string> {
    return {
      [AttachmentType.EMAIL]: this.i18n.translate(
        'GLOBAL:textEmailAttachment',
        {},
        'Email attachment'
      ),
      [AttachmentType.EXTERNAL]: this.i18n.translate(
        'GLOBAL:textExternalFile',
        {},
        'External file'
      ),
      [AttachmentType.FORM]: this.i18n.translate(
        'GLOBAL:textFormAttachment',
        {},
        'Form attachment'
      ),
      [AttachmentType.MERGE]: this.i18n.translate(
        'GLOBAL:textMergeDocument',
        {},
        'Merge document'
      ),
      [AttachmentType.COMMUNICATION]: this.i18n.translate(
        'GLOBAL:textCommunication',
        {},
        'Communication'
      )
    };
  }

  getDocumentVisibilityOptions (
    isNomination = false
  ): TypeaheadSelectOption[] {
    return [ {
      label: this.i18n.translate(
        isNomination ?
          'GLOBAL:textAllGrantManagerOnNomCanViewDoc' :
          'GLOBAL:textAllGrantManagerOnAppCanViewDoc',
        {},
        isNomination ?
          'All grant managers on the nomination can view the document' :
          'All grant managers on the application can view the document'
      ),
      value: CommunicationVisibility.ALL_GRANT_MANAGERS
    }, {
      label: this.i18n.translate(
        'GLOBAL:textOnlyGrantManagerInWFLCanViewDoc',
        {},
        'Only grant manager in the current workflow level can view the document'
      ),
      value: CommunicationVisibility.MANAGERS_IN_LEVEL
    }, {
      label: this.i18n.translate(
        'GLOBAL:textOnlyICanViewTheDocument',
        {},
        'Only I can view the document'
      ),
      value: CommunicationVisibility.ONLY_ME
    } ];
  }

  async getApplicationAttachments (
    applicationId: number
  ): Promise<ApplicationAttachmentForUI[]> {
    const response = await this.applicationAttachmentResources.getApplicationAttachments(
      applicationId
    );

    return this.adaptApplicationAttachmentsForUI(response);
  }

  filterFileInfoToUniq (
    fileInfo: (CommFileInfo|FormFileInfo)[],
    fileUploadIds: number[]
  ) {
    return fileInfo.filter((file) => {
      if (!!file.fileUploadId) {
        if (!fileUploadIds.includes(file.fileUploadId)) {
          fileUploadIds.push(file.fileUploadId);

          return true;
        }
      }

      return false;
    });
  }

  adaptApplicationAttachmentsForUI (response: ApplicationAttachmentFromApi) {
    const fileUploadIds: number[] = [];
    response.formFileInfo = response.formFileInfo.map((info) => {
      return {
        ...info,
        fileInfo: this.filterFileInfoToUniq(info.fileInfo, fileUploadIds),
        multiValueFileInfo: this.filterFileInfoToUniq(
          info.multiValueFileInfo,
          fileUploadIds
        ),
        disableDownload: info.disableDownload
      };
    });
    const attachments: ApplicationAttachmentRecord[] = [
      ...response.communicationFileInfo,
      ...response.emailFileInfo,
      ...response.formFileInfo.reduce((acc, formFileInfo) => {
        if (formFileInfo.multiValueFileInfo.length === 0) {
          return [
            ...acc,
            formFileInfo
          ];
        } else {
          return [
            ...acc,
            {
              ...formFileInfo,
              fileInfo: [
                ...formFileInfo.fileInfo,
                ...formFileInfo.multiValueFileInfo
              ]
            }
          ];
        }
      }, []),
      ...response.externalFileInfo,
      ...response.mergeDocumentFileInfo
    ];
    const viewTranslations = this.translationService.viewTranslations;
    const formTranslationMap = viewTranslations.FormTranslation;

    return attachments.map<ApplicationAttachmentForUI>((attachment) => {
      const {
        statusDate,
        statusText,
        statusTooltip
      } = this.getStatusInfoForAttachment(attachment);

      let formName: string;
      let formId: number;
      let applicantCanView = false;
      let canRemoveAttachment = false;
      let disableDownload = false;
      const attachmentType = attachment.attachmentType;
      switch (attachmentType) {
        case AttachmentType.EXTERNAL:
        case AttachmentType.MERGE:
          applicantCanView = (attachment as ExternalOrMergeFileInfo).applicantCanView;
          canRemoveAttachment = (attachment as ExternalOrMergeFileInfo).canRemoveAttachment;
          if (attachmentType === AttachmentType.MERGE) {
            const templateName = (attachment as ExternalOrMergeFileInfo).documentTemplateName;
            attachment.fileInfo.forEach((file) => {
              file.fileName = templateName ?
              `${templateName}.pdf` :
              file.fileName;
            });
          }
          break;
        case AttachmentType.FORM:
          formId = (attachment as FormFileInfo).formId;
          const map = formTranslationMap[formId];
          formName = map?.Name ?? (attachment as FormFileInfo).formName;
          disableDownload = (attachment as FormFileInfo).disableDownload;
          break;
      }

      return {
        attachmentId: attachment.attachmentId,
        attachmentType: attachment.attachmentType,
        emailNotificationType: 'emailNotificationType' in attachment ?
          attachment.emailNotificationType :
          null,
        emailSentDate: 'emailSentDate' in attachment ?
          attachment.emailSentDate :
          null,
        formId,
        formName,
        documentVisibility: 'documentVisibility' in attachment ?
          attachment.documentVisibility :
          null,
        applicantCanView,
        uploadedDate: 'uploadedDate' in attachment ?
          attachment.uploadedDate :
          null,
        uploadedBy: 'uploadedBy' in attachment ?
          attachment.uploadedBy :
          null,
        canRemoveAttachment,
        statusDate,
        statusText,
        statusTooltip,
        disableDownload,
        fileInfo: attachment.fileInfo
      };
    });
  }

  getStatusInfoForAttachment (
    attachment: ApplicationAttachmentRecord
  ) {
    let statusDate = '';
    let statusText = '';
    let statusTooltip = '';
    const uploadedBy = attachment.fileInfo[0]?.uploadedBy;
    switch (attachment.attachmentType) {
      case AttachmentType.EMAIL:
        statusDate = (attachment as EmailFileInfo).emailSentDate;
        statusText = this.i18n.translate(
          'GLOBAL:textEmailSentOnDate',
          {
            date: this.dateService.formatDate(statusDate)
          },
          'Email sent on __date__'
        );
        break;
      case AttachmentType.EXTERNAL:
      case AttachmentType.FORM:
      case AttachmentType.COMMUNICATION:
        statusDate = attachment.fileInfo[0].uploadedDate;
        statusText = this.i18n.translate(
          'GLOBAL:textUploadedByUserOnDate',
          {
            userName: `${uploadedBy?.firstName} ${uploadedBy?.lastName}`,
            date: this.dateService.formatDate(statusDate)
          },
          'Uploaded by __userName__ on __date__'
        );
        statusTooltip = uploadedBy?.impersonatedBy;
        break;
      case AttachmentType.MERGE:
        statusDate = attachment.fileInfo[0].uploadedDate;
        statusText = this.i18n.translate(
          'GLOBAL:textAddedByUserOnDate',
          {
            userName: `${uploadedBy?.firstName} ${uploadedBy?.lastName}`,
            date: this.dateService.formatDate(statusDate)
          },
          'Added by __userName__ on __date__'
        );
        statusTooltip = uploadedBy?.impersonatedBy;
        break;
    }

    return {
      statusDate,
      statusText,
      statusTooltip
    };
  }

  async doRemoveAttachment (
    fileUploadIds: number[],
    applicationId: number,
    attachmentType: AttachmentType
  ) {
    await Promise.all(fileUploadIds.map(async (uploadId: number) => {
      await this.applicationAttachmentResources.removeAttachment(
        uploadId,
        applicationId,
        attachmentType
      );
    }));
  }

  async handleRemoveAttachment (
    fileUploadIds: number[],
    applicationId: number,
    attachmentType: AttachmentType
  ) {
    const {
      passed
    } = await this.confirmAndTakeAction.genericTakeAction(
      () => this.doRemoveAttachment(fileUploadIds, applicationId, attachmentType),
      this.i18n.translate(
        'GLOBAL:textSuccessfullyRemovedAttachment',
        {},
        'Successfully removed the attachment'
      ),
      this.i18n.translate(
        'GLOBAL:textErrorRemovingAttachment',
        {},
        'There was an error removing the attachment'
      )
    );

    return passed;
  }

  async doAttachExternalFile (
    modalResponse: ExternalFileModalResponse,
    applicationId: number
  ) {
    await this.applicationAttachmentResources.attachExternalFileToApplication(
      modalResponse.selectedFile,
      applicationId,
      modalResponse.documentVisibility,
      modalResponse.applicantCanView
    );
  }

  async handleAttachExternalFile (
    modalResponse: ExternalFileModalResponse,
    applicationId: number
  ) {
    const {
      error,
      passed
    } = await this.confirmAndTakeAction.genericTakeAction(
      () => this.doAttachExternalFile(modalResponse, applicationId),
      this.i18n.translate(
        'GLOBAL:textSuccessfullyAddedTheExternalFile',
        {},
        'Successfully added the external file'
      ),
      ''
    );

    if (passed) {
      return true;
    } else {
      const e = error as HttpErrorResponse;
      this.fileTypeService.displayInvalidFileUploadErrorMessage(e?.error?.message, e);

      return false;
    }
  }

  async doAttachMergeDocument (
    modalResponse: MergeDocumenteModalResponse,
    applicationId: number
  ) {
    await this.applicationAttachmentResources.attachMergeDocumentToApplication(
      modalResponse.documentTemplateId,
      applicationId,
      modalResponse.documentVisibility,
      modalResponse.applicantCanView
    );
  }

  async handleAttachMergeDocument (
    modalResponse: MergeDocumenteModalResponse,
    applicationId: number
  ) {
    const {
      passed
    } = await this.confirmAndTakeAction.genericTakeAction(
      () => this.doAttachMergeDocument(modalResponse, applicationId),
      this.i18n.translate(
        'GLOBAL:textSuccessfullyAddedTheMergeDocument',
        {},
        'Successfully added the merge document'
      ),
      this.i18n.translate(
        'GLOBAL:textErrorAddingMergeDocument',
        {},
        'There was an error adding the merge document'
      )
    );

    return passed;
  }

  async doDownloadFile (
    fileUploadId: number,
    applicationId: number,
    attachmentType: AttachmentType,
    fileName: string
  ) {
    let url = '';
    if (this.portal.isManager) {
      url = await this.getAccessUrlForManager(
        fileUploadId,
        applicationId,
        attachmentType
      );
    } else {
      url = await this.applicationAttachmentResources.getFileAccessUrlForApplicant(fileUploadId);
    }
    if (!!url) {
      await this.fileService.downloadUrlAs(url, fileName);
    }
  }

  async handleDownloadFile (
    fileUploadId: number,
    applicationId: number,
    attachmentType: AttachmentType,
    fileName: string
  ) {
    await this.confirmAndTakeAction.genericTakeAction(
      () => this.doDownloadFile(fileUploadId, applicationId, attachmentType, fileName),
      '',
      this.i18n.translate(
        'CONFIG:textErrorOpeningFile',
        {},
        'There was an error opening the file'
      )
    );
  }

  getAccessUrlForManager (
    fileUploadId: number,
    applicationId: number,
    attachmentType: AttachmentType
  ) {
    return this.applicationAttachmentResources.getFileAccessUrlForManager(
      fileUploadId,
      applicationId,
      attachmentType
    );
  }

  async handleMailMergeModalResponse (
    response: MailMergeModalResponse,
    isNomination = false
  ) {
    const isPrint = response.distributeOptionId === DistributeOptions.PRINT;
    const isDownload = response.distributeOptionId === DistributeOptions.DOWNLOAD;
    if (isPrint || isDownload) {
      const accessURL = await this.attachMergeDocumentToApplicationBulk(
        response,
        isNomination
      );
      const foundTemplate = this.documentTemplateService.findDocumentTemplate(
        response.documentTemplateId
      );
      if (accessURL) {
        if (isPrint) {
          await this.printSelectedMergeDocuments(
            accessURL,
            foundTemplate.name
          );
        } else {
          await this.fileService.downloadUrlAs(accessURL, `${foundTemplate.name}.pdf`);
        }
      } else {
        this.notifier.error(this.i18n.translate(
          isPrint ?
            'CONFIG:textErrorPrintingPDF' :
            'CONFIG:textErrorDownloadingTheFile',
          {},
          isPrint ?
            'There was an error printing the PDF' :
            'There was an error downloading the file'
        ));
      }
    } else {
      await this.handleAttachAndSendDocument(response);
    }
  }

  async doPrintSelectedMergeDocuments (
    accessURL: string,
    documentTitle: string
  ) {
    const blob = await this.fileService.getBlob(accessURL);
    const  url = this.fileService.convertFileToUrl(blob);

    return printJS({
      printable: url,
      type: 'pdf',
      documentTitle: `${documentTitle}.pdf`
    });
  }

  async printSelectedMergeDocuments (
    accessURL: string,
    documentTitle: string
  ) {
    await this.confirmAndTakeAction.genericTakeAction(
      () => this.doPrintSelectedMergeDocuments(accessURL, documentTitle),
      '',
      this.i18n.translate(
        'CONFIG:textErrorPrintingTheSelectedMergeDocument',
        {},
        'There was an error printing the selected merge document'
      ),
      true
    );
  }

  async doAttachMergeDocumentToApplications (
    response: MailMergeModalResponse
  ) {
    return this.applicationAttachmentResources.attachMergeDocumentToApplicationBulk(response);
  }

  async attachMergeDocumentToApplicationBulk (
    response: MailMergeModalResponse,
    isNomination = false
  ): Promise<string> {
    const {
      passed,
      endpointResponse
    } = await this.confirmAndTakeAction.genericTakeAction(
      () => this.doAttachMergeDocumentToApplications(response),
      '',
      this.i18n.translate(
        isNomination ?
          'CONFIG:textErrorAttachingMergeDocsToNoms' :
          'CONFIG:textErrorAttachingMergeDocsToApps',
        {},
        isNomination ?
          'There was an error attaching the merge document to the selected nominations' :
          'There was an error attaching the merge document to the selected applications'
      ),
      true
    );
    
    return passed ? endpointResponse : null;
  }

  async doAttachAndSendDocument (response: MailMergeModalResponse) {
    await this.applicationAttachmentResources.sendMailMergeBulk(response);
  }

  async handleAttachAndSendDocument (response: MailMergeModalResponse) {
    await this.confirmAndTakeAction.genericTakeAction(
      () => this.doAttachAndSendDocument(response),
      this.i18n.translate(
        'GLOBAL:textSuccessBulkMailMerge',
        {},
        'Successfully attached and sent the merge document'
      ),
      this.i18n.translate(
        'GLOBAL:textErrorBulkMailMerge',
        {},
        'There was an error attaching and sending the merge document'
      ),
      true
    );
  }

  async doGetMergePreview (
    templateId: number,
    applicationId: number
  ) {
    return this.applicationAttachmentResources.getMergePreview(templateId, applicationId);
  }

  async getMergePreview (
    templateId: number,
    applicationId: number
  ): Promise<string> {
    const {
      passed,
      endpointResponse
    } = await this.confirmAndTakeAction.genericTakeAction(
      () => this.doGetMergePreview(templateId, applicationId),
      '',
      ''
    );
    
    return passed ? endpointResponse : null;
  }

  async doOpenReferenceFieldFromUrl (url: string) {
    if (!!url) {
      const details = this.applicationFileService.breakDownloadUrlDownToObject(url);
      await this.applicationFileService.openFile(
        +details.applicationId,
        +details.fileId,
        +details.applicationFormId
      );
    }
  }

  async openReferenceFieldFromUrl (url: string) {
    await this.confirmAndTakeAction.genericTakeAction(
      () => this.doOpenReferenceFieldFromUrl(url),
      '',
      this.i18n.translate(
        'CONFIG:textErrorDownloadingTheFile',
        {},
        'There was an error downloading the file'
      ),
      true
    );
  }

  async doDownloadReferenceFieldFile (url: string) {
    if (!!url) {
      const details = this.applicationFileService.breakDownloadUrlDownToObject(url);
      await this.applicationFileService.downloadFile(
        +details.applicationId,
        +details.fileId,
        details.fileName,
        +details.applicationFormId
      );
    }
  }

  async downloadReferenceFieldFile (url: string) {
    await this.confirmAndTakeAction.genericTakeAction(
      () => this.doDownloadReferenceFieldFile(url),
      '',
      this.i18n.translate(
        'CONFIG:textErrorDownloadingTheFile',
        {},
        'There was an error downloading the file'
      ),
      true
    );
  }

  async getFilesForPdfDownload (
    attachments: ApplicationAttachmentForUI[],
    applicationId: number
  ): Promise<FileUploadForPDF[]> {
    const fileAttachments: FileUploadForPDF[] = [];
    const fileNameMap: Record<string, number> = {};
    await Promise.all(attachments.map(async (attachment) => {
      await Promise.all(attachment.fileInfo.map(async (file) => {
        await this.mapFileAttachmentForPdf(fileAttachments, attachment, file, fileNameMap, applicationId);
      }));
    }));

    return fileAttachments;
  }

  async mapFileAttachmentForPdf (
    fileAttachments: FileUploadForPDF[],
    attachment: ApplicationAttachmentForUI,
    file: CommFileInfo,
    fileNameMap: Record<string, number>,
    applicationId: number
  ) {
    let alreadyHave = false;
    const fileUrl = await this.getAccessUrlForManager(
      file.fileUploadId,
      applicationId,
      attachment.attachmentType
    );
    const pdfFile: FileUploadForPDF = {
      applicationId,
      applicationFormId: null,
      fileId: file.fileUploadId,
      fileName: file.fileName,
      fileUrl
    };
    alreadyHave = this.formHelperService.checkIfAlreadyHaveFile(
      pdfFile.fileId,
      fileAttachments
    );
    const passed = this.formHelperService.addToFileNameMap(alreadyHave, pdfFile, fileNameMap);
    if (passed) {
      fileAttachments.push(pdfFile);
    }
  }

  async doOpenFileForManager (
    params: OpenCommunicationFileParams
  ) {
    const accessUrl = await this.getAccessUrlForManager(
      params.file.fileUploadId as number,
      params.applicationId,
      AttachmentType.COMMUNICATION
    );
    const blob = await this.fileService.getBlob(accessUrl) as File;
    const blobUrl = this.fileService.convertFileToUrl(blob);
    window.open(blobUrl, '_blank');
  }

  async openFileForManager (
    params: OpenCommunicationFileParams
  ): Promise<void> {
    await this.confirmAndTakeAction.genericTakeAction(
      () => this.doOpenFileForManager(params),
      '',
      this.i18n.translate(
        'CONFIG:textErrorOpeningFile',
        {},
        'There was an error opening the file'
      ),
      true
    );
  }

  async doOpenCommunicationFile (
    params: OpenCommunicationFileParams
  ) {
    if (params.isForNonprofit) {
      await this.communicationsService.openFileForNonprofitCommunication(
        params.joinCommunicationId,
        params.file.fileUploadId as  number
      );
    } else {
      await this.doOpenFileForManager(params);
    }
  }

  async openCommunicationFile (params: OpenCommunicationFileParams): Promise<void> {
    await this.confirmAndTakeAction.genericTakeAction(
      () => this.doOpenCommunicationFile(params),
      '',
      this.i18n.translate(
        'CONFIG:textErrorOpeningFile',
        {},
        'There was an error opening the file'
      ),
      true
    );
  }

  async doDownloadCommunicationFile (
    params: OpenCommunicationFileParams
  ) {
    if (params.isForNonprofit) {
      await this.communicationsService.downloadAccessUrlForNonprofitCommunication(
        params.joinCommunicationId,
        params.file.fileUploadId as number,
        params.file.fileName
      );
    } else {
      await this.doDownloadFile(
        params.file.fileUploadId as number,
        params.applicationId,
        AttachmentType.COMMUNICATION,
        params.file.fileName
      );
    }
  }

  /**
   *
   * @param params used for determining how we fetch the file
   */
  async downloadCommunicationFile (params: OpenCommunicationFileParams): Promise<void> {
    await this.confirmAndTakeAction.genericTakeAction(
      () => this.doDownloadCommunicationFile(params),
      '',
      this.i18n.translate(
        'CONFIG:textErrorOpeningFile',
        {},
        'There was an error opening the file'
      ),
      true
    );
  }
}

