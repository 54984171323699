import { Injectable } from '@angular/core';
import { StatusService } from '@core/services/status.service';
import { TranslationService } from '@core/services/translation.service';
import { BaseApplication } from '@core/typings/application.typing';
import { ProgramExport } from '@core/typings/program.typing';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { CreateEditFormModalResponse, StartFromFormTemplate } from '@features/configure-forms/create-edit-form-modal/create-edit-form-modal.component';
import { AdaptedFormDefResponse, BaseApplicationForLogic, BasicForm, ComponentOnForm, ExportForm, ExportFormResult, Form, FormAnswerValues, FormAudience, FormChangesWithCompKey, FormDefinitionComponent, FormDefinitionForUi, FormDefinitionWithLogic, FormLogicStates, FormRevisionRefFields, FormTypes, LogicGroupForForm, SaveForm, SaveFormResponseObj, ValueLogicResult } from '@features/configure-forms/form.typing';
import { FormResources } from '@features/configure-forms/forms.resources';
import { PicklistDataType } from '@features/custom-data-tables/custom-data-tables.typing';
import { CustomDataTablesService } from '@features/custom-data-tables/services/custom-data-table.service';
import { EmployeeSSOFieldsService } from '@features/employee-sso-fields/employee-sso-fields.service';
import { ExternalAPIService } from '@features/external-api/external-api.service';
import { FormFieldAdHocService } from '@features/form-fields/services/form-field-ad-hoc.service';
import { FormFieldCategoryService } from '@features/form-fields/services/form-field-category.service';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { DefaultValType } from '@features/forms/component-configuration/component-configuration.typing';
import { ExternalAPISelection } from '@features/forms/component-configuration/external-api-selector-settings/external-api-selector-settings.component';
import { FormulaBuilderService } from '@features/formula-builder/formula-builder.service';
import { FormulaState, RootFormula } from '@features/formula-builder/formula-builder.typing';
import { LogicBuilderService } from '@features/logic-builder/logic-builder.service';
import { EvaluationType, GlobalLogicGroup, GlobalValueLogicGroup, ListLogicState, LogicColumn, LogicColumnDisplay, LogicFilterTypes, LogicState, NestedPropColumn, SelectLogicColumnDisplay } from '@features/logic-builder/logic-builder.typing';
import { LogicStateService } from '@features/logic-builder/logic-state.service';
import { Base64, Tab } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { CurrencyRadioOptions, CurrencyValue } from '@yourcause/common/currency';
import { DateService } from '@yourcause/common/date';
import { FileService } from '@yourcause/common/files';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ArrayHelpersService, GuidService } from '@yourcause/common/utils';
import { isEqual, uniq } from 'lodash';
import { ComponentHelperService, REF_COMPONENT_TYPE_PREFIX } from '../component-helper/component-helper.service';
import { FormLogicResources } from './form-logic.resources';
import { FormLogicState } from './form-logic.state';

@AttachYCState(FormLogicState)
@Injectable({ providedIn: 'root' })
export class FormLogicService extends BaseYCService<FormLogicState> {

  constructor (
    private formFieldAdHocService: FormFieldAdHocService,
    private formulaBuilderService: FormulaBuilderService,
    private logicBuilderService: LogicBuilderService,
    private componentHelper: ComponentHelperService,
    private customDataTableService: CustomDataTablesService,
    private arrayHelper: ArrayHelpersService,
    private i18n: I18nService,
    private guidService: GuidService,
    private logger: LogService,
    private clientSettingsService: ClientSettingsService,
    private formLogicResources: FormLogicResources,
    private externalApiService: ExternalAPIService,
    private notifier: NotifierService,
    private statusService: StatusService,
    private formFieldHelperService: FormFieldHelperService,
    private employeeSSOFieldsService: EmployeeSSOFieldsService,
    private logicStateService: LogicStateService,
    private translationService: TranslationService,
    private formResources: FormResources,
    private fileService: FileService,
    private dateService: DateService,
    private formFieldCategoryService: FormFieldCategoryService
  ) {
    super();
  }

  get formDetail () {
    return this.get('formDetails');
  }

  get reportFieldLogicColumns () {
    return this.get('reportFieldLogicColumns');
  }

  get pageOptionsMap () {
    return this.get('pageOptionsMap');
  }

  get typeAudienceMap () {
    return this.get('typeAudienceMap');
  }

  get logicBuilderKeyToCompKey () {
    return this.get('logicBuilderKeyToCompKey');
  }

  get compKeyToLogicBuilderKey () {
    return this.get('compKeyToLogicBuilderKey');
  }

  get currentValidityState () {
    return this.get('currentValidityState');
  }

  get finalValidationMap () {
    return this.get('finalValidationMap');
  }

  get customValidationMap () {
    return this.get('customValidationMap');
  }

  setCurrentValidityState (
    validityState: LogicState<BaseApplicationForLogic, boolean>
  ) {
    this.set('currentValidityState', validityState);
  }

  setFinalValidationMap (validityMap: Record<string, boolean>) {
    this.set('finalValidationMap', validityMap);
  }

  setCustomValidationMap (validityMap: Record<string, boolean>) {
    this.set('customValidationMap', validityMap);
  }

  setTypeAudienceMap (typeAudienceMap: Record<number, FormAudience>) {
    this.set('typeAudienceMap', typeAudienceMap);
  }

  /**
   *
   * @param component form field component
   * @returns boolean of whether we should skip the value related logic for this component. If the component is hidden and set to clear on hide, then we don't need to set the value of that component, it should stay cleared
   */
   shouldSkipValueLogic (component: FormDefinitionComponent) {
    return (component.isHidden || component.hiddenFromParent) && component.clearOnHide;
  }

  setPageOptionsMap (map: Record<string, TypeaheadSelectOption<string>[]>) {
    this.set('pageOptionsMap', map);
  }

  /**
   *
   * @param id: form id
   * @param revisionId: revision id
   * @returns the form detail
   */
  getFormDetail (id: number, revisionId: number) {
    return this.formDetail[+('' + id + revisionId)];
  }

  /**
   *
   * @param id: form id
   * @param revisionId: revision id
   * @param form: the form to set
   */
  setFormDetail (id: number, revisionId: number, form: Form) {
    this.set('formDetails', {
      ...this.formDetail,
      ['' + id + revisionId]: form
    });
  }

  /**
   *
   * @param formId: form id
   * @param revisionId: revision id
   * @returns the fetched form
   */
  async getAndSetForm (
    formId: number,
    revisionId: number
  ): Promise<Form> {
    const form = this.getFormDetail(formId, revisionId);
    if (!form) {
      await this.fetchFormDetail(formId, revisionId);
    }

    return this.getFormDetail(formId, revisionId);
  }

  /**
   *
   * @param formId: form id
   * @param revisionId: revision id
   * @returns the form detail
   */
  async fetchFormDetail (
    formId: number,
    revisionId: number
  ) {
    const form = await this.formLogicResources.getForm(formId, revisionId);
    const adaptedResponse = this.adaptFormDefinitionForTabs(form.formDefinition, form.formType);
    const adaptedForm: Form = {
      ...form,
      formDefinition: adaptedResponse.formDefinition,
      customJavascriptComps: adaptedResponse.customJavascriptComps
    };
    this.setFormDetail(formId, revisionId, adaptedForm);

    return adaptedForm;
  }

  /**
   *
   * @param formDefinition: form definition
   * @returns the form schema
   */
  getFormSchema (formDefinition: FormDefinitionForUi[]) {
    const formSchema: {
      key: string;
      label: string;
      type: string;
      values: any[];
    }[] = [];
    formDefinition.forEach((tab) => {
      this.componentHelper.eachComponent(
        tab.components, (component) => {
        const inputData = this.componentHelper.getComponentInputData(component);
        if (inputData) {
          formSchema.push(inputData);
        }
      }, true);
    });

    return formSchema;
  }

  /**
   *
   * @param response: create form modal response
   * @returns the new form route
   */
  async handleCreateForm (
    response: CreateEditFormModalResponse
  ) {
    try {
      const {
        formDefinition,
        formSchema,
        referenceFieldIds,
        externalApiRequestIds,
        picklistGuids,
        nominationReferenceFieldIds,
        employeeApplicantInfoKeys,
        nominatorInfoKeys
      } = await this.getFormDetailsForCreate(response.startFromTemplate);
      const payload: SaveForm = {
        revisionId: null,
        id: null,
        name: response.formName,
        description: response.description ?? '',
        formType: response.formType,
        formDefinition,
        formSchema,
        availableForTranslation: true,
        defaultLanguageId: response.defaultFormLang,
        picklistGuids,
        referenceFieldIds,
        externalApiRequestIds,
        requireSignature: response.requireSignature,
        signatureDescription: response.signatureDescription,
        nominationReferenceFieldIds,
        employeeApplicantInfoKeys,
        nominatorInfoKeys
      };
      const form = await this.formLogicResources.saveForm(payload);
      this.notifier.success(this.i18n.translate(
        'FORMS:textSuccessCreateForm',
        {},
        'Successfully created the custom form'
      ));

      return `/management/program-setup/forms/${form.id}/draft`;
    } catch (e) {
      this.notifier.error(this.i18n.translate(
        'FORMS:textErrorCreatingForm',
        {},
        'There was an error creating the custom form'
      ));

      return null;
    }
  }

  /**
   *
   * @param startFromTemplate: template to start from
   * @returns form details for create
   */
  async getFormDetailsForCreate (
    startFromTemplate: StartFromFormTemplate
  ): Promise<{
      formDefinition: FormDefinitionForUi[];
      formSchema: any;
      referenceFieldIds: FormRevisionRefFields[];
      externalApiRequestIds: number[];
      picklistGuids: string[];
      nominationReferenceFieldIds: number[];
      employeeApplicantInfoKeys: string[];
      nominatorInfoKeys: string[];
    }> {
    if (startFromTemplate?.revisionId) {
      const details = await this.getAndSetForm(startFromTemplate.formId, startFromTemplate.revisionId);
      const {
        referenceFieldIds,
        customDataTableGuids,
        nominationReferenceFieldIds,
        employeeApplicantInfoKeys,
        nominatorInfoKeys
      } = this.formFieldHelperService.extractReferenceFieldsFromForm(
        details.formDefinition,
        this.formFieldHelperService.allReferenceFields
      );
      const externalApiRequests = this.getExternalAPICalls(
        details.formDefinition
      );

      return {
        formDefinition: details.formDefinition,
        formSchema: this.getFormSchema(details.formDefinition),
        referenceFieldIds,
        externalApiRequestIds: this.externalApiService.extractIds(externalApiRequests),
        picklistGuids: customDataTableGuids,
        nominationReferenceFieldIds,
        employeeApplicantInfoKeys,
        nominatorInfoKeys
      };
    }

    return {
      formDefinition: [{
        tabName: 'Page One',
        components: [],
        uniqueId: this.guidService.nonce(),
        index: 0,
        logic: null
      }],
      formSchema: [],
      referenceFieldIds: [],
      externalApiRequestIds: [],
      picklistGuids: [],
      nominationReferenceFieldIds: [],
      employeeApplicantInfoKeys: [],
      nominatorInfoKeys: []
    };
  }

  /**
   *
   * @param e: the error
   * @returns if there is a pending form error
   */
  handleSaveFormError (e: any): boolean {
    if (
      e.error &&
      e.error.message === 'This form already has a draft revision pending to be published'
    ) {
      return true;
    } else {
      this.notifier.error(this.i18n.translate(
        'FORMS:textErrorUpdatingTheForm',
        {},
        'There was an error updating the form'
      ));

      return false;
    }
  }

  /**
   *
   * @param formId: form id
   * @param revisionId: revision id
   */
  async resetAfterSave (formId: number, revisionId: number) {
    this.setFormDetail(formId, revisionId, undefined);
  }

  /**
   *
   * @param data: payload to save form
   * @returns response details
   */
  async saveFormNewRevision (
    data: SaveForm
  ): Promise<SaveFormResponseObj> {
    try {
      const response = await this.formLogicResources.saveForm(data);
      await this.resetAfterSave(data.id, data.revisionId);

      return {
        success: true,
        id: response?.id,
        hasPendingFormError: false
      };
    } catch (e) {
      this.logger.error(e);
      const hasPendingFormError = this.handleSaveFormError(e);

      return {
        success: false,
        id: null,
        hasPendingFormError
      };
    }
  }

  /**
   *
   * @param data: payload to save form
   * @returns response details
   */
  async saveFormExistingRevision (
    data: SaveForm
  ): Promise<SaveFormResponseObj> {
    try {
      const response = await this.formLogicResources.updateRevision(
        data.id,
        data.revisionId,
        data
      );
      await this.resetAfterSave(data.id, data.revisionId);

      return {
        success: true,
        id: response?.id,
        hasPendingFormError: false
      };
    } catch (e) {
      this.logger.error(e);
      const hasPendingFormError = this.handleSaveFormError(e);

      return {
        success: false,
        id: null,
        hasPendingFormError
      };
    }
  }

  /**
   *
   * @param formInfo: form info to copy
   * @returns response details
   */
  async copyForm (
    formInfo: BasicForm
  ) {
    try {
      const form = await this.fetchFormDetail(formInfo.formId, formInfo.revisionId);

      const {
        referenceFieldIds,
        customDataTableGuids,
        nominationReferenceFieldIds,
        employeeApplicantInfoKeys,
        nominatorInfoKeys
      } = this.formFieldHelperService.extractReferenceFieldsFromForm(
        form.formDefinition,
        this.formFieldHelperService.allReferenceFields
      );

      const externalApiRequests = this.getExternalAPICalls(form.formDefinition);
      const copyText = this.i18n.translate(
        'common:textCopy',
        {},
        'Copy'
      );
      const data: SaveForm = {
        name: `${formInfo.name} - ${copyText}`,
        description: formInfo.description,
        formDefinition: form.formDefinition,
        formSchema: this.getFormSchema(form.formDefinition),
        formType: formInfo.formType,
        availableForTranslation: true,
        defaultLanguageId: formInfo.defaultLanguageId,
        picklistGuids: customDataTableGuids,
        referenceFieldIds,
        externalApiRequestIds: this.externalApiService.extractIds(externalApiRequests),
        requireSignature: form.requireSignature,
        signatureDescription: form.signatureDescription,
        nominationReferenceFieldIds,
        employeeApplicantInfoKeys,
        nominatorInfoKeys
      };
      const result = await this.saveFormNewRevision(data);
      this.notifier.success(this.i18n.translate(
        'FORMS:notificationSuccessCopyForm',
        {},
        'Successfully copied form'
      ));

      return result;
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'FORMS:notificationErrorCopyForm',
        {},
        'There was an error copying the form'
      ));

      return null;
    }
  }

  /**
   *
   * @param formDefinition: form definition to adapt
   * @returns the adapted form definition
   */
  adaptFormDefinitionForTabs (
    formDefinition: FormDefinitionWithLogic|FormDefinitionWithLogic[],
    formType: FormTypes
  ): AdaptedFormDefResponse {
    let adaptedDefinition: FormDefinitionForUi[];
    if (!(formDefinition instanceof Array)) {
      adaptedDefinition = [{
        tabName: 'Page One',
        logic: formDefinition.logic,
        components: [
          ...formDefinition.components
        ],
        uniqueId: this.guidService.nonce(),
        index: 0
      }];
    } else {
      adaptedDefinition = formDefinition.map((def, index) => {
        return {
          ...def,
          uniqueId: this.guidService.nonce(),
          index
        };
      });
    }

    const {
      adaptedFormDefinition,
      customJavascriptComps
    } = this.adaptComponentsForLogic(adaptedDefinition, formType);

    return {
      formDefinition: adaptedFormDefinition,
      customJavascriptComps
    };
  }

  /**
   *
   * @param reportFieldLogicColumns: Report field logic columns to set
   */
  setReportFieldLogicColumns (reportFieldLogicColumns: LogicColumnDisplay<BaseApplicationForLogic>[]) {
    this.set('reportFieldLogicColumns', reportFieldLogicColumns);
  }

  /**
   *
   * @param formDefinition Form Definition
   * @param externalFields: External fields for this form response / application
   * @param reportFieldResponse: Report field response from API
   * @returns Logic States for Conditional Visibility, Validity, Set Value, and Formulas
   */
  initFormDefinitionLogic (
    formDefinition: FormDefinitionForUi[],
    externalFields?: BaseApplication,
    reportFieldResponse?: AdHocReportingUI.ReportFieldResponseRow
  ): FormLogicStates {
    const { logicGroups, recordForLogic, formulas } = this.getLogicContext(
      formDefinition,
      externalFields,
      reportFieldResponse
    );
    const conditionals = logicGroups.reduce((acc, group) => {
      return [
        ...acc,
        [
          group[0],
          group[1].visibilityGroup
        ] as const
      ];
    }, [] as (readonly [LogicColumn<BaseApplicationForLogic>, GlobalLogicGroup<BaseApplicationForLogic>])[])
    .filter(value => !!value[0] && !!value[1]);

    const validities = logicGroups.reduce((acc, group) => {
      return [
        ...acc,
        [
          group[0],
          group[1].validityGroup
        ] as const
      ];
    }, [] as (readonly [LogicColumn<BaseApplicationForLogic>, GlobalLogicGroup<BaseApplicationForLogic>])[])
    .filter(value => !!value[0] && !!value[1]);

    const setValues = logicGroups.reduce((acc, group) => {
      return [
        ...acc,
        [
          group[0],
          group[1].conditionalValueGroups
        ] as const
      ];
    }, [] as (readonly [LogicColumn<BaseApplicationForLogic>, GlobalValueLogicGroup<BaseApplicationForLogic, ValueLogicResult<BaseApplicationForLogic>>[]])[]);

    const conditionalVisibilityState = this.logicBuilderService.runConditionalLogic<BaseApplicationForLogic>(
      conditionals,
      recordForLogic
    );

    const validityState = this.logicBuilderService.runConditionalLogic<BaseApplicationForLogic>(
      validities,
      recordForLogic
    );

    const setValueState = this.logicBuilderService.runValueLogic<BaseApplicationForLogic, ValueLogicResult<BaseApplicationForLogic>>(
      setValues,
      recordForLogic
    );

    const formulaState = this.formulaBuilderService.startRootFormulas<BaseApplicationForLogic>(
      formulas,
      recordForLogic
    );

    return {
      conditionalVisibilityState,
      validityState,
      setValueState,
      formulaState
    };
  }

  /**
   *
   * @param formDefinition: Form definition (array of tabs)
   * @param externalFields: External fields for this form response / application
   * @param reportFieldResponse: Report field response from API
   * @returns Logic groups array, Record for logic, and Formulas array
   */
  getLogicContext (
    formDefinition: FormDefinitionForUi[],
    externalFields?: BaseApplication,
    reportFieldResponse?: AdHocReportingUI.ReportFieldResponseRow
  ): {
    logicGroups: LogicGroupForForm[];
    recordForLogic: BaseApplicationForLogic;
    formulas: RootFormula<BaseApplicationForLogic>[];
  } {
    let recordForLogic: BaseApplicationForLogic;
    if (!!externalFields) {
      recordForLogic = this.getRecordForLogic(
        externalFields,
        reportFieldResponse
      );
    }
    const formFieldLogicGroups = this.getFormFieldLogicGroups(formDefinition);
    const formulas = this.getFormFieldFormulas(formDefinition);
    const tabLogicGroups = formDefinition.reduce<LogicGroupForForm[]>((acc, tab, index) => {
      // TODO: fix after ts 4.1 upgrade (ng 11.1)
      const column = ['tabs', index] as LogicColumn<BaseApplicationForLogic>;

      return [
        ...acc,
        [
          column,
          {
            visibilityGroup: tab.logic || null,
            validityGroup: null,
            conditionalValueGroups: []
          }
        ]
      ];
    }, []);

    const logicGroups = [
      ...formFieldLogicGroups,
      ...tabLogicGroups
    ].filter(([ _, logic ]) => {

      return !!logic.visibilityGroup ||
        !!logic.validityGroup ||
        logic.conditionalValueGroups?.length > 0;
    });

    return { logicGroups, recordForLogic, formulas };
  }

  /**
   *
   * @param form: the form
   * @returns the form field formulas
   */
  getFormFieldFormulas (
    form: FormDefinitionForUi[]
  ) {
    const formulas: RootFormula<BaseApplicationForLogic>[] = [];
    form.forEach((tab) => {
      this.componentHelper.eachComponent(tab.components, (comp) => {
        const formula = comp.formula;
        if (formula?.step) {
          const prop = comp.type.split('-').join('.');
          formulas.push({
            ...formula,
            property: prop
          });
        }
      });
    });

    return formulas;
  }

  /**
   *
   * @param form: the form
   * @returns the form field logic groups
   */
  getFormFieldLogicGroups (
    form: FormDefinitionForUi[]
  ) {
    const groups: LogicGroupForForm[] = [];
    form.forEach((tab) => {
      this.componentHelper.eachComponent(tab.components, (comp) => {
        const group = this.getComponentLogic(comp);
        if (group) {
          groups.push(group);
        }
      }, true);
    });

    return groups;
  }

  /**
   *
   * @param externalFields: External Fields for this response / application
   * @param reportFieldResponse: Report field response from API
   * @returns The record to use for running logic
   */
  getRecordForLogic (
    externalFields: BaseApplication,
    reportFieldResponse: AdHocReportingUI.ReportFieldResponseRow
  ): BaseApplicationForLogic {
    const application = (externalFields ?? {}) as BaseApplication;

    return {
      tabs: [],
      layoutComponents: {},
      application: {
        ...application,
        amountRequested: externalFields.amountRequestedForEdit
      },
      employeeSso: externalFields.employeeInfo,
      reportFieldResponse,
      referenceFields: externalFields?.referenceFields
    };
  }

  /**
   *
   * @param comp: form component
   * @returns the components logic
   */
  getComponentLogic (
    comp: FormDefinitionComponent
  ): LogicGroupForForm {
    const employeeSsoColumn = this.getEmployeeSsoFieldColumnLogic(comp);
    if (!!employeeSsoColumn) {
      return employeeSsoColumn;
    }
    const appCompColumn = this.getAppCompColumnLogic(comp);
    if (!!appCompColumn) {
      return appCompColumn;
    }
    const layoutCompColumn = this.getLayoutColumnLogic(comp);
    if (!!layoutCompColumn) {
      return layoutCompColumn;
    }
    const reportFieldColumn = this.getReportFieldColumnLogic(comp);
    if (!!reportFieldColumn) {
      return reportFieldColumn;
    }
    const refFieldColumn = this.getRefFieldColumnLogic(comp);
    if (!!refFieldColumn) {
      return refFieldColumn;
    }

    return null;
  }

  /**
   *
   * @param comp: form component
   * @returns reference field column logic
   */
  getRefFieldColumnLogic (comp: FormDefinitionComponent): LogicGroupForForm {
    const refField = this.formFieldHelperService.getReferenceFieldFromCompType(
      comp.type
    );
    if (!!refField) {
      const column = ['referenceFields', refField.key] as LogicColumn<BaseApplicationForLogic>;

      return this.getLogicGroupForComp(column, comp);
    }

    return null;
  }

  getRefFieldColumn (key: string) {
    return ['referenceFields', key] as LogicColumn<BaseApplicationForLogic>;
  }

  /**
   *
   * @param column: logic column
   * @param comp: form component
   * @returns the logic group for the component
   */
  getLogicGroupForComp (
    column: LogicColumn<BaseApplicationForLogic>,
    comp: FormDefinitionComponent
  ): LogicGroupForForm {
    return [
      column,
      {
        visibilityGroup: comp.conditionalLogic || null,
        validityGroup: comp.customValidation || null,
        conditionalValueGroups: comp.conditionalValue || []
      }
    ];
  }

  /**
   *
   * @param comp: form component
   * @returns the app components column logic
   */
  getAppCompColumnLogic (comp: FormDefinitionComponent): LogicGroupForForm {
    const isAppComp = this.componentHelper.isStandardComponent(comp.type);
    if (isAppComp) {
      const column = this.getAppCompColumn(comp.type);

      return this.getLogicGroupForComp(column, comp);
    }

    return null;
  }

  getAppCompColumn (type: string) {
    return ['application', type] as LogicColumn<BaseApplicationForLogic>;
  }

  /**
   *
   * @param comp: the form component
   * @returns layout component column logic
   */
  getLayoutColumnLogic (comp: FormDefinitionComponent): LogicGroupForForm {
    if (this.componentHelper.isLayoutComponent(comp.type)) {
      const column = ['layoutComponents', comp.key] as LogicColumn<BaseApplicationForLogic>;

      return this.getLogicGroupForComp(column, comp);
    }

    return null;
  }

  /**
   *
   * @param comp: the form component
   * @returns report field column logic
   */
  getReportFieldColumnLogic (comp: FormDefinitionComponent): LogicGroupForForm {
    if (this.componentHelper.isReportFieldComp(comp.type)) {
      const column = this.getReportFieldColumn(
        comp.reportFieldDataOptions.reportFieldObject,
        comp.reportFieldDataOptions.reportFieldDisplay
      );

      return this.getLogicGroupForComp(column, comp);
    }

    return null;
  }

  getReportFieldColumn (reportFieldObject: string, reportFieldDisplay: string) {
    return ['reportFieldResponse', reportFieldObject, reportFieldDisplay] as LogicColumn<BaseApplicationForLogic>;
  }

  /**
   *
   * @param comp: the form component
   * @returns employee sso field column logic
   */
  getEmployeeSsoFieldColumnLogic (comp: FormDefinitionComponent): LogicGroupForForm {
    if (this.componentHelper.isEmployeeSsoComponent(comp.type)) {
      const column = this.getEmployeeSsoColumn(comp.type);

      return this.getLogicGroupForComp(column, comp);
    }

    return null;
  }

  getEmployeeSsoColumn (type: string) {
    const attribute = this.componentHelper.getEmployeeSsoAttrFromCompType(type);
    
    return ['employeeSso', attribute] as LogicColumn<BaseApplicationForLogic>;
  }

  getReportFieldColumnsForLogic (
    allCompsOnForm: FormDefinitionComponent[]
  ): LogicColumnDisplay<BaseApplicationForLogic>[] {
    return (this.reportFieldLogicColumns || []).filter((reportFieldColumn) => {
      return allCompsOnForm.some((compOnForm) => {
        if (this.componentHelper.isReportFieldComp(compOnForm.type)) {
          const config = compOnForm.reportFieldDataOptions;
          if (this.componentHelper.isReportNominationField(config?.reportFieldObject)) {
            return reportFieldColumn.column.includes(this.componentHelper.getNominatorReportKey(config?.reportFieldDisplay) as keyof BaseApplicationForLogic);
          } else {
            return reportFieldColumn.column.includes(
              config?.reportFieldDisplay as keyof BaseApplicationForLogic
            );
          }
        }

         return false;
      });
    });
  }

  /**
   * Get a list of employee SSO columns for logic
   *
   * @returns employee sso columns
   */
  getEmployeeSsoColumnsForLogic (): LogicColumnDisplay<BaseApplicationForLogic>[] {
    if (this.clientSettingsService.clientSettings?.hasSSO) {
      return (this.employeeSSOFieldsService.employeeSSOFields || []).map((employeeField) => {
        return {
          label: employeeField.name || employeeField.columnName,
          column: ['employeeSso', employeeField.attr] as NestedPropColumn<BaseApplicationForLogic>,
          type: 'text',
          otherColumnOptions: []
        };
      });
    }

    return [];
  }

  getLogicColumnId (
    component: FormDefinitionComponent
  ): string {
    const isReportField = this.componentHelper.isReportFieldComp(component.type);
    if (isReportField) {
      return this.getReportFieldColumn(
        component.reportFieldDataOptions.reportFieldObject,
        component.reportFieldDataOptions.reportFieldDisplay
      ).join('.');
    }
    const isEmployeeSsoField = this.componentHelper.isEmployeeSsoComponent(component.type);
    if (isEmployeeSsoField) {
      return this.getEmployeeSsoColumn(component.type).join('.');
    }
    const isStandardField = this.componentHelper.isStandardComponent(component.type);
    if (isStandardField) {
      return this.getAppCompColumn(component.type).join('.');
    }
    const isRefField = this.componentHelper.isReferenceFieldComp(component.type);
    if (isRefField) {
      const field = this.formFieldHelperService.getReferenceFieldFromCompType(component.type);
      if (!field) {
        this.logger.log('Field not found in getLogicColumnId', {
          type: component.type,
          key: component.key
        });
      }
      
      return this.getRefFieldColumn(field.key).join('.');
    }


    return null;
  }

  setLogicComponentsMap (
    formDefinition: FormDefinitionForUi[]
  ) {
    const allComps = this.componentHelper.getAllComponents(formDefinition);
    const logicBuilderKeyToCompKey = allComps.reduce((acc, comp) => {
      const key = this.getLogicColumnId(comp);
      if (!!key) {
        return {
          ...acc,
          [this.getLogicColumnId(comp)]: comp.key
        }
      }

      return {
        ...acc
      };
    }, {} as Record<string, string>);
    const compKeyToLogicBuilderKey: Record<string, string> = {};
    Object.keys(logicBuilderKeyToCompKey).forEach((leftSideKey) => {
      const rightSideKey = logicBuilderKeyToCompKey[leftSideKey];
      compKeyToLogicBuilderKey[rightSideKey] = leftSideKey;
    });
    this.set('logicBuilderKeyToCompKey', logicBuilderKeyToCompKey);
    this.set('compKeyToLogicBuilderKey', compKeyToLogicBuilderKey);
  }

  /**
   *
   * @param filteredFormDef Form definition array
   * @param formDefinition: Form definition
   * @param index Index of form tab
   * @param formAudience Audience of form
   * @param skipFilteringToCurrentTab Skip application field filtering
   */
  getAvailableColumnsForLogicModal (
    filteredFormDef: FormDefinitionForUi[],
    formDefinition: FormDefinitionForUi[],
    index: number,
    formAudience: FormAudience,
    skipFilteringToCurrentTab: boolean
  ): LogicColumnDisplay<BaseApplicationForLogic>[] {
    const allComps = this.componentHelper.getAllComponents(filteredFormDef);
    const reportFieldColumns = this.getReportFieldColumnsForLogic(allComps);
    const employeeSsoColumns = this.getEmployeeSsoColumnsForLogic();
    const refColumns = allComps.map<LogicColumnDisplay<BaseApplicationForLogic>>((component) => {
      return this.getReferenceFieldLogicColumnDisplay(component);
    }).filter((item) => !!item);

    const applicationFields = this.getApplicationFieldsForLogic(formAudience, allComps);

    const availableColumns = [
      ...reportFieldColumns,
      ...refColumns,
      ...employeeSsoColumns,
      ...applicationFields.filter((field) => {
        if (!skipFilteringToCurrentTab) {
          return !this.componentHelper.checkIfCompTypeExistsOnTab(
            field.column[1],
            formDefinition[index]
          );
        }

        return true;
      })
    ];
    this.addOtherColumnOptions(availableColumns);

    return availableColumns;
  }

  /**
   *
   * @param formAudience: the form audience
   * @returns the application fields for logic
   */
  getApplicationFieldsForLogic (
    formAudience: FormAudience,
    allComps: FormDefinitionComponent[]
  ) {
    let applicationFields = this.getApplicantApplicationFieldsForLogic();
    if (formAudience === FormAudience.MANAGER) {
      const hasReveiwerRecommendedFunding = allComps.some((comp) => {
        return comp.type === 'reviewerRecommendedFundingAmount';
      });
      applicationFields = [
        ...applicationFields,
        ...this.getManagerApplicationFieldsForLogic(hasReveiwerRecommendedFunding)
      ];
    }

    return applicationFields;
  }

  /**
   *
   * @returns the application applicant fields for logic
   */
  getApplicantApplicationFieldsForLogic (): LogicColumnDisplay<BaseApplicationForLogic>[] {
    return [{
      label: this.i18n.translate(
        'GLOBAL:lblCashAmountRequested',
        {},
        'Cash amount requested'
      ),
      column: ['application', 'amountRequested'] as NestedPropColumn<BaseApplicationForLogic>,
      type: 'number',
      otherColumnOptions: []
    }, {
      label: this.i18n.translate(
        'GLOBAL:textDesignation',
        {},
        'Designation'
      ),
      column: ['application', 'designation'] as NestedPropColumn<BaseApplicationForLogic>,
      type: 'text',
      otherColumnOptions: []
    }];
  }

  /**
   *
   * @param hasReveiwerRecommendedFunding: If the form has this field on it
   * @returns the manager application fields for logic
   */
  getManagerApplicationFieldsForLogic (
    hasReveiwerRecommendedFunding: boolean
  ): LogicColumnDisplay<BaseApplicationForLogic>[] {
    const columns: LogicColumnDisplay<BaseApplicationForLogic>[] = [{
      label: this.i18n.translate(
        'GLOBAL:lblDecision',
        {},
        'Decision'
      ),
      column: ['application', 'decision'] as NestedPropColumn<BaseApplicationForLogic>,
      type: 'multi-list',
      filterOptions: this.statusService.decisionOptions,
      otherColumnOptions: []
    }, {
      label: this.i18n.translate(
        'common:textApplicationRecommendedFundingAmount',
        {},
        'Application recommended funding amount'
      ),
      column: ['application', 'recommendedFundingAmount'] as NestedPropColumn<BaseApplicationForLogic>,
      type: 'number',
      otherColumnOptions: []
    }];
    if (hasReveiwerRecommendedFunding) {
      columns.push({
        label: this.i18n.translate(
          'GLOBAL:textReviewerRecommendedFundingAmount',
          {},
          'Reviewer recommended funding amount'
        ),
        column: ['application', 'reviewerRecommendedFundingAmount'] as NestedPropColumn<BaseApplicationForLogic>,
        type: 'number',
        otherColumnOptions: []
      });
    }

    return columns;
  }

  /**
   *
   * @param availableColumns: available columns for logic for a given form definition
   */
  addOtherColumnOptions (availableColumns: LogicColumnDisplay<BaseApplicationForLogic>[]) {
    availableColumns.forEach((column) => {
      const filteredOptions = availableColumns.filter((col) => {
        return !isEqual(column.column, col.column);
      });
      column.otherColumnOptions = filteredOptions.filter((col) => {
        return this.isMatchingFilterType(column.type, col.type);
      }).map((col) => {
        return {
          label: col.label,
          value: col.column
        };
      });
      column.otherColumnOptions = this.arrayHelper.sort(column.otherColumnOptions, 'label');
    });
  }

  /**
   *
   * @param componentFilterType: The components filter type
   * @param otherComponentFilterType: the other components filter type we are comparing
   * @returns if the types are compatible
   */
  isMatchingFilterType (
    componentFilterType: LogicFilterTypes,
    otherComponentFilterType: LogicFilterTypes
  ) {
    switch (componentFilterType) {
      default:
        return otherComponentFilterType === componentFilterType;
      case 'number':
      case 'currency':
        return ['number', 'currency'].includes(otherComponentFilterType);
    }
  }

  /**
   *
   * @param component: form component
   * @returns the reference field logic column display
   */
  getReferenceFieldLogicColumnDisplay (
    component: FormDefinitionComponent
  ): LogicColumnDisplay<BaseApplicationForLogic> {
    const refField = this.formFieldHelperService.getReferenceFieldFromCompType(component.type);
    const invalidRefTypes = [
      ReferenceFieldsUI.ReferenceFieldTypes.Table,
      ReferenceFieldsUI.ReferenceFieldTypes.Subset,
      ReferenceFieldsUI.ReferenceFieldTypes.Address
    ];
    if (
      refField &&
      !refField.isMasked &&
      !refField.isRichText &&
      !refField.isEncrypted &&
      !invalidRefTypes.includes(refField.type)
    ) {
      const [config] = this.formFieldAdHocService.getReferenceFieldColumnDef(
        {
          ...refField,
          formIds: []
        },
        true
      );
      const base = {
        label: component.label,
        column: this.getRefFieldColumn(refField.key),
        type: config.type
      };
      const hasFilterOptions = [
        'list', 'typeaheadSingleEquals', 'multiValueList', 'multi-list', 'multiListFuzzyText'
      ].includes(base.type);
      if (hasFilterOptions) {
        let type = base.type;
        const relatedPicklist = this.customDataTableService.getCDTFromGuid(
          refField.customDataTableGuid
        );
        if (relatedPicklist?.dataType === PicklistDataType.Numeric) {
          type = 'number';
        }

        return <SelectLogicColumnDisplay<BaseApplicationForLogic>>{
          ...base,
          type,
          filterOptions: 'filterOptions' in config ? config.filterOptions : []
        };
      }

      return base as LogicColumnDisplay<BaseApplicationForLogic>;
    }

    return null;
  }

  /**
   * @param component: form component
   * @param filterOutComp Component to filter out of available columns
   * @param formDefinition Only pass if adapting, otherwise it uses current value
   */
  getAvailableLogicColumnsForComponent (
    component: FormDefinitionComponent,
    filterOutComp: boolean,
    formDefinition: FormDefinitionForUi[],
    currentFormBuilderIndex: number,
    currentFormBuilderFormAudience: FormAudience
  ): {
    availableColumns: LogicColumnDisplay<BaseApplicationForLogic>[];
    sourceColumn: LogicColumnDisplay<BaseApplicationForLogic>;
  } {
    const cols = this.getAvailableColumnsForLogicModal(
      formDefinition,
      formDefinition,
      currentFormBuilderIndex,
      currentFormBuilderFormAudience,
      true
    );
    let sourceColumn: LogicColumnDisplay<BaseApplicationForLogic>;
    const availableColumns = cols.filter((col) => {
      const column = this.getComponentLogic(component);
      if (column) {
        const isCurrentColumn = isEqual(column[0], col.column);
        if (isCurrentColumn) {
          sourceColumn = col;
          if (filterOutComp) {
            return false;
          }
        }
        // only return columns that are not the current source column
        col.otherColumnOptions = col.otherColumnOptions.filter((oco) => {
          return !isEqual(oco.value, column[0]);
        });
      }

      return true;
    });

    return {
      availableColumns,
      sourceColumn
    };
  }

  /**
   *
   * @param currentFormDefinition: the form definition
   * @param setValueState: the set value state
   * @param conditionalVisibilityState: the conditional visibility state
   * @param formulaState: the formula state
   * @param isReadOnly: is the form read only?
   * @param formAudience: Current form audience
   */
  applyComponentLogicResults (
    currentFormDefinition: FormDefinitionForUi,
    setValueState: ListLogicState<BaseApplicationForLogic, ValueLogicResult<BaseApplicationForLogic>>,
    conditionalVisibilityState: LogicState<BaseApplicationForLogic, boolean>,
    formulaState: FormulaState<BaseApplicationForLogic>,
    isReadOnly: boolean,
    formAudience: FormAudience
  ) {
    let valueChanges: FormChangesWithCompKey[] = [];
    let validityChanges: string[] = [];
    const customValidityMap: Record<string, boolean> = {
      ...this.customValidationMap
    };
    // If a component is hidden and needs cleared, we need to trigger a change here
    const clearOnHideChanges = this.applyConditionalLogicResults(
      currentFormDefinition,
      conditionalVisibilityState,
      formAudience
    );

    if (!isReadOnly) {
      valueChanges = [
        ...this.applySetValueResults(
          currentFormDefinition,
          setValueState
        ),
        ...this.applyFormulaValues(
          currentFormDefinition,
          formulaState
        )
      ];
    }


    if (!isReadOnly) {
      if (clearOnHideChanges.length > 0) {
        valueChanges = [
          ...valueChanges,
          ...clearOnHideChanges
        ];
      }
      if (!!this.currentValidityState) {
        this.componentHelper.eachComponent(currentFormDefinition.components, (component) => {
          if (component.type !== 'button') {
            const logicBuilderKey = this.compKeyToLogicBuilderKey[component.key];
            const isInvalid = this.currentValidityState.sourceMap.get(logicBuilderKey)?.result$?.value === false;
            const currentValidity = !isInvalid;
            const previousValidity = this.customValidationMap[component.key];
            if (previousValidity !== currentValidity) {
              validityChanges.push(component.key);
              customValidityMap[component.key] = currentValidity;
            }
          }
        });
        this.setCustomValidationMap(customValidityMap);
      }
    }

    return {
      valueChanges,
      validityChanges
    };
  }

  /**
   * For a given form and visibility state, go through and apply the correct overrides for the values of the components with logic
   *
   * @param currentFormDefinition The form to be evaluated
   * @param setValueState The current state of the set value calculations
   */
  applySetValueResults (
    currentFormDefinition: FormDefinitionForUi,
    setValueState: ListLogicState<BaseApplicationForLogic, ValueLogicResult<BaseApplicationForLogic>>
  ) {
    const changes: FormChangesWithCompKey[] = [];
    this.componentHelper.eachComponent(currentFormDefinition.components, (component) => {
      // no need to set value if comp is hidden and clears on hide
      const skipLogic = this.shouldSkipValueLogic(component);
      if (!skipLogic) {
        const logic = this.getComponentLogic(component);
        const column = logic?.[0];
        if (column) {
          const groups = logic[1]?.conditionalValueGroups;
          if (groups?.length > 0) {
            const changed = this.handleConditionalValueGroups(
              component,
              column,
              setValueState
            );

            if (changed) {
              changes.push({
                componentKey: component.key,
                isReferenceField: this.componentHelper.isReferenceFieldComp(component.type),
                key: this.componentHelper.getRefFieldKeyFromCompType(component.type),
                type: component.type,
                value: component.value,
                updateFormGroup: true
              });
            }
          }
        }
      }
    });

    return changes;
  }

  /**
   * Apply Conditional Logic Results for Tab
   *
   * @param tab: Tab
   * @param formAudience: Form Audience
   * @param formDefinition: Form Definition
   * @param isReadOnly: Is read only?
   * @returns the resulting changes
   */
  applyConditionalLogicResultsForTab (
    tab: Tab&{ index: number },
    formAudience: FormAudience,
    formDefinition: FormDefinitionForUi,
    isReadOnly: boolean
  ) {
    let changes: FormChangesWithCompKey[] = [];
    this.componentHelper.eachComponent(formDefinition.components, (comp) => {
      if (!comp.hiddenFromParent) {
        const initialChanges = this.applyVisibilityResultToComponent(
          comp,
          tab.hidden,
          false,
          formAudience
        );
        changes = [
          ...changes,
          ...initialChanges
        ];
      }
    }, true);
    if (isReadOnly) {
      return [];
    }

    return changes;
  }

  /**
   * For a given form and visibility state, go through and apply the correct overrides for form to hide/show the components
   *
   * @param currentFormDefinition The form to be evaluated
   * @param conditionalVisibilityState The current state of logic
   * @param formAudience: Audience of current form being viewed
   * @returns array of changes made during applying results
   */
  applyConditionalLogicResults (
    currentFormDefinition: FormDefinitionForUi,
    conditionalVisibilityState: LogicState<BaseApplicationForLogic, boolean>,
    formAudience: FormAudience
  ) {
    let changes: FormChangesWithCompKey[] = [];
    this.componentHelper.eachComponent(currentFormDefinition.components, (component) => {
      const logic = this.getComponentLogic(component);
      const column = logic?.[0];
      const groups = logic?.[1];
      const visibilityGroup = groups?.visibilityGroup;
      if (column && visibilityGroup) {
        const groupChanges = this.handleVisibilityLogic(
          component,
          column,
          visibilityGroup,
          conditionalVisibilityState,
          formAudience
        );

        changes = [
          ...changes,
          ...groupChanges
        ];
      }
    }, true);

    return changes;
  }

  /**
   *
   * @param currentFormDefinition: the current form definition
   * @param formulaState: the formula state
   * @returns array of changes made
   */
  applyFormulaValues (
    currentFormDefinition: FormDefinitionForUi,
    formulaState: FormulaState<BaseApplicationForLogic>
  ) {
    const changes: FormChangesWithCompKey[] = [];
    this.componentHelper.eachComponent(currentFormDefinition.components, (component) => {
      // no need to set value if comp is hidden and clears on hide
      const skipLogic = this.shouldSkipValueLogic(component);
      if (!skipLogic) {
        if (!!component.formula?.step) {
          const changed = this.handleFormulaResult(
            component,
            component.type.split('-').join('.'),
            formulaState
          );

          if (changed) {
            changes.push(
              this.componentHelper.adaptToFormChanges(component, component.value, true)
            );
          }
        }
      }
    }, true);

    return changes;
  }

  /**
   *
   * @param comp: form component
   * @param column: column
   * @param visibilityGroup: the visibility group
   * @param conditionalVisibilityState: the conditional visibility state
   * @returns array of changes made during applying results
   */
  handleVisibilityLogic (
    comp: FormDefinitionComponent,
    column: LogicColumn<BaseApplicationForLogic>,
    visibilityGroup: GlobalLogicGroup<BaseApplicationForLogic>,
    conditionalVisibilityState: LogicState<BaseApplicationForLogic, boolean>,
    formAudience: FormAudience
  ) {
    let changes: FormChangesWithCompKey[] = [];
    const evaluationType = visibilityGroup?.evaluationType;
    const hasLogic = this.logicBuilderService.getHasConditionalLogic(evaluationType);
    if (hasLogic) {
      const show = this.logicBuilderService.getCurrentLogicValueOfColumn(
        column as LogicColumn<BaseApplicationForLogic>,
        conditionalVisibilityState
      ) ?? true;
      if (!comp.hiddenFromParent) {
        const initialChanges = this.applyVisibilityResultToComponent(
          comp,
          !show,
          false,
          formAudience
        );
        changes = [
          ...changes,
          ...initialChanges
        ];
      }
    } else if (evaluationType) {
      // if doesnt have logic but does have evaluation type, then either always hide or always show
      const alwaysValue = evaluationType === EvaluationType.AlwaysTrue;
      if (!comp.isHidden && !comp.hiddenFromParent) {
        // If this component is already hidden by it's parent, do not overwrite this
        const evalChanges = this.applyVisibilityResultToComponent(
          comp,
          !alwaysValue,
          false,
          formAudience
        );
        changes = [
          ...changes,
          ...evalChanges
        ];
      }
    }

    return changes;
  }

  /**
   *
   * @param component: the form component
   * @param newValue: new value for the component
   * @returns true if it did set the value
   */
  setValueForComp (
    component: FormDefinitionComponent,
    newValue: FormAnswerValues
  ) {
    let didSetValue = false;
    if (
      !this.componentHelper.isLayoutComponent(component.type) &&
      component.type !== 'button' &&
      !isEqual(component.value, newValue)
    ) {
      didSetValue = true;
      component.value = newValue;
    }

    return didSetValue;
  }

  /**
   * This will apply the hidden property to nested components
   *
   * @param comp: component to apply visibility results to
   * @param hidden: is hidden?
   * @param fromParent: whether this evaluation is recursive (should be false most of the time)
   * @param formAudience: Audience of the current form being viewed
   * @returns array of changes made
   * (for visibility, it would be that a component was hidden and the value was cleared)
   */
  applyVisibilityResultToComponent (
    comp: FormDefinitionComponent,
    hidden: boolean,
    fromParent: boolean,
    formAudience: FormAudience
  ): FormChangesWithCompKey[] {
    let changes: FormChangesWithCompKey[] = [];
    if (fromParent) {
      comp.hiddenFromParent = hidden;
    } else {
      comp.isHidden = hidden;
    }
    if (hidden && comp.clearOnHide) {
      const field = this.formFieldHelperService.getReferenceFieldFromCompType(
        comp.type
      );
      const audienceIsSame = field?.formAudience === formAudience;
      const isSubsetOrTable = [
        ReferenceFieldsUI.ReferenceFieldTypes.Subset,
        ReferenceFieldsUI.ReferenceFieldTypes.Table
      ].includes(field?.type);
      if (!isSubsetOrTable && audienceIsSame) {
        const blankValue = this.formFieldHelperService.getBlankValueForFormField(
          field,
          comp,
          false
        );
        const changed = this.setValueForComp(comp, blankValue);
        if (changed) {
          changes = [
            ...changes,
            this.componentHelper.adaptToFormChanges(comp, comp.value, true)
          ];
        }
      }
    }
    switch (comp.type) {
      case 'columns':
        comp.columns.forEach((column) => {
          this.componentHelper.eachComponent(column.components, (columnComp) => {
            const columnCompChanges = this.applyVisibilityResultToComponent(
              columnComp,
              hidden,
              true,
              formAudience
            );
            if (columnCompChanges.length > 0) {
              changes = [
                ...changes,
                ...columnCompChanges
              ];
            }
          }, true);
        });
        break;
      case 'fieldset':
      case 'panel':
      case 'well':
        this.componentHelper.eachComponent(comp.components, (layoutComp) => {
          const layoutCompChanges = this.applyVisibilityResultToComponent(
            layoutComp,
            hidden,
            true,
            formAudience
          );
          if (layoutCompChanges.length > 0) {
            changes = [
              ...changes,
              ...layoutCompChanges
            ];
          }
        }, true);
        break;
      case 'table':
        comp.rows.forEach((rowComponents) => {
          rowComponents.forEach((componentObj) => {
            this.componentHelper.eachComponent(componentObj.components, (rowComp) => {
              const tableChanges = this.applyVisibilityResultToComponent(
                rowComp,
                hidden,
                true,
                formAudience
              );
              if (tableChanges.length > 0) {
                changes = [
                  ...changes,
                  ...tableChanges
                ];
              }
            }, true);
          });
        });
        break;
    }

    return changes;
  }

 /**
  *
  * @param component: the form component
  * @param column: column
  * @param setValueState: the set value state
  * @returns if changes were made
  */
  handleConditionalValueGroups (
    component: FormDefinitionComponent,
    column: LogicColumn<BaseApplicationForLogic>,
    setValueState: ListLogicState<BaseApplicationForLogic, ValueLogicResult<BaseApplicationForLogic>>
  ) {
    let value = this.logicBuilderService.getCurrentLogicValueOfColumn<
      BaseApplicationForLogic, ValueLogicResult<BaseApplicationForLogic>
    >(
      column as LogicColumn<BaseApplicationForLogic>,
      setValueState
    ) ?? null;
    const refField = this.formFieldHelperService.getReferenceFieldFromCompType(
      component.type
    );

    if (refField?.supportsMultiple && !(value instanceof Array)) {
      value = [];
    }
    value = this.convertCalculatedOrSetValueToCurrencyVal(
      value,
      component.type,
      component.value
    ) as FormAnswerValues;

    return this.setValueForComp(component, value as FormAnswerValues);
  }

  /**
   *
   * @param component: the form component
   * @param column: column
   * @param formulaState: the conditional visibility state
   * @returns if changes were made
   */
  handleFormulaResult (
    component: FormDefinitionComponent,
    column: string,
    formulaState: FormulaState<BaseApplicationForLogic>
  ) {
    const logicStateForColumn = formulaState.sourceMap.get(column);
    if (!!logicStateForColumn) {
      let formResponse = component.value;
      if (this.isCurrencyField(component.type)) {
        formResponse = (component.value as CurrencyValue)?.amountForControl ?? 0;
      }

      let previousCalculatedValue = logicStateForColumn.previousResult;
      const isFirstRun = previousCalculatedValue === undefined;
      const runResult = logicStateForColumn.result$.value;

      if (isFirstRun) {
        previousCalculatedValue = runResult;
      }

      const { currentValue, usedRunResult } = this.componentHelper.determineCorrectCalculatedValueResult(
        isFirstRun,
        runResult,
        previousCalculatedValue,
        formResponse,
        component.type,
        component.allowCalculateOverride
      );

      if (usedRunResult) {
        previousCalculatedValue = +currentValue;
      }

      const updatedVal = this.convertCalculatedOrSetValueToCurrencyVal(
        currentValue,
        component.type,
        component.value
      ) as FormAnswerValues;

      return this.setValueForComp(component, updatedVal);
    }

    return false;
  }

  /**
   *
   * @param compType: component type
   * @returns if the component is a currency field
   */
  isCurrencyField (compType: string) {
    const field = this.formFieldHelperService.getReferenceFieldFromCompType(compType);
    const isCurrencyField = field?.type === ReferenceFieldsUI.ReferenceFieldTypes.Currency;
    const isAmountRequested = compType === 'amountRequested';
    const isRecommendedFunding = compType === 'reviewerRecommendedFundingAmount';

    return isCurrencyField || isAmountRequested || isRecommendedFunding;
  }

  /**
   *
   * @param calculatedValue: the calculated value number
   * @param compType: the component type
   * @param value: the current value of the component
   * @returns the correct model for the new value
   */
  convertCalculatedOrSetValueToCurrencyVal (
    calculatedValue: unknown,
    compType: string,
    value: FormAnswerValues
  ) {
    // Currency fields are stored in an object (which includes the amount and currency)
    // Because of this, they may need adapted if the value is calculated and comes through as a number
    if (this.isCurrencyField(compType)) {
      // If the value coming in is already the currency model, we don't need to adapt anything
      const isFormattedAsCurrency = this.logicBuilderService.isCurrencyType(calculatedValue);
      if (!isFormattedAsCurrency) {
        calculatedValue = {
          ...value as CurrencyValue,
          amountForControl: calculatedValue
        } as CurrencyValue;
      }
    }

    return calculatedValue;
  }

  /**
   *
   * @param formDefinition: the form definition to adapt
   * @returns the adapted form definition
   */
  adaptComponentsForLogic (
    formDefinition: FormDefinitionForUi[],
    formType: FormTypes
  ): {
    customJavascriptComps: string[];
    adaptedFormDefinition: FormDefinitionForUi[];
  } {
    const customJavascriptComps: string[] = [];
    formDefinition.forEach((tab) => {
      this.componentHelper.eachComponent(tab.components, (comp) => {
        if (comp.validate) {
          comp.validate.custom = this.componentHelper.adaptInitialCustomValidation(comp.validate.custom);
        }
        const hasJavascriptLogic = !!comp.customConditional ||
          !!comp.validate?.custom ||
          !!comp.calculateValue;
        if (hasJavascriptLogic) {
          customJavascriptComps.push(comp.label || comp.title || comp.legend || comp.key);
        }
        this.componentHelper.adaptInputMask(comp);

        this.componentHelper.adaptConditionalValue(comp, this.isCurrencyField(comp.type));

        this.componentHelper.adaptConditionalLogic(comp);

        this.componentHelper.parseConfigurationOptions(comp);

        this.adaptInitialComponentValues(comp, formType);

        if (comp.hidden) {
          // This is an old form attribute we no longer use and should always be false
          comp.hidden = false;
        }
      }, true);
    });

    return {
      adaptedFormDefinition: formDefinition,
      customJavascriptComps
    };
  }

  /**
   *
   * @param comp: the component
   */
  adaptInitialComponentValues (
    comp: FormDefinitionComponent,
    formType: FormTypes
  ) {
    // if the key has any periods, replace them since they cause issues when used as a form control key
    comp.key = comp.key?.replace(/\./g, '');
    const field = this.formFieldHelperService.getReferenceFieldFromCompType(comp.type, true);
    if (!!field) {
      comp.type = `${REF_COMPONENT_TYPE_PREFIX}${field.key}`;
    }
    // This should always start as false, and will be applied based on conditions
    comp.isHidden = false;
    comp.hiddenFromParent = false;
    if (this.componentHelper.isInvalidComp(comp.type)) {
      // This is bad data and we should treat these fields as hidden with no validation
      comp.isHidden = true;
      comp.hiddenFromParent = true;
      comp.validate = {
        required: false,
        custom: ''
      };
    }
    if (!!field) {
      const canBeRequired = this.formFieldHelperService.canFieldTypeBeRequired(
        field.type,
        field.supportsMultiple
      );
      if (!canBeRequired && comp.validate?.required) {
        comp.validate.required = false;
      }
      if (
        !!comp.hiddenTableColumnIds &&
        comp.hiddenTableColumnIds.length > 0 &&
        !comp.hiddenTableColumnKeys
      ) {
        // Deprecated hiddenTableColumnIds in favor of hiddenTableColumnKeys
        // This is because IDs will not work when a form gets imported to PROD from UAT
        // IDs are different while keys are the same across environments
        comp.hiddenTableColumnKeys = comp.hiddenTableColumnIds.map((id) => {
          return this.formFieldHelperService.referenceFieldMapById[id]?.key;
        }).filter((key) => !!key);
      }
    }

    // Clear any value stored on definition. Value will set in gc-form-renderer
    comp.value = undefined;
    comp.useCustomCurrency = comp.useCustomCurrency || CurrencyRadioOptions.USE_ONE_CURRENCY;

    const isStandardComp = this.componentHelper.isStandardComponent(comp.type);
    const isTableOrSubset = [
      ReferenceFieldsUI.ReferenceFieldTypes.Table,
      ReferenceFieldsUI.ReferenceFieldTypes.Subset
    ].includes(field?.type);
    // These types of field do not support clear on hide, so make sure it's false
    if (isStandardComp || isTableOrSubset) {
      comp.clearOnHide = false;
    }

    // If the field doesn't support default val, clear out the setting
    if (field && !!comp.defaultVal) {
      const formAudience = this.typeAudienceMap[formType];
      const {
        defaultValType
      } = this.formFieldHelperService.getEditFormSupportsSettings(field, formAudience);
      if (defaultValType === DefaultValType.None) {
        comp.defaultVal = undefined;
      } else if ((comp.defaultVal as any) instanceof Object) {
        comp.defaultVal = undefined;
      }
    }
    if (field?.supportsMultiple || field?.type !== ReferenceFieldsUI.ReferenceFieldTypes.TextField) {
      // We do not support input masks or patterns on fields that support multiple values
      // We only support it on text fields
      comp.inputMask = '';
      if (!!comp.validate?.pattern) {
        comp.validate.pattern = '';
      }
    }
    // even if there is something saved here, we don't want to use validationResult until the form has been edited
    if (comp.validate?.validationResult) {
      comp.validate.validationResult = null;
    }
  }

  /**
   *
   * @param formDefinition: form definition
   * @returns external api calls for hat definition
   */
  getExternalAPICalls (
    formDefinition: FormDefinitionForUi[]
  ) {
    let externalAPIComponents: FormDefinitionComponent[] = [];
    formDefinition.forEach((tab) => {
      const response = this.extractComponentsByRefFieldType(
        tab,
        ReferenceFieldsUI.ReferenceFieldTypes.ExternalAPI
      );
      externalAPIComponents = [
        ...externalAPIComponents,
        ...response
      ];
    });
    const configs: (ExternalAPISelection&{ relatedComponent: string })[] = externalAPIComponents.map(comp => {
      const conf = comp.apiConfig;

      return {
        ...(typeof conf === 'string' ?
          JSON.parse(conf) :
          conf),
        relatedComponent: comp.relatedComponent
      };
    });

    return configs.filter((item, index) => {
      if (!!item.relatedComponent) {
        const configIndex = configs.findIndex(comp => {
          return comp.integrationId === item.integrationId &&
            comp.relatedComponent === item.relatedComponent;
          });

        return item && (configIndex === index);
      }

      return false;
    });
  }

  /**
   *
   * @param tab: form definition tab
   * @param refType: ref type to find
   * @returns components of that type
   */
  extractComponentsByRefFieldType (
    tab: FormDefinitionForUi,
    refType: ReferenceFieldsUI.ReferenceFieldTypes
  ) {
    const comps: FormDefinitionComponent[] = [];

    this.componentHelper.eachComponent(tab.components, (comp) => {
      const refField = this.formFieldHelperService.getReferenceFieldFromCompType(comp.type);

      if (refField?.type === refType) {
        comps.push(comp);
      }
    });

    return comps;
  }

  /**
   *
   * @param formId: This is the ID of the form currently being edited
   * @param revisionId: revision id
   */
  async getPageTemplateOptions (
    formId: number,
    revisionId: number
  ): Promise<TypeaheadSelectOption[]> {
    let pageOptions = this.pageOptionsMap[formId];

    if (!pageOptions) {
      const formDetails = await this.getAndSetForm(formId, revisionId);

      pageOptions = formDetails.formDefinition.filter((def) => {
        return def.components.length > 0;
      }).map((definition) => {
        return {
          value: definition.uniqueId,
          label: definition.tabName
        };
      });
      this.setPageOptionsMap({
        ...this.pageOptionsMap,
        [formId]: pageOptions
      });
    }

    return pageOptions;
  }

  /**
   * Given a component, return all related fields in a map.
   * This could be conditional logic, set value, calculated values, or validity
   *
   * @param compToCheck: Component to get map for
   * @param logicStates: Logic States map
   * @param relatedFieldMap: Related Field map
   * @param columnsToDelete: Columns we plan to delete
   * @returns the related field map
   */
  getRelatedFieldMap (
    compToCheck: FormDefinitionComponent,
    logicStates: FormLogicStates,
    relatedFieldMap: Record<string, string[]> = {},
    columnsToDelete: string[] = []
  ) {
    if (this.componentHelper.isLayoutComponent(compToCheck.type) && compToCheck.type !== 'content') {
      const nestedComps = this.componentHelper.getNestedComponents(compToCheck);
      const nestedWithLayoutComps = this.componentHelper.getNestedComponents(compToCheck, true);
      const colsToDelete = nestedWithLayoutComps.map((nestedComp) => {
        const compLogic = this.getComponentLogic(nestedComp);

        return compLogic[0].join('.');
      });
      nestedComps.forEach((nestedComp) => {
        relatedFieldMap = this.getRelatedFieldMap(
          nestedComp,
          logicStates,
          relatedFieldMap,
          colsToDelete
        );
      });
    } else {
      const compLogic = this.getComponentLogic(compToCheck);
      const columnVal = compLogic[0].join('.');
      let relatedColumns = this.getColumnsWithLogicTiedToComponent(columnVal, logicStates);
      relatedColumns = relatedColumns.filter((relatedColumn) => {
        return !columnsToDelete.includes(relatedColumn);
      });
      if (relatedColumns.length > 0) {
        relatedFieldMap[columnVal] = relatedColumns;
      }
    }

    return relatedFieldMap;
  }

  /**
   * Given a component, returns all related columns
   *
   * @param columnVal: Column to check against
   * @param logicStates: Logic states map
   * @returns an array of related columns
   */
  getColumnsWithLogicTiedToComponent (
    columnVal: string,
    logicStates: FormLogicStates
  ) {
    const conditionalVisibilityDeps = this.logicStateService.getRecordsFromState(
      columnVal,
      logicStates.conditionalVisibilityState
    );
    const setValueDeps = this.logicStateService.getRecordsFromState(
      columnVal,
      logicStates.setValueState
    );
    const validityDeps = this.logicStateService.getRecordsFromState(
      columnVal,
      logicStates.validityState
    );
    const formulaDeps = this.logicStateService.getRecordsFromState(
      columnVal,
      logicStates.formulaState
    );
    const relatedColumns = [
      ...conditionalVisibilityDeps.dependents,
      ...setValueDeps.dependents,
      ...validityDeps.dependents
    ].map((dependency) => {
      return dependency.column.join('.');
    });
    const formulaRelatedColumns = formulaDeps.dependents.map((dependency) => {
      let formulaColumnVal = dependency.property;
      if (this.componentHelper.isStandardComponent(formulaColumnVal)) {
        formulaColumnVal = `application.${dependency.property}`;
      }

      return formulaColumnVal;
    });
    const allColumns = uniq([
      ...relatedColumns,
      ...formulaRelatedColumns
    ]);

    return allColumns;
  }

  /**
   * Get a map to correlate components to their label
   *
   * @param formDefinition: Form Definition
   * @returns a map with the column attr on the left and the label on the right
   */
  getLabelMapFromFormDefinition (
    formDefinition: FormDefinitionForUi[]
  ): Record<string, string> {
    let labelMap: Record<string, string> = {};
    formDefinition.forEach((tab, index) => {
      labelMap[`tabs.${index}`] = tab.tabName + ` (${this.i18n.translate('common:hdrTab', {}, 'Tab')})`
    });

    return this.componentHelper.getAllComponents(formDefinition, true).reduce((acc, comp) => {
      const columnLogic = this.getComponentLogic(comp);
      if (!!columnLogic) {
        const columnVal = columnLogic[0].join('.');
        let label = comp.title || comp.legend || comp.label || comp.type;
        if (this.componentHelper.isLayoutComponent(comp.type)) {
          label = `${label} (${comp.key})`;
        }

        return {
          ...acc,
          [columnVal]: label
        };
      } else {
        return {
          ...acc
        };
      }

    }, labelMap);
  }

  /**
   * Evaluates if a given component can be deleted. If not, returns a string explaining why it can't
   *
   * @param compToCheck: Component to check if we can delete
   * @param formDefinition: Form Definition
   * @returns the text to display if the component cannot be deleted
   */
  getCompDeletionText (
    compToCheck: FormDefinitionComponent,
    formDefinition: FormDefinitionForUi[]
  ) {
    const logicStates = this.initFormDefinitionLogic(formDefinition);
    const relatedFieldMap = this.getRelatedFieldMap(compToCheck, logicStates);
    const labelMap = this.getLabelMapFromFormDefinition(formDefinition);
    const numberOfRelatedFields = Object.keys(relatedFieldMap).length;
    if (numberOfRelatedFields > 0) {
      const deletionText = this.i18n.translate(
        numberOfRelatedFields === 1 ? 'common:textCompCannotBeDeleted' : 'common:textCompsCannotBeDeleted',
        {},
        numberOfRelatedFields === 1 ?
          'The following component cannot be deleted because it is referenced by other components on the form' :
          'The following components cannot be deleted because they are referenced by other components on the form'
      );

      return Object.keys(relatedFieldMap).reduce((acc, columnVal, index) => {
        const isFirst = index === 0;
        const isLast = index === (numberOfRelatedFields - 1);
        const label = labelMap[columnVal];
        const relatedFields = relatedFieldMap[columnVal].map((field) => {
          return labelMap[field];
        });
        const relatedFieldList = relatedFields.map((relatedFieldLabel) => {
          return '<li>' + relatedFieldLabel + '</li>';
        }).join(' ');

        return `${acc} ${isFirst ? '<ul>' : ''}
          <li>
            ${label}
            <ul>
              ${relatedFieldList}
            </ul>
          </li>
        ${isLast ? '</ul>' : ''}`;
      }, `<div class="mb-3">${deletionText}.</div>`);
    }

    return '';
  }

  /**
   * Given a list of programs to export, return which forms tied to them still contain custom javascript
   *
   * @param programExport: Programs to Export
   * @returns The related forms that still contain custom javascript
   */
  getFormNamesWithCustomJavascriptForProgramExport (
    programExport: ProgramExport
  ) {
    const formNamesWithJavascript: string[] = [];
    const formTranslations = this.translationService.viewTranslations.FormTranslation;
    programExport.exportModel.formRevision.forEach((revision) => {
      const formDef = JSON.parse(revision.formDefinition) as FormDefinitionWithLogic|FormDefinitionWithLogic[];
      const {
        customJavascriptComps
      } = this.adaptFormDefinitionForTabs(formDef, null);
      if (customJavascriptComps.length > 0) {
        const translation = formTranslations[revision.formId];
        formNamesWithJavascript.push(translation.Name);
      }
    });

    return uniq(formNamesWithJavascript);
  }

  /**
   * Validates if the forms contain any invalid flat fields
   *
   * @param forProgramExport Determines if from the program or form export pages
   * @param ids Either the programIds if forProgramExport else, the form revision ids
   * @returns if the form fields are valid and if not, returns the invalid form fields
   */
  async flatFieldValidation (ids: number[], forProgramExport: boolean = false) {
    try {
      return await this.formResources.flatFieldValidation(forProgramExport, ids);
    } catch(e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
          'FORMS:textErrorExportingSelectedForms',
          {},
          'There was an error exporting the selected forms'
      ));

      return {
        isValid: false
      };
    }
  }

  /**
   * Exports the selected forms if no custom javascript exists
   *
   * @param exportFormsPayload: forms to export
   */
  async exportForms (exportFormsPayload: ExportForm[]) {
    try {
      let formNamesWithJavascript: string[] = [];
      const formTranslations = this.translationService.viewTranslations.FormTranslation;
      const exportedForms = await this.formResources.exportForms(exportFormsPayload);
      exportedForms.forEach((form) => {
        const {
          customJavascriptComps
        } = this.adaptFormDefinitionForTabs(form.formRevisionFormDefinition, null);
        if (customJavascriptComps.length > 0) {
          const translation = formTranslations[form.id];
          formNamesWithJavascript.push(translation.Name);
        }
      });
      formNamesWithJavascript = uniq(formNamesWithJavascript);
      if (formNamesWithJavascript.length > 0) {
        return {
          formNamesWithJavascript,
          result: ExportFormResult.HasCustomJs
        };
      } else {
        this.fileService.downloadRaw(
          Base64.encode(JSON.stringify(exportedForms)),
          `forms_export_${this.dateService.formatDate(new Date())}.bin`
        );
        this.notifier.success(this.i18n.translate(
          'FORMS:textSuccessfullyExportedSelectedForms',
          {},
          'Successfully exported the selected forms'
        ));

        return {
          result: ExportFormResult.Passed,
          formNamesWithJavascript: []
        };
      }
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'FORMS:textErrorExportingSelectedForms',
        {},
        'There was an error exporting the selected forms'
      ));

      return {
        result: ExportFormResult.Failed,
        formNamesWithJavascript: []
      };
    }
  }

  getComponentsOnForm (formDefinition: FormDefinitionForUi[]) {
    const formComponents = this.componentHelper.getAllComponents(formDefinition);
    const fieldTypes = this.formFieldHelperService.getReferenceFieldTypeOptions();
    const typeMap = fieldTypes.reduce((acc, option) => {
      return {
        ...acc,
        [option.value]: option.label
      };
    }, {} as Record<ReferenceFieldsUI.ReferenceFieldTypes, string>);
    const categoryNameMap = this.formFieldCategoryService.categoryNameMap;
    const standardCompHelpers = this.componentHelper.getStandardComponentHelperMap();

    return formComponents.map<ComponentOnForm>((comp) => {
      const refField = this.formFieldHelperService.getReferenceFieldFromCompType(comp.type);
      const standardHelper = standardCompHelpers[comp.type];
      let name = '';
      let audience = null;
      let typeName = '';
      if (!!refField) {
        name = refField.name;
        typeName = typeMap[refField.type];
        audience = refField.formAudience;
      } else if (!!standardHelper) {
        name = this.i18n.translate(standardHelper.key, {}, standardHelper.defaultValue);
        typeName = this.i18n.translate('common:textStandardField', {}, 'Standard field');
        audience = standardHelper.audience;
      } else if (this.componentHelper.isEmployeeSsoComponent(comp.type)) {
        name = this.i18n.translate('common:textEmployeeHRData', {}, 'Employee HR data');
        typeName = name;
      } else if (this.componentHelper.isReportFieldComp(comp.type)) {
        name = this.i18n.translate('common:textReportField', {}, 'Report field');
        typeName = name;
      }

      return {
        label: comp.label === name ? '' : comp.label,
        name: name,
        audienceName: !!audience ? this.i18n.translate(
          audience === FormAudience.MANAGER ? 'GLOBAL:textGrantManager' : 'common:lblApplicant',
          {},
          audience === FormAudience.MANAGER ? 'Grant manager' : 'Applicant'
        ) : '',
        typeName,
        key: !!refField ? refField.key : comp.key,
        categoryName: !!refField ? categoryNameMap[refField.categoryId] : ''
      };
    });
  }
}
